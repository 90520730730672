<template>
  <div v-if="!item.hidden" class="first-level-menu">
    <template>
      <div v-if="hasOnlyOneShowingChild(item.children, item)">
        <el-menu-item :index="item.path">
          <span>{{ item.meta.title }}</span>
        </el-menu-item>
      </div>

      <el-submenu v-else :index="item.path">
        <template slot="title">
          <!-- <i :class="'iconfont' + ' ' + item.meta.icon"></i> -->
          <span>{{ item.meta.title }}</span>
        </template>
        <div v-for="child in item.children" :key="child.path">
          <el-menu-item v-if="child.meta && !child.hidden" :index="item.path + (child.path ? '/' + child.path : '')">
            <span>{{ child.meta.title }}</span>
          </el-menu-item>
        </div>
      </el-submenu>
    </template>
  </div>
</template>

<script>
export default {
  name: 'SideMenuItem',
  components: {},

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  data() {
    return {}
  },

  computed: {},

  watch: {},

  created() {},

  methods: {
    hasOnlyOneShowingChild(children = [], parent) {
      const showingChildren = children.filter((item) => {
        if (item.hidden) {
          return false
        } else {
          // Temp set(will be used if only has one showing child)
          return true
        }
      })

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.first-level-menu {
}
</style>
