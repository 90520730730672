<template>
  <div class="page">
    <!-- 主体区域 -->
    <el-card>
      <!-- 筛选、操作区域 -->
      <OperationBar>
        <template slot="search-box">
          <el-form :model="queryForm" :inline="true">
            <el-form-item label="关键词">
              <el-input
                v-model="queryForm.search"
                placeholder="请输入关键词"
                clearable
                @keyup.enter.native="handleSearch()"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="medium" @click="handleSearch()">查询</el-button>
            </el-form-item>
          </el-form>
        </template>

        <template slot="btn-box">
          <el-button type="primary" size="medium" round @click="createCategory()">新建分类</el-button>
        </template>
      </OperationBar>

      <!-- 表格 -->
      <el-table ref="productListTable" :data="list" v-loading="loading" border>
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column label="分类" prop="name"></el-table-column>

        <el-table-column label="是否显示" width="120">
          <template slot-scope="{ row }">
            <el-switch
              v-model="row.isShow"
              :active-value="1"
              :inactive-value="0"
              @change="isShowChange(row)"
            ></el-switch>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="240">
          <div slot-scope="{ row, $index }" class="action-cell">
            <el-button type="primary" round size="mini" @click="handleEdit(row)">编辑</el-button>
            <ConfirmPopover :index="$index" @confirm="handleDelete(row)"></ConfirmPopover>
          </div>
        </el-table-column>
      </el-table>

      <!-- 分页器 -->
      <PaginationBar
        :pageNo.sync="pagination.pageNum"
        :pageSize.sync="pagination.pageSize"
        :total="pagination.total"
        @change="getList()"
      ></PaginationBar>
    </el-card>

    <!-- 编辑分类弹框 -->
    <el-dialog title="编辑分类" :visible.sync="editDialog" width="600px" @closed="clearContents()">
      <el-form ref="editForm" :model="editForm" :rules="editRules" label-width="auto">
        <el-form-item label="名称" prop="name">
          <el-input v-model="editForm.name" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input-number v-model="editForm.sort" :min="1" :step="1" step-strictly></el-input-number>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="editDialog = false">取 消</el-button>
        <el-button type="primary" @click="handleEditSave()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      query: {
        levels: 1
      },
      queryForm: {
        levels: 1,
        search: ''
      },
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      loading: false,
      list: [],

      editDialog: false,
      editForm: {},
      defaultEditForm: {
        levels: 1,
        name: '',
        sort: ''
      },
      editRules: {
        name: [{ required: true, message: '请输入分类名称', trigger: 'blur' }],
        sort: [{ required: true, message: '请设置排序', trigger: 'blur' }]
      }
    }
  },

  filters: {},

  computed: {},

  watch: {},

  created() {
    this.init()
    this.getList()
  },

  methods: {
    init() {
      this.editForm = { ...this.defaultEditForm }
    },

    // 获取一级分类列表
    async getList() {
      this.loading = true
      const res = await this.$api.subProduct.getCategoryList({ ...this.query, ...this.pagination })
      this.loading = false

      if (res.code === '00000') {
        this.list = res.data
        this.pagination.total = res.total
      }
    },

    // 搜索事件
    handleSearch() {
      this.query = { ...this.queryForm }
      this.pagination.pageNum = 1
      this.getList()
    },

    // 添加分类
    createCategory() {
      this.editDialog = true
    },

    // 显示隐藏状态变化时
    async isShowChange(row) {
      const res = await this.$api.subProduct.setCategoryShowHide(row.id)

      if (res.code === '00000') {
        this.$message.success('操作成功！')
        this.getList()
      }
    },

    // 编辑
    handleEdit(row) {
      this.editForm = { ...row }
      this.editDialog = true
    },

    // 关闭弹窗时清除表单
    clearContents() {
      this.editForm = { ...this.defaultEditForm }
    },

    // 删除
    async handleDelete(row) {
      const res = await this.$api.subProduct.deleteCategory(row.id)

      if (res.code === '00000') {
        this.$message.success('删除成功！')
        this.getList()
      }
    },

    // 保存编辑
    handleEditSave() {
      this.$refs.editForm.validate(async (valid) => {
        if (!valid) return

        const res = await this.$api.subProduct.editCategory(this.editForm)
        if (res.code === '00000') {
          this.$message.success('操作成功！')
          this.editDialog = false
          this.getList()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  .el-card {
    .el-table {
    }
  }
}
</style>
