<template>
  <div class="page">
    <el-card>
      <!-- 筛选、操作区域 -->
      <OperationBar>
        <template slot="search-box">
          <el-form :model="queryForm" :inline="true">
            <el-form-item label="套餐名称">
              <el-select v-model="queryForm.packageId" placeholder="请选择套餐名称" @change="handleSearch()">
                <el-option v-for="item in packageList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="medium" @click="handleSearch()">查询</el-button>
            </el-form-item>
          </el-form>
        </template>
      </OperationBar>

      <!-- 表格 -->
      <el-table :data="list" v-loading="loading" border>
        <el-table-column label="序号" type="index" width="50" header-align="center"></el-table-column>

        <el-table-column label="套餐名称" prop="packageName"></el-table-column>
        <el-table-column label="出库数量" prop="num"></el-table-column>
        <el-table-column label="剩余数量" prop="stock"></el-table-column>
        <el-table-column label="出库时间" prop="gmtCreate"></el-table-column>
      </el-table>

      <!-- 分页器 -->
      <PaginationBar
        :pageNo.sync="pagination.pageNum"
        :pageSize.sync="pagination.pageSize"
        :total.sync="pagination.total"
        @change="getList()"
      ></PaginationBar>
    </el-card>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      query: {},
      queryForm: {
        packageId: ''
      },
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      queryDate: '',
      list: [],
      packageList: [],
      loading: false
    }
  },

  computed: {},

  watch: {},

  created() {
    this.getList()
    this.getPackageList()
  },

  methods: {
    // 获取列表
    async getList() {
      this.loading = true
      const res = await this.$api.packageApi.getOutboundList({ ...this.query, ...this.pagination })
      this.loading = false

      if (res.code === '00000') {
        this.pagination.total = res.total
        this.list = res.data
      }
    },

    // 搜索事件
    handleSearch() {
      this.query = { ...this.queryForm }
      this.pagination.pageNum = 1
      this.getList()
    },

    // 获取列表
    async getPackageList() {
      const res = await this.$api.packageApi.getList({ ...this.query, ...this.pagination })

      if (res.code === '00000') {
        this.packageList = res.data
      }
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  .el-card {
    .el-table {
      .pacInfo-wrapper {
        .title {
          color: #1086ff;
          font-size: 18px;
        }
        .proNum {
          margin-top: 4px;
        }
      }

      .proPic-wrapper {
        .el-image {
          width: 75px;
          height: 75px;
        }
      }
    }
  }

  /deep/ .edit-dialog {
    .el-card__body {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // max-height: 600px;
      padding: 4px 20px;

      .selected-product {
        display: flex;
        align-items: center;
        .el-image {
          width: 75px;
          height: 75px;
        }
        .name {
          margin-left: 20px;
          font-size: 18px;
        }
      }
    }
  }

  /deep/ .select-product-dialog {
    .el-dialog__body {
      padding: 4px 20px 10px;

      .select-product-option {
        display: flex;
        align-items: center;
        margin-top: 4px;
        cursor: pointer;

        &:hover {
          background-color: #c6e2ff;
        }
        &:first-child {
          margin-top: 0;
        }
        .el-image {
          width: 75px;
          height: 75px;
        }
        .name {
          margin-left: 20px;
          font-size: 18px;
        }
      }
    }
  }
}
</style>
