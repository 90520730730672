<template>
  <div class="page">
    <!-- 主体区域 -->
    <el-card>
      <!-- 筛选、操作区域 -->
      <OperationBar>
        <template slot="search-box">
          <el-form :model="queryForm" :inline="true">
            <el-form-item label="关键词">
              <el-input
                v-model="queryForm.search"
                placeholder="请输入关键词"
                clearable
                @keyup.enter.native="handleSearch()"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="medium" @click="handleSearch()">查询</el-button>
            </el-form-item>
          </el-form>
        </template>

        <template slot="btn-box">
          <el-button type="primary" size="medium" round @click="createAccount()">新建供货商</el-button>
        </template>
      </OperationBar>

      <!-- 表格 -->
      <el-table ref="listTable" :data="list" v-loading="loading" border>
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column label="供货商名称" prop="name"></el-table-column>
        <el-table-column label="产品种类" prop="num"></el-table-column>
        <el-table-column label="联系方式" prop="phone"></el-table-column>
        <el-table-column label="创建时间" prop="gmtCreate"></el-table-column>

        <el-table-column label="操作" width="300">
          <div slot-scope="{ row, $index }" class="action-cell">
            <el-button type="primary" round size="mini" @click="viewDetails(row)">查看详情</el-button>
            <el-button type="primary" round size="mini" @click="editAccount(row)">编辑</el-button>
            <ConfirmPopover :index="$index" @confirm="handleDelete(row)"></ConfirmPopover>
          </div>
        </el-table-column>
      </el-table>

      <!-- 分页器 -->
      <PaginationBar
        :pageNo.sync="pagination.pageNum"
        :pageSize.sync="pagination.pageSize"
        :total="pagination.total"
        @change="getList()"
      ></PaginationBar>
    </el-card>

    <!-- 编辑账号弹框 -->
    <el-dialog title="编辑账号" :visible.sync="accountDialog" width="600px" @closed="clearContents()">
      <el-form ref="accountForm" :model="accountForm" :rules="accountRules" label-width="auto">
        <el-form-item label="账号" prop="name">
          <el-input v-model="accountForm.name" placeholder="请输入供货商名称-"></el-input>
        </el-form-item>
        <el-form-item label="联系方式" prop="phone">
          <el-input v-model="accountForm.phone" placeholder="请输入联系方式"></el-input>
        </el-form-item>
        <el-form-item label="地址" prop="address">
          <el-input v-model="accountForm.address" placeholder="请输入地址"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="accountDialog = false">取 消</el-button>
        <el-button type="primary" @click="handleAccountSave()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      query: {},
      queryForm: {
        search: ''
      },
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      loading: false,
      list: [],

      accountDialog: false,
      accountForm: {},
      defaultAccountForm: {
        name: '',
        phone: '',
        address: ''
      },
      accountRules: {
        name: [{ required: true, message: '请输入供货商名称', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入联系方式', trigger: 'blur' }],
        address: [{ required: true, message: '请输入地址', trigger: 'blur' }]
      }
    }
  },

  filters: {},
  computed: {},
  watch: {},

  created() {
    this.getList()
  },

  methods: {
    // 获取列表
    async getList() {
      this.loading = true
      const res = await this.$api.supplier.getList({ ...this.query, ...this.pagination })
      this.loading = false

      if (res.code === '00000') {
        this.list = res.data
        this.pagination.total = res.total
      }
    },

    // 搜索事件
    handleSearch() {
      this.query = { ...this.queryForm }
      this.pagination.pageNum = 1
      this.getList()
    },

    // 新建账号
    createAccount() {
      this.accountDialog = true
    },

    // 查看详情
    viewDetails(row) {
      this.$router.push({ path: 'supplierDetail', query: { id: row.id } })
    },

    // 编辑账号
    editAccount(row) {
      this.accountForm = { ...row }
      this.accountDialog = true
    },

    // 关闭弹窗时清除表单
    clearContents() {
      this.accountForm = { ...this.defaultAccountForm }
    },

    // 删除
    async handleDelete(row) {
      const res = await this.$api.supplier.deleteSupplier(row.id)

      if (res.code === '00000') {
        this.$message.success('操作成功！')
        this.getList()
      }
    },

    // 确认新建账号，调用接口
    handleAccountSave() {
      this.$refs.accountForm.validate(async (valid) => {
        if (!valid) return

        const res = await this.$api.supplier.editSupplier(this.accountForm)
        if (res.code === '00000') {
          this.$message.success('操作成功！')
          this.accountDialog = false
          this.pagination.pageNum = 1
          this.getList()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.page {
}
</style>
