<template>
  <div class="page">
    <!-- 主体区域 -->
    <el-card>
      <!-- 筛选、操作区域 -->
      <OperationBar>
        <template slot="search-box">
          <el-form :model="queryForm" :inline="true">
            <el-form-item label="名称">
              <el-input
                v-model="queryForm.name"
                placeholder="请输入角色名称"
                clearable
                @keyup.enter.native="handleSearch()"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="medium" @click="handleSearch()">查询</el-button>
            </el-form-item>
          </el-form>
        </template>

        <template slot="btn-box">
          <el-button type="primary" size="medium" round @click="handleCreate()">创建角色</el-button>
        </template>
      </OperationBar>

      <!-- 表格 -->
      <el-table ref="productListTable" :data="list" v-loading="loading" border>
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column label="角色名称" prop="name"></el-table-column>
        <el-table-column label="角色编码" prop="code"></el-table-column>

        <el-table-column label="操作" width="300">
          <div slot-scope="{ row, $index }" class="action-cell">
            <el-button type="primary" round size="mini" @click="jumpDetailPage(row.id)">查看详情</el-button>
            <ConfirmPopover :index="$index" @confirm="handleDelete(row)"></ConfirmPopover>
          </div>
        </el-table-column>
      </el-table>

      <!-- 分页器 -->
      <PaginationBar
        :pageNo.sync="pagination.page"
        :pageSize.sync="pagination.limit"
        :total="pagination.total"
        @change="getList()"
      ></PaginationBar>
    </el-card>

    <!-- 编辑角色弹框 -->
    <el-dialog title="编辑角色" :visible.sync="roleDialog" width="600px" @closed="handleClosed()">
      <el-form ref="roleForm" :model="roleForm" :rules="roleRules" label-width="auto">
        <el-form-item label="角色名称" prop="name">
          <el-input v-model="roleForm.name" placeholder="请填写角色名称"></el-input>
        </el-form-item>

        <el-form-item label="角色编码" prop="code">
          <el-input v-model="roleForm.code" placeholder="请填写角色编码"></el-input>
        </el-form-item>

        <el-form-item label="排序">
          <el-input-number v-model="roleForm.sort" :min="1" :step="1" step-strictly></el-input-number>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="menuDialog = false">取 消</el-button>
        <el-button type="primary" @click="handleSave()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      query: {},
      queryForm: {
        name: ''
      },
      pagination: {
        page: 1,
        limit: 10,
        total: 0
      },

      loading: false,
      list: [],

      roleDialog: false,
      roleForm: {},
      defaultRoleForm: {
        name: '',
        code: '',
        sort: 1
      },
      roleRules: {
        name: [{ required: true, message: '请填写角色名称', trigger: 'blur' }],
        code: [{ required: true, message: '请填写角色编码', trigger: 'blur' }]
      }
    }
  },

  computed: {},

  watch: {},

  created() {
    this.roleForm = { ...this.defaultRoleForm }
    this.getList()
  },

  methods: {
    // 获取列表
    async getList() {
      this.loading = true
      const res = await this.$api.role.getList({ ...this.query, ...this.pagination })
      this.loading = false

      if (res.code === '00000') {
        this.list = res.data
        this.pagination.total = res.total
      }
    },

    // 搜索事件
    handleSearch() {
      this.query = { ...this.queryForm }
      this.pagination.pageNum = 1
      this.getList()
    },

    // 创建角色
    handleCreate() {
      this.roleDialog = true
    },

    // 完全关闭弹窗时触发
    handleClosed() {
      this.roleForm = { ...this.defaultRoleForm }
    },

    // 保存
    handleSave() {
      this.$refs.roleForm.validate(async (valid) => {
        if (!valid) return

        const res = await this.$api.role.createRole(this.roleForm)
        if (res.code === '00000') {
          this.$message.success('创建成功！')
          this.roleDialog = false
          this.getList()
        }
      })
    },

    // 跳转详情页面
    jumpDetailPage(id) {
      this.$router.push({ path: 'roleDetail', query: { id } })
    },

    // 删除
    async handleDelete(row) {
      const res = await this.$api.role.deleteRole(row.id)

      if (res.code === '00000') {
        this.$message.success('删除成功！')
        this.getList()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  .el-card {
    width: 1000px;
  }
}
</style>
