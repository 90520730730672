<template>
  <div class="view">
    <!-- 主体区域 -->
    <el-card>
      <div slot="header" class="clearfix">
        <div>设备测试历史记录</div>
      </div>

      <!-- 表格 -->
      <el-table ref="productListTable" :data="list" v-loading="loading" border>
        <el-table-column label="序号" type="index" width="50"></el-table-column>

        <el-table-column label="温湿度">
          <template slot-scope="{ row }">
            <div v-if="row.successSteps.includes('1')" class="state-wrapper">
              <div class="state-dot success-dot"></div>
              <div>合格</div>
            </div>
            <div v-else-if="row.errorSteps.includes('1')" class="state-wrapper">
              <div class="state-dot danger-dot"></div>
              <div>不合格</div>
            </div>
            <div v-else class="state-wrapper">
              <div class="state-dot info-dot"></div>
              <div>未完成</div>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="触摸按键">
          <template slot-scope="{ row }">
            <div v-if="row.successSteps.includes('2')" class="state-wrapper">
              <div class="state-dot success-dot"></div>
              <div>合格</div>
            </div>
            <div v-else-if="row.errorSteps.includes('2')" class="state-wrapper">
              <div class="state-dot danger-dot"></div>
              <div>不合格</div>
            </div>
            <div v-else class="state-wrapper">
              <div class="state-dot info-dot"></div>
              <div>未完成</div>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="配网按键">
          <template slot-scope="{ row }">
            <div v-if="row.successSteps.includes('3')" class="state-wrapper">
              <div class="state-dot success-dot"></div>
              <div>合格</div>
            </div>
            <div v-else-if="row.errorSteps.includes('3')" class="state-wrapper">
              <div class="state-dot danger-dot"></div>
              <div>不合格</div>
            </div>
            <div v-else class="state-wrapper">
              <div class="state-dot info-dot"></div>
              <div>未完成</div>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="红外">
          <template slot-scope="{ row }">
            <div v-if="row.successSteps.includes('4')" class="state-wrapper">
              <div class="state-dot success-dot"></div>
              <div>合格</div>
            </div>
            <div v-else-if="row.errorSteps.includes('4')" class="state-wrapper">
              <div class="state-dot danger-dot"></div>
              <div>不合格</div>
            </div>
            <div v-else class="state-wrapper">
              <div class="state-dot info-dot"></div>
              <div>未完成</div>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="zigbee入网">
          <template slot-scope="{ row }">
            <div v-if="row.successSteps.includes('5')" class="state-wrapper">
              <div class="state-dot success-dot"></div>
              <div>合格</div>
            </div>
            <div v-else-if="row.errorSteps.includes('5')" class="state-wrapper">
              <div class="state-dot danger-dot"></div>
              <div>不合格</div>
            </div>
            <div v-else class="state-wrapper">
              <div class="state-dot info-dot"></div>
              <div>未完成</div>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="状态">
          <template slot-scope="{ row }">
            <div v-if="row.result == 0" class="state-wrapper">
              <div class="state-dot info-dot"></div>
              <div>未完成</div>
            </div>
            <div v-else-if="row.result == 1" class="state-wrapper">
              <div class="state-dot success-dot"></div>
              <div>合格</div>
            </div>
            <div v-else-if="row.result == 2" class="state-wrapper">
              <div class="state-dot danger-dot"></div>
              <div>不合格</div>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="测试时间" prop="gmtCreate"></el-table-column>
      </el-table>

      <!-- 分页器 -->
      <PaginationBar
        :pageNo.sync="pagination.pageNum"
        :pageSize.sync="pagination.pageSize"
        :total="pagination.total"
        @change="getList()"
      ></PaginationBar>
    </el-card>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      id: null,
      query: {},
      queryForm: {
        search: ''
      },
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      loading: false,
      list: []
    }
  },

  filters: {},
  computed: {},
  watch: {},

  created() {
    this.id = this.$route.query.id
    this.getList()
  },

  methods: {
    // 获取列表
    async getList() {
      this.loading = true
      const res = await this.$api.deviceFactory.testDeviceHistory({
        deviceCheckId: this.id,
        ...this.query,
        ...this.pagination
      })
      this.loading = false

      if (res.code === '00000') {
        this.list = this.$utils.stringToArray(res.data, 'successSteps', ';')
        this.list = this.$utils.stringToArray(res.data, 'errorSteps', ';')
        this.pagination.total = res.total
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.view {
  .state-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .state-dot {
      width: 10px;
      height: 10px;
      margin-right: 5px;
      border-radius: 5px;
    }

    .success-dot {
      background-color: #63e441;
    }

    .danger-dot {
      background-color: #f56c6c;
    }

    .info-dot {
      background-color: #909399;
    }
  }
}
</style>
