<template>
  <div class="page">
    <!-- 主体区域 -->
    <el-card>
      <!-- 筛选、操作区域 -->
      <OperationBar>
        <template slot="search-box">
          <el-form :model="queryForm" :inline="true">
            <el-form-item label="关键词">
              <el-input
                v-model="queryForm.search"
                placeholder="请输入关键词"
                clearable
                @keyup.enter.native="handleSearch()"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="medium" @click="handleSearch()">查询</el-button>
            </el-form-item>
          </el-form>
        </template>
      </OperationBar>

      <!-- 表格 -->
      <el-table ref="productListTable" :data="list" v-loading="loading" border>
        <el-table-column label="序号" type="index" width="50"></el-table-column>

        <el-table-column label="产品信息" width="300">
          <div slot-scope="{ row }" class="product-info">
            <div class="left">
              <el-image :src="row.basePic"></el-image>
            </div>
            <div class="right">
              <div class="title">{{ row.proName }}</div>
              <div class="type">产品型号：{{ row.type }}</div>
            </div>
          </div>
        </el-table-column>

        <el-table-column label="类目" prop="baseName"></el-table-column>

        <el-table-column label="通讯方式">
          <template slot-scope="{ row }">
            <span v-for="(item, index) in row.linkWay" :key="item">
              {{ item | linkWayFilter }}{{ index + 1 == row.linkWay.length ? '' : '、' }}
            </span>
          </template>
        </el-table-column>

        <el-table-column label="颜色" prop="color"></el-table-column>
        <el-table-column label="测试通过数量" prop="passNum"></el-table-column>
        <el-table-column label="创建时间" prop="gmtCreate"></el-table-column>

        <el-table-column label="操作" width="280">
          <div slot-scope="{ row }" class="action-cell">
            <el-button type="primary" round size="mini" @click="handleDetail(row)">查看详情</el-button>
            <el-button type="primary" round size="mini" @click="checkStorage(row)">库存</el-button>
            <el-button type="primary" round size="mini" @click="createOrder(row)">创建订单</el-button>
            <el-button type="primary" round size="mini" @click="exportSerial(row)">导出</el-button>
          </div>
        </el-table-column>
      </el-table>

      <!-- 分页器 -->
      <PaginationBar
        :pageNo.sync="pagination.pageNum"
        :pageSize.sync="pagination.pageSize"
        :total="pagination.total"
        @change="getList()"
      ></PaginationBar>
    </el-card>

    <!-- 创建订单弹框 -->
    <el-dialog title="创建订单" :visible.sync="orderDialog" width="600px" @closed="clearContents()">
      <el-form ref="orderForm" :model="orderForm" :rules="orderRules" label-width="auto">
        <el-form-item label="订单产品数量" prop="num">
          <el-input-number v-model="orderForm.num" :min="1" :step="1" step-strictly></el-input-number>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="orderDialog = false">取 消</el-button>
        <el-button type="primary" @click="handleOrderSave()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 导出弹框 -->
    <el-dialog title="导出包装序列号" :visible.sync="exportDialog" width="600px" @closed="clearContents()">
      <el-form ref="exportForm" :model="exportForm" :rules="exportRules" label-width="auto">
        <el-form-item label="导出数量" prop="num">
          <el-input-number v-model="exportForm.num" :min="1" :step="1" step-strictly></el-input-number>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="exportDialog = false">取 消</el-button>
        <el-button type="primary" :loading="exportLoading" @click="handleExportSave()">下 载</el-button>
      </span>
    </el-dialog>

    <!-- 下载弹框 -->
    <!-- <el-dialog title="下载文件" :visible.sync="downloadDialog" width="600px">
      <div style="text-align: center">
        <img src="@/assets/device/download.png" style="width: 154px" />
      </div>

      <span slot="footer">
        <el-button type="primary" @click="handleDownload()">下载文件</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import { linkWays } from '@/utils/data'

export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      query: {},
      queryForm: {
        search: '',
        agentId: '',
        linkWay: null
      },
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },

      linkWays,
      loading: false,

      list: [],

      selectProNo: '',

      orderDialog: false,
      orderForm: {},
      defaultOrderForm: {
        num: ''
      },
      orderRules: {
        num: [{ required: true, message: '请输入订单产品数量', trigger: 'blur' }]
      },

      exportDialog: false,
      exportLoading: false,
      exportForm: {},
      defaultExportForm: {
        num: ''
      },
      exportRules: {
        num: [{ required: true, message: '请输入要导出的包装序列号数量', trigger: 'blur' }]
      },

      downloadDialog: false,
      link: ''
    }
  },

  filters: {},

  computed: {},

  watch: {},

  created() {
    this.init()
    this.getList()
  },

  methods: {
    init() {
      this.orderForm = { ...this.defaultOrderForm }
      this.exportForm = { ...this.defaultExportForm }
    },

    // 获取产品列表
    async getList() {
      this.loading = true
      const res = await this.$api.productFactory.getList({ ...this.query, ...this.pagination })
      this.loading = false

      if (res.code === '00000') {
        this.pagination.total = res.total
        this.list = this.$utils.stringToArray(res.data, 'linkWay')
      }
    },

    // 搜索事件
    handleSearch() {
      this.query = { ...this.queryForm }
      this.pagination.pageNum = 1
      this.getList()
    },

    // 查看详情
    handleDetail(row) {
      this.$router.push({
        path: 'detail',
        query: {
          proNo: row.proNo
        }
      })
    },

    // 查看库存
    checkStorage(row) {
      this.$router.push({
        path: '/storage/list',
        query: {
          search: row.proName
        }
      })
    },

    // 创建订单
    createOrder(row) {
      this.selectProNo = row.proNo
      this.orderDialog = true
    },

    // 确认创建订单，调用接口
    handleOrderSave() {
      this.$refs.orderForm.validate(async (valid) => {
        if (!valid) return

        const form = { proNo: this.selectProNo, ...this.orderForm }

        const res = await this.$api.order.createOrder(form)
        if (res.code === '00000') {
          this.$message.success('创建订单成功！')
          this.orderDialog = false
        }
      })
    },

    // 关闭弹窗时清除表单
    clearContents() {
      this.orderForm = { ...this.defaultOrderForm }
      this.exportForm = { ...this.defaultExportForm }
    },

    // 导出产品包装序列号
    exportSerial(row) {
      this.selectProNo = row.proNo
      this.exportDialog = true
    },

    // 确认导出数量，调用接口
    handleExportSave() {
      this.$refs.exportForm.validate(async (valid) => {
        if (!valid) return

        this.exportLoading = true
        const form = { proNo: this.selectProNo, ...this.exportForm }
        const res = await this.$api.productFactory.exportPackSerial(form)
        this.exportLoading = false
        if (res.code === '00000') {
          // this.link = res.data
          this.exportDialog = false
          // this.downloadDialog = true
          window.open(res.data)
        }
      })
    },

    // 下载
    async handleDownload() {
      window.open(this.link)
      this.downloadDialog = false
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  .el-card {
    .el-table {
      .product-info {
        display: flex;
        align-items: center;

        .left {
          .el-image {
            width: 75px;
            height: 75px;
          }
        }

        .right {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-around;
          height: 80px;
          margin-left: 20px;
          // text-align: left;

          .title {
            color: #4e8cff;
            font-size: 16px;
          }

          .type {
          }
        }
      }
      .action-cell {
        .el-button {
          padding: 7px 10px;
        }
      }
    }
  }
}
</style>
