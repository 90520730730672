<template>
  <div class="page">
    <!-- 主体区域 -->
    <el-card>
      <!-- 筛选、操作区域 -->
      <OperationBar>
        <template slot="search-box">
          <el-form :model="queryForm" :inline="true">
            <el-form-item label="关键词">
              <el-input
                v-model="queryForm.search"
                placeholder="请输入关键词"
                clearable
                @keyup.enter.native="handleSearch()"
              ></el-input>
            </el-form-item>
            <el-form-item label="套餐类型">
              <el-select v-model="queryForm.proType" placeholder="请选择套餐类型" @change="handleSearch()">
                <el-option
                  v-for="item in packageType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="订单状态">
              <el-select v-model="queryForm.status" placeholder="请选择订单状态" clearable @change="handleSearch()">
                <el-option
                  v-for="item in userOrderStatus"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="medium" @click="handleSearch()">查询</el-button>
            </el-form-item>
          </el-form>
        </template>
      </OperationBar>

      <!-- 表格 -->
      <el-table
        v-if="[1, 2].includes(queryForm.proType)"
        ref="productListTable"
        :data="list"
        v-loading="loading"
        border
      >
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column label="订单号" prop="orderSn"></el-table-column>
        <el-table-column label="用户昵称" prop="nickName"></el-table-column>
        <el-table-column label="手机号" prop="mobile"></el-table-column>

        <el-table-column label="套餐类型">
          <template slot-scope="{ row }">
            <span>{{ row.proType | packageTypeFilter }}</span>
          </template>
        </el-table-column>

        <el-table-column label="套餐名称" prop="proName"></el-table-column>

        <el-table-column label="支付方式">
          <template slot-scope="{ row }">
            <span>{{ row.payType | payTypeFilter }}</span>
          </template>
        </el-table-column>

        <el-table-column label="支付金额">
          <template slot-scope="{ row }">
            <span>{{ row.payAmount / 100 }}</span>
          </template>
        </el-table-column>

        <el-table-column label="开通状态">
          <template slot-scope="{ row }">
            <div v-if="row.status == 901">开通成功</div>
            <div v-else>
              <div>开通失败</div>
              <el-popover placement="top-start" trigger="click" :content="row.remarks">
                <i class="el-icon-warning" slot="reference" style="color: red; font-size: 20px"></i>
              </el-popover>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="退款状态">
          <template slot-scope="{ row }">
            <div v-if="row.status == 203">退款成功</div>
            <div v-if="row.status == 204">退款中</div>
            <div v-if="row.status == 205">
              <div>退款失败</div>
              <el-popover placement="top-start" trigger="click" :content="row.refundRemark">
                <i class="el-icon-warning" slot="reference" style="color: red; font-size: 20px"></i>
              </el-popover>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="下单时间" prop="gmtCreate"></el-table-column>

        <el-table-column label="操作" width="120">
          <div slot-scope="{ row, $index }" v-if="[201, 205].includes(row.status)" class="action-cell">
            <el-button type="primary" round size="mini" @click="reopen(row)">重新开通</el-button>
            <ConfirmPopover :index="$index" text="发起退款" @confirm="startRefund(row)"></ConfirmPopover>
          </div>
        </el-table-column>
      </el-table>

      <el-table v-if="queryForm.proType == 3" ref="productListTable2" :data="list" v-loading="loading" border>
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column label="用户名称" prop="nickName"></el-table-column>
        <el-table-column label="号码服务商" prop="telephoneType">
          <template slot-scope="{ row }">
            <span>{{ row.telephoneType | telephoneTypeFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column label="手机号码" prop="telephoneNo"></el-table-column>
        <el-table-column label="充值金额">
          <template slot-scope="{ row }">
            <span>{{ row.proPrice / 100 }}</span>
          </template>
        </el-table-column>
        <el-table-column label="支付金额">
          <template slot-scope="{ row }">
            <span>{{ row.payAmount / 100 }}</span>
          </template></el-table-column
        >
        <el-table-column label="充值时间" prop="payTime"></el-table-column>
        <el-table-column label="订单状态">
          <template slot-scope="{ row }">
            <div v-if="row.status == 201">已支付</div>
            <div v-if="row.status == 901">已完成</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120">
          <div slot-scope="{ row, $index }" v-if="row.status == 201" class="action-cell">
            <ConfirmPopover
              :index="$index"
              text="充值完成"
              type="primary"
              @confirm="rechargeCompleted(row)"
            ></ConfirmPopover>
          </div>
        </el-table-column>
      </el-table>

      <!-- 分页器 -->
      <PaginationBar
        :pageNo.sync="pagination.pageNum"
        :pageSize.sync="pagination.pageSize"
        :total="pagination.total"
        @change="getList()"
      ></PaginationBar>
    </el-card>
  </div>
</template>

<script>
import { packageType, userOrderStatus, telephoneType } from '@/utils/data'

export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      query: {
        proType: 1
      },
      queryForm: {
        search: '',
        proType: 1,
        status: null,
        proId: ''
      },
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },

      loading: false,
      packageType,
      userOrderStatus,

      list: [],
      productList: []
    }
  },

  filters: {
    // 套餐类型过滤
    packageTypeFilter(value) {
      const obj = packageType.find((item) => item.value === value)
      return obj ? obj.label : '未知'
    },

    telephoneTypeFilter(value) {
      const obj = telephoneType.find((item) => item.value === value)
      return obj ? obj.label : '未知'
    }
  },

  computed: {},

  watch: {},

  created() {
    this.init()
    this.getList()
  },

  methods: {
    init() {},

    // 获取列表
    async getList() {
      this.loading = true
      const res = await this.$api.purchaseRecord.getList({ ...this.query, ...this.pagination })
      this.loading = false
      if (res.code === '00000') {
        this.list = res.data
        this.pagination.total = res.total
      }
    },

    // 搜索事件
    handleSearch() {
      this.query = { ...this.queryForm }
      this.pagination.pageNum = 1
      this.getList()
    },

    // 重新开通
    async reopen(row) {
      const res = await this.$api.purchaseRecord.reopen({ id: row.id })

      if (res.code === '00000') {
        this.$message.success('重新开通成功！')
        this.getList()
      }
    },

    // 发起退款
    async startRefund(row) {
      const res = await this.$api.purchaseRecord.startRefund({ id: row.id })

      if (res.code === '00000') {
        this.$message.success('发起退款成功！')
        this.getList()
      }
    },

    // 充值完成
    async rechargeCompleted(row) {
      const res = await this.$api.purchaseRecord.rechargeCompleted({ id: row.id })

      if (res.code === '00000') {
        this.$message.success('操作成功！')
        this.getList()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.page {
}
</style>
