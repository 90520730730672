<template>
  <div class="page">
    <el-card>
      <div class="main">
        <el-steps class="left" direction="vertical" :active="stepIndex" space="200px">
          <el-step title="选择产品"></el-step>
          <el-step title="选择通讯方式"></el-step>
          <el-step title="完成"></el-step>
        </el-steps>

        <div class="right">
          <div class="floor_1">
            <el-button v-if="JSON.stringify(selectProductItem_2) == '{}'" type="primary" @click="selectProduct()">
              选择产品
            </el-button>
            <div v-else class="product-box">
              <el-card class="product-item" @click.native="selectProduct()">
                <el-image :src="selectProductItem_2.pic"></el-image>
                <div class="name">{{ selectProductForm.proName }}</div>
              </el-card>
            </div>
          </div>

          <div v-if="stepIndex >= 1" class="floor_2">
            <el-select v-model="linkWay" placeholder="请选择通讯方式" multiple @change="linkWayChange">
              <el-option v-for="item in linkWays" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </div>

          <div v-if="stepIndex == 2" class="floor_3">
            <el-button type="primary" @click="handleSubmit()"> 完 成 </el-button>
          </div>
        </div>
      </div>
    </el-card>

    <!-- 选择产品弹框 -->
    <el-dialog
      custom-class="selectProductDialog"
      title="选择产品"
      :visible.sync="selectProductDialog"
      width="75%"
      top="10vh"
      @closed="clearContents()"
    >
      <el-form ref="selectProductForm" class="selectProductForm" :model="selectProductForm" :rules="rules">
        <el-form-item label="选择产品" prop="baseId" class="select-product-item">
          <el-input v-show="false" v-model="selectProductForm.baseId" disabled></el-input>
          <el-card class="product-list-box">
            <div class="product-list-content">
              <el-card
                v-for="item in libraryList"
                :key="item.id"
                :class="`product-item ${selectProductForm.baseId == item.id ? 'product-item-select' : ''}`"
                @click.native="selectProductLibraryItem(item)"
              >
                <el-image :src="item.pic"></el-image>
                <div class="name">{{ item.name }}</div>
              </el-card>
            </div>
          </el-card>
        </el-form-item>

        <el-form-item label="产品名称" prop="proName">
          <el-input v-model="selectProductForm.proName" placeholder="请填写产品名称"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="selectProductDialog = false">取 消</el-button>
        <el-button type="primary" @click="selectProductConfirm()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { linkWays } from '@/utils/data'

export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      stepIndex: 0,
      selectProductDialog: false,
      libraryList: [],
      selectProductItem_1: {},
      selectProductItem_2: {},
      linkWays,

      selectProductForm: {
        baseId: null,
        proName: ''
      },
      linkWay: null,
      rules: {
        baseId: [{ required: true, message: '请选择产品', trigger: 'blur' }],
        proName: [{ required: true, message: '请填写产品名称', trigger: 'blur' }]
      }
    }
  },

  computed: {},

  watch: {},

  created() {
    this.getLibraryList()
  },

  methods: {
    // 获取产品库列表
    async getLibraryList() {
      const res = await this.$api.product.getLibraryList()

      if (res.code === '00000') {
        this.libraryList = res.data
      }
    },

    // 选择产品弹框
    selectProduct() {
      this.selectProductDialog = true
    },

    // 选择产品
    selectProductLibraryItem(item) {
      this.selectProductItem_1 = item
      this.selectProductForm.baseId = item.id
    },

    // 关闭弹窗时清除表单
    clearContents() {
      // this.$utils.resetForm(this, 'selectProductForm')
    },

    // 产品选择确认事件
    selectProductConfirm() {
      this.$refs.selectProductForm.validate(async (valid) => {
        if (!valid) return

        this.stepIndex = 1
        this.selectProductItem_2 = this.selectProductItem_1
        this.selectProductDialog = false
      })
    },

    // 通讯方式改变时触发
    linkWayChange(values) {
      if (values.length > 0) {
        this.stepIndex = 2
      } else {
        this.stepIndex = 1
      }
    },

    // 提交
    async handleSubmit() {
      const form = { ...this.selectProductForm, linkWay: this.linkWay }
      form.linkWay = form.linkWay.join(',')

      const res = await this.$api.product.createProduct(form)

      if (res.code === '00000') {
        this.$message.success('添加成功！')
        this.$router.back()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  height: 100%;

  .main {
    display: flex;

    .left {
      width: 200px;
      height: 100%;
    }

    .right {
      position: relative;
      width: 100%;

      .floor_1 {
        .product-box {
          /deep/ .product-item {
            width: 500px;
            cursor: pointer;

            .el-card__body {
              display: flex;
              align-items: center;
              padding: 10px;

              .el-image {
                width: 100px;
                max-height: 150px;
              }
              .name {
                margin-left: 10px;
                font-weight: 700;
              }
            }
          }
        }
      }

      .floor_2 {
        position: absolute;
        top: 200px;
        left: 0;
      }

      .floor_3 {
        position: absolute;
        top: 400px;
        left: 0;
      }
    }
  }

  /deep/ .selectProductDialog {
    .selectProductForm {
      .select-product-item {
        .product-list-box {
          width: 100%;
          line-height: normal;

          .product-list-content {
            display: flex;
            flex-wrap: wrap;
            max-height: 30vh;
            overflow-y: auto;

            .product-item {
              display: flex;
              // align-items: center;
              margin: 0 20px 20px 0;
              cursor: pointer;

              .el-card__body {
                display: flex;
                align-items: center;
                width: 250px;
                padding: 10px;

                .el-image {
                  width: 50px;
                }
                .name {
                  margin-left: 10px;
                  font-weight: 700;
                }
              }
            }
            .product-item-select {
              background-color: #409eff;
            }
          }
        }
      }
    }
  }
}
</style>
