<template>
  <div class="view">
    <ProductFrame
      ref="productFrame"
      :title="title + ' : '"
      :showSearchBtn.sync="showSearchBtn"
      remoteId="39"
      :serialNumber="serialNumber"
      @change="onChange"
    >
      <div slot="content">
        <el-input v-model="detail.controlId" placeholder="· 待添加" disabled></el-input>
      </div>
    </ProductFrame>
  </div>
</template>

<script>
import ProductFrame from './components/ProductFrame'

export default {
  name: '',
  components: { ProductFrame },

  props: {
    serialNumber: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: '门磁传感器'
    }
  },

  data() {
    return {
      showSearchBtn: true,
      detail: {}
    }
  },

  filters: {},
  computed: {},
  watch: {},

  created() {},

  methods: {
    // 设备改变时执行
    onChange(data) {
      this.detail = data
      this.$emit('change', this.detail)
      this.checkout()
    },

    // 检测结果
    checkout() {
      if (this.detail.controlId) {
        this.$emit('resultChange', true)
      } else {
        this.$emit('resultChange', false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.view {
  .testStatus-wrapper {
    display: flex;
    align-items: center;
    margin-top: 10px;

    .title {
      font-size: 18px;
    }

    .state {
      width: 120px;
      font-size: 20px;
    }

    .progress-box {
      display: flex;
      align-items: center;

      .block {
        width: 28px;
        height: 10px;
        margin-right: 10px;
        background-color: #d5d5d5;
      }
      .pass {
        background-color: #09d93f;
      }
    }
  }
}
</style>
