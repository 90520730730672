<template>
  <div class="view">
    <ProductFrame
      ref="productFrame"
      :title="title + ' : '"
      :showSearchBtn.sync="showSearchBtn"
      remoteId="42"
      :serialNumber="serialNumber"
      @change="onChange"
    >
      <div slot="content">
        <el-input v-model="detail.controlId" placeholder="· 待添加" disabled></el-input>
      </div>
      <div v-if="detail.controlId" slot="middle">
        <div class="testStatus-wrapper">
          <div class="title">设备状态：</div>
          <div class="state">{{ state }}</div>
          <div class="progress-box">
            <div :class="{ block: true, pass: result.progress >= 1 }"></div>
            <div :class="{ block: true, pass: result.progress >= 2 }"></div>
            <div class="number">{{ result.progress }}/2</div>
          </div>
        </div>
        <LightState @change="onLightChange"></LightState>
      </div>
    </ProductFrame>
  </div>
</template>

<script>
import ProductFrame from './components/ProductFrame'
import LightState from './components/LightState'

export default {
  name: '',
  components: { ProductFrame, LightState },

  props: {
    serialNumber: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: '人体传感器'
    }
  },

  data() {
    return {
      showSearchBtn: true,
      detail: {},
      state: '--',
      result: {
        progress: 0,
        light: null
      }
    }
  },

  filters: {},
  computed: {},
  watch: {},

  created() {},

  methods: {
    // 设备改变时执行
    onChange(data) {
      this.detail = data
      this.$emit('change', this.detail)
      this.resetTestResult()
    },

    // 收到 mqtt 信息
    receiveMessage(msg) {
      this.state = msg.alarmState ? '有人' : '无人'
      this.result.progress++
      this.checkout()
    },

    // 灯光测试结果改变时执行
    onLightChange(value) {
      this.result.light = !!value
      this.checkout()
    },

    // 重置测试结果
    resetTestResult() {
      this.state = '--'
      this.result = {
        progress: 0,
        light: null
      }
    },

    // 检测结果
    checkout() {
      const progressResult = this.result.progress >= 2
      const lightResult = this.result.light

      if (progressResult && lightResult) {
        this.$emit('resultChange', true)
      } else {
        this.$emit('resultChange', false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.view {
  .testStatus-wrapper {
    display: flex;
    align-items: center;
    margin-top: 10px;

    .title {
      font-size: 18px;
    }

    .state {
      width: 120px;
      font-size: 20px;
    }

    .progress-box {
      display: flex;
      align-items: center;

      .block {
        width: 28px;
        height: 10px;
        margin-right: 10px;
        background-color: #d5d5d5;
      }
      .pass {
        background-color: #09d93f;
      }
    }
  }
}
</style>
