<template>
  <div class="page">
    <!-- 主体区域 -->
    <el-card v-loading="loading">
      <div slot="header" class="clearfix">
        <div>设备测试</div>
        <div style="margin-top: 10px; font-size: 16px">
          <span>当前测试设备：{{ detail.serialNumber }}</span>
          <el-button
            type="primary"
            size="medium"
            @click="sendControlSignal('light', lightState ? 1 : 0)"
            style="margin-left: 20px"
          >
            {{ lightState ? '开 灯' : '关 灯' }}
          </el-button>
        </div>
      </div>

      <!-- 步骤条 -->
      <el-steps :active="stepIndex" align-center>
        <el-step
          title="灯光测试"
          :status="
            resultForm.light == 1 ? 'success' : resultForm.light == 2 ? 'error' : stepIndex == 0 ? 'process' : 'wait'
          "
        ></el-step>
        <el-step
          title="红外控制"
          :status="resultForm.IR == 1 ? 'success' : resultForm.IR == 2 ? 'error' : stepIndex == 1 ? 'process' : 'wait'"
        ></el-step>
        <el-step
          title="添加子设备"
          :status="
            resultForm.sub == 1 ? 'success' : resultForm.sub == 2 ? 'error' : stepIndex == 2 ? 'process' : 'wait'
          "
        ></el-step>
        <el-step
          title="重置设备"
          :status="
            resultForm.reset == 1 ? 'success' : resultForm.reset == 2 ? 'error' : stepIndex == 3 ? 'process' : 'wait'
          "
        ></el-step>
        <el-step title="完成" :status="stepIndex == 4 ? 'success' : ''"></el-step>
      </el-steps>

      <div class="main">
        <div class="floor floor_1">
          <div class="text">
            <div v-if="stepIndex == 0">请触摸设备上的开关，查看设备开关控制是否正常</div>
            <div v-if="stepIndex == 1">点击按钮发送红外指令，测试红外控制是否正常</div>
            <div v-if="stepIndex == 2">长按设备按钮，进入配网模式，测试是否能添加设备</div>
            <div v-if="stepIndex == 3 || stepIndex == 4">使用卡针点按复位键，查看夜灯是否闪烁</div>
          </div>

          <div class="content">
            <div v-if="stepIndex == 0">
              <img src="@/assets/device/product-picture.png" />
            </div>
            <div v-if="stepIndex == 1">
              <img src="@/assets/device/IR_on.png" @click="sendControlSignal('infraredsend')" style="cursor: pointer" />
            </div>
            <div v-if="stepIndex == 2" class="sub-wrapper">
              <div class="sub-box">
                <img :class="{ 'left-pic': true, rotate: rotateSearchPic }" src="@/assets/device/search_radar.png" />
                <div class="text">{{ searchText }}</div>
                <el-button type="primary" size="medium" :disabled="searchBtnDisabled" @click="handleSearch()">
                  搜 索
                </el-button>
              </div>
            </div>
            <div v-if="stepIndex == 3 || stepIndex == 4" class="reset-box">
              <img src="@/assets/device/reset_device.gif" />
              <img src="@/assets/device/device_bright.jpg" />
            </div>
          </div>
        </div>

        <div class="floor floor_2">
          <div class="text">
            <div v-if="stepIndex == 0">灯光是否正常亮灭？</div>
            <div v-if="stepIndex == 1">红外控制是否成功？</div>
            <div v-if="stepIndex == 2">子设备能否正常添加？</div>
            <div v-if="stepIndex == 3 || stepIndex == 4">复位按钮功能正常？</div>
          </div>

          <div class="content">
            <div v-if="stepIndex == 1">
              <el-button key="IR" type="primary" :disabled="IRDisabled" @click="setTestResult(1)">测试通过</el-button>
              <el-button type="primary" @click="setTestResult(2)">测试不通过</el-button>
            </div>
            <div v-else-if="stepIndex == 2">
              <el-button key="sub" type="primary" :disabled="subDisabled" @click="setTestResult(1)">测试通过</el-button>
              <el-button type="primary" @click="setTestResult(2)">测试不通过</el-button>
            </div>
            <div v-else>
              <el-button key="else" type="primary" @click="setTestResult(1)">测试通过</el-button>
              <el-button type="primary" @click="setTestResult(2)">测试不通过</el-button>
            </div>
          </div>
        </div>

        <div class="floor floor_3">
          <div class="text">
            <div v-if="stepIndex == 0">灯光测试结果</div>
            <div v-if="stepIndex == 1">红外测试结果</div>
            <div v-if="stepIndex == 2">子设备添加测试结果</div>
            <div v-if="stepIndex == 3 || stepIndex == 4">复位按钮测试结果</div>
          </div>

          <div class="content">
            <div v-if="stepIndex == 0">
              {{ resultForm.light == 1 ? '通过' : resultForm.light == 2 ? '不通过' : '/' }}
            </div>
            <div v-if="stepIndex == 1">
              {{ resultForm.IR == 1 ? '通过' : resultForm.IR == 2 ? '不通过' : '/' }}
            </div>
            <div v-if="stepIndex == 2">
              {{ resultForm.sub == 1 ? '通过' : resultForm.sub == 2 ? '不通过' : '/' }}
            </div>
            <div v-if="stepIndex == 3 || stepIndex == 4">
              {{ resultForm.reset == 1 ? '通过' : resultForm.reset == 2 ? '不通过' : '/' }}
            </div>
          </div>
        </div>
      </div>

      <div class="action-bar">
        <el-button type="primary" plain @click="$router.back()">返回列表</el-button>
        <el-button v-if="stepIndex >= 1" type="primary" plain @click="lastStep()">上一步</el-button>
        <el-button
          v-if="stepIndex <= 2 && resultForm[resultNameList[stepIndex]] != 2"
          type="primary"
          :disabled="resultForm[resultNameList[stepIndex]] != 1"
          @click="nextStep()"
        >
          下一步
        </el-button>
        <el-button
          v-if="stepIndex >= 3 || resultForm[resultNameList[stepIndex]] == 2"
          type="success"
          @click="handleFinish()"
        >
          测试完成
        </el-button>
      </div>
    </el-card>

    <!-- 结束测试弹框 -->
    <el-dialog title="" :visible.sync="finishTestDialog" width="600px">
      <el-result
        v-if="hasNext"
        icon="success"
        title="设备测试完成"
        :subTitle="`当前设备 ${detail.serialNumber} 已测试完成，是否开始测试下一个设备？`"
      ></el-result>
      <el-result
        v-else
        icon="warning"
        title="连接设备已测完"
        subTitle="当前列表中设备已全部测试完成，是否退出测试？"
      ></el-result>

      <span slot="footer">
        <el-button @click="back()">返 回</el-button>
        <el-button v-if="hasNext" type="primary" @click="testNext()">确 定</el-button>
        <el-button v-if="!hasNext" type="primary" @click="back()">退 出</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mqtt from 'mqtt'
import CryptoJS from 'crypto-js'

export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      topic: '',
      client: null,
      detail: {},
      hasNext: false,
      nextDetail: {},
      stepIndex: 0,
      stepIndex2: 1,
      resultForm: {
        light: 0,
        IR: 0,
        sub: 0,
        reset: 0
      },
      resultNameList: [],
      loading: false,
      lightState: true,

      IRDisabled: true,
      subDisabled: true,

      rotateSearchPic: false,
      searchText: '点击搜索进入搜索模式',
      searchBtnDisabled: false,
      countdownSecond: 60,
      countdownTimer: null,
      pollingTimer: null,
      searchToken: null,
      subDetail: {},

      nextDisabled: false,

      finishTestDialog: false
    }
  },

  filters: {},
  computed: { ...mapState('user', ['userInfo']) },
  watch: {},

  created() {
    this.init()
    this.setData()
    this.registerMqtt()
  },

  async beforeRouteLeave(to, from, next) {
    if (!this.isSave && localStorage.getItem('token')) {
      this.setCurrentNoPass()
      await this.finishTest(false)
      next()
    } else {
      next()
    }
  },

  methods: {
    init() {
      this.detail = JSON.parse(localStorage.getItem('testDeviceDetail'))
      this.topic = process.env.VUE_APP_MQTT_BASE_TOPIC + this.userInfo.id
      this.resultNameList = Object.keys(this.resultForm)
    },

    // 设置数据
    setData() {
      // 设置当前步骤
      if (this.detail.steps === 0) {
        this.stepIndex = 0
      } else {
        this.stepIndex = this.detail.steps - 1
        this.stepIndex2 = this.detail.steps
      }

      this.resultForm = {
        light: 0,
        IR: 0,
        sub: 0,
        reset: 0
      }

      // 将当前步骤之前全部设为成功
      for (let i = 0; i < this.stepIndex; i++) {
        this.resultForm[this.resultNameList[i]] = 1
      }

      // 设置错误项目
      if (this.detail.errorSteps) {
        this.detail.errorSteps = this.detail.errorSteps.split(';')

        this.detail.errorSteps.forEach((item, index) => {
          this.resultNameList.forEach((key, index2) => {
            if (item - 1 === index2) {
              this.resultForm[key] = 2
            }
          })
        })
      }

      this.IRDisabled = true
      this.subDisabled = true
      this.rotateSearchPic = false
      this.searchBtnDisabled = false
      this.searchText = '点击搜索进入搜索模式'
      this.subDetail = {}
      if (this.pollingTimer) clearInterval(this.pollingTimer)
      if (this.countdownTimer) clearInterval(this.countdownTimer)
    },

    // 注册 mqtt
    async registerMqtt() {
      const res = await this.$api.other.getMqttOptions()

      if (res.code === '00000') {
        const credential = res.data.deviceCredential
        const instanceId = credential.instanceId
        const clientId = credential.clientId
        const accessKey = credential.deviceAccessKeyId
        const secretKey = credential.deviceAccessKeySecret

        this.client = mqtt.connect('wss://' + instanceId + '.mqtt.aliyuncs.com:443', {
          clientId: clientId,
          username: 'DeviceCredential|' + accessKey + '|' + instanceId,
          password: CryptoJS.HmacSHA1(clientId, secretKey).toString(CryptoJS.enc.Base64)
        })

        this.setMqttEvents()
      }
    },

    // 设置 mqtt 事件
    setMqttEvents() {
      this.client.on('connect', (e) => {
        console.log('mqtt连接成功')
        this.client.subscribe(this.topic, (e) => {
          console.log('topic订阅成功: ' + this.topic)
        })
      })

      // 接收消息
      this.client.on('message', (topic, message) => {
        message = JSON.parse(message.toString())
        console.log(topic, message)

        if (message.msg.irTest === 1) {
          this.IRDisabled = false
          this.setCurrentTestResult(1)
        } else {
        }
      })
    },

    // 设置项目测试结果
    setTestResult(result) {
      this.setCurrentTestResult(result)

      if (this.stepIndex + 1 > this.stepIndex2) {
        this.stepIndex2 = this.stepIndex + 1
      }
    },

    // 设置当前项目测试结果
    setCurrentTestResult(result) {
      const index = this.stepIndex === this.resultNameList.length ? this.resultNameList.length - 1 : this.stepIndex
      this.resultForm[this.resultNameList[index]] = result
    },

    // 发送信号
    async sendControlSignal(name, flag) {
      const res = await this.$api.deviceFactory.sendControlSignal({
        serialNumber: this.detail.serialNumber,
        command: name,
        isLight: flag
      })

      if (res.code === '00000') {
        if (name === 'light') {
          this.lightState = !this.lightState
        }
      }
    },

    // 搜索子设备
    async handleSearch() {
      this.rotateSearchPic = true
      this.countdown()

      const res = await this.$api.packageApi.getTokenOfSearchSubDevice({
        serial: this.detail.serialNumber,
        remoteId: '39'
      })

      if (res.code === '00000') {
        this.searchToken = res.data
        this.polling()
      }
    },

    // 倒计时
    countdown() {
      this.countdownSecond = 60

      this.searchBtnDisabled = true
      this.searchText = '正在搜索(' + this.countdownSecond + ')'

      this.countdownTimer = setInterval(() => {
        this.countdownSecond--
        this.searchText = '正在搜索(' + this.countdownSecond + ')'
        if (this.countdownSecond === 0) {
          clearInterval(this.countdownTimer)
          this.searchBtnDisabled = false
          this.searchText = '点击搜索进入搜索模式'
          this.rotateSearchPic = false
        }
      }, 1000)
    },

    // 轮询子设备
    polling() {
      this.pollingTimer = setInterval(async () => {
        if (this.countdownSecond !== 0) {
          const res = await this.$api.packageApi.searchSubDeviceOfCreateCombo(this.searchToken)
          if (res.code === '00000') {
            this.subDetail = res.data
            this.rotateSearchPic = false
            this.countdownSecond = 0
            this.searchBtnDisabled = false
            this.searchText = this.subDetail.name
            this.subDisabled = false
            this.setCurrentTestResult(1)
            clearInterval(this.pollingTimer)
            clearInterval(this.countdownTimer)
            this.handleDelete()
          } else if (res.code === 'D0330') {
            this.rotateSearchPic = false
            this.$message.error(res.message)
          }
        } else {
          clearInterval(this.pollingTimer)
        }
      }, 5000)
    },

    // 移除子设备
    async handleDelete() {
      const res = await this.$api.packageApi.deleteSubDeviceOfCreateCombo(this.subDetail.deviceRemoteId)

      if (res.code === '00000') {
      }
    },

    // 上一步
    lastStep() {
      this.stepIndex--
      this.stepIndex2--
    },

    // 下一步
    nextStep() {
      this.setCurrentNoPass()

      this.stepIndex++
      this.stepIndex2++
    },

    // 设置当前步骤测试结果为未通过
    setCurrentNoPass() {
      // 如果未选择测试结果，则设为不通过
      if (this.resultForm[this.resultNameList[this.stepIndex]] === 0) {
        this.resultForm[this.resultNameList[this.stepIndex]] = 2
      }
    },

    // 完成
    handleFinish() {
      this.setCurrentNoPass()

      if (this.stepIndex === 3) {
        this.stepIndex = 4
        this.stepIndex2 = 5
      }

      this.finishTest(true)
    },

    // 确认完成，调用接口
    async finishTest(showFinishTestDialog) {
      this.loading = true

      const form = {
        id: this.detail.id,
        steps: this.stepIndex2,
        result: 0,
        msg: '',
        errorSteps: null
      }

      this.resultNameList.forEach((key, index) => {
        const step = index + 1
        if (this.resultForm[key] === 2) {
          // 设置错误信息
          let str = ''
          if (step === 1) {
            str = '灯光控制失败'
          } else if (step === 2) {
            str = '红外控制失败'
          } else if (step === 3) {
            str = '子设备添加失败'
          } else if (step === 4) {
            str = '复位按钮测试失败'
          }

          // 设置错误步骤
          // if (!form.errorSteps) {
          //   form.errorSteps = step
          //   form.msg = str
          // } else {
          //   form.errorSteps = form.errorSteps + ';' + step
          //   form.msg = form.msg + '；' + str
          // }
          form.errorSteps = step
          form.msg = str
        }
      })

      // 设置测试结果
      // this.resultNameList.forEach((key, index) => {
      //   if (this.resultForm[key] === 0) {
      //     form.result = 0
      //     form.msg = '测试未完成'
      //   } else {
      //     if (form.errorSteps) {
      //       form.result = 2
      //     } else {
      //       form.result = 1
      //     }
      //   }
      // })
      if (form.errorSteps) {
        form.result = 2
      } else {
        form.result = 1
      }

      const res = await this.$api.deviceFactory.finishTest(form)
      this.loading = false
      if (res.code === '00000') {
        if (res.data) {
          this.nextDetail = res.data
          this.hasNext = true
        } else {
          this.nextDetail = {}
          this.hasNext = false
        }
        if (showFinishTestDialog) {
          this.finishTestDialog = true
        }
      }
    },

    // 测试下一个设备
    testNext() {
      this.finishTestDialog = false
      localStorage.setItem('testDeviceDetail', JSON.stringify(this.nextDetail))
      this.detail = JSON.parse(localStorage.getItem('testDeviceDetail'))
      this.nextDetail = {}
      this.setData()
    },

    // 离开页面
    back() {
      this.isSave = true
      this.$router.back()
    }
  },

  destroyed() {
    this.client.end()
  }
}
</script>

<style scoped lang="scss">
.page {
  .el-steps {
    /deep/ .el-step__head.is-success {
      color: #409eff;
      border-color: #409eff;
    }
    /deep/ .el-step__main {
      .el-step__title.is-success {
        color: #409eff;
      }
    }
  }

  .main {
    margin-top: 80px;
    .floor {
      margin-top: 50px;
      text-align: center;

      .text {
      }

      .content {
        margin-top: 20px;
        img {
          width: 106px;
        }
      }
    }

    .floor_1 {
      .content {
        .sub-wrapper {
          .sub-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 400px;
            height: 75px;
            margin: auto;
            padding: 15px;
            border-radius: 10px;
            background-color: #fafafa;
            .left-pic {
              width: 75px;
              height: 75px;
            }
            .rotate {
              animation: rotate 3s linear infinite;
            }
            @keyframes rotate {
              100% {
                transform: rotate(360deg);
              }
            }
            .text {
              // margin-left: 40px;
            }
          }
        }

        .reset-box {
          img {
            width: 150px;
            margin-left: 20px;
            &:first-child {
              margin-left: 0;
            }
          }
        }
      }
    }

    .floor_3 {
      .content {
        font-size: 20px;
        font-weight: 700;
      }
    }
  }

  .action-bar {
    display: flex;
    justify-content: space-around;
    max-width: 800px;
    margin: 80px auto 0;
  }
}
</style>
