<template>
  <div class="page">
    <el-row :gutter="20">
      <el-col :span="10" :xs="24">
        <!-- 菜单管理 -->
        <el-card class="menu-card">
          <!-- 筛选、操作区域 -->
          <OperationBar>
            <template slot="btn-box">
              <el-button type="primary" size="medium" round @click="handleCreateMenu()">新增菜单</el-button>
            </template>
          </OperationBar>

          <el-table
            :data="list"
            row-key="id"
            v-loading="loading"
            border
            highlight-current-row
            :tree-props="{ hasChildren: 'hasChildren', children: 'children' }"
            @row-click="handleRowClick"
          >
            <el-table-column label="菜单名称" prop="name"></el-table-column>

            <el-table-column label="操作" max-width="300">
              <div slot-scope="{ row, $index }" class="action-cell">
                <el-button type="primary" round size="mini" @click="handleEdit(row, 'menu')">编辑</el-button>
                <ConfirmPopover :index="$index" @confirm="handleDelete(row, 'menu')"></ConfirmPopover>
              </div>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>

      <el-col :span="14" :xs="24">
        <!-- 权限管理 -->
        <el-card class="perm-card">
          <!-- 筛选、操作区域 -->
          <OperationBar>
            <template slot="btn-box">
              <el-button type="primary" size="medium" round @click="handleCreatePerm()">新增权限</el-button>
            </template>
          </OperationBar>

          <el-table :data="permList" row-key="id" v-loading="permLoading" border>
            <el-table-column label="权限名称" prop="name"></el-table-column>

            <el-table-column label="操作" max-width="300">
              <div slot-scope="{ row, $index }" class="action-cell">
                <el-button type="primary" round size="mini" @click="handleEdit(row, 'perm')">编辑</el-button>
                <ConfirmPopover :index="$index" @confirm="handleDelete(row, 'perm')"></ConfirmPopover>
              </div>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>

    <!-- 编辑菜单弹框 -->
    <el-dialog title="编辑菜单" :visible.sync="menuDialog" width="600px" @closed="clearContents()">
      <el-form ref="editForm" :model="editForm" :rules="editRules" label-width="auto">
        <el-form-item label="父级菜单" prop="parentId" class="parentId-item">
          <el-cascader
            ref="parentIdCascaderRef"
            v-model="editForm.parentId"
            :options="list2"
            :props="parentIdProps"
            @change="parentIdChange"
          ></el-cascader>
        </el-form-item>

        <el-form-item label="菜单名称" prop="name">
          <el-input v-model="editForm.name" placeholder="请填写菜单名称"></el-input>
        </el-form-item>

        <el-form-item label="路由路径" prop="path">
          <el-input v-model="editForm.path" placeholder="请填写路由路径"></el-input>
        </el-form-item>

        <el-form-item label="页面路径" prop="component">
          <el-input v-model="editForm.component" placeholder="请填写页面路径">
            <template slot="prepend">@/views/</template>
            <template slot="append">.vue</template>
          </el-input>
        </el-form-item>

        <el-form-item label="排序">
          <el-input-number v-model="editForm.sort" :min="1" :step="1" step-strictly></el-input-number>
        </el-form-item>

        <el-form-item label="是否显示">
          <el-switch v-model="editForm.visible" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="menuDialog = false">取 消</el-button>
        <el-button type="primary" @click="handleSave()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 编辑权限弹框 -->
    <el-dialog title="编辑权限" :visible.sync="permDialog" width="600px" @closed="clearContents()">
      <el-form ref="permForm" :model="permForm" :rules="permRules" label-width="auto">
        <el-form-item label="权限名称" prop="name">
          <el-input v-model="permForm.name" placeholder="请填写权限名称"></el-input>
        </el-form-item>

        <el-form-item label="权限标识" prop="btnPerm">
          <el-input v-model="permForm.btnPerm" placeholder="product:list:add"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="permDialog = false">取 消</el-button>
        <el-button type="primary" @click="handlePermSave()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      list: [],
      list2: [],
      loading: false,
      props: {
        label: 'name'
      },
      menuDialog: false,
      editForm: {},
      defaultEditForm: {
        parentId: '',
        name: '',
        path: '',
        component: 'Layout',
        sort: 1,
        visible: 1
      },
      editRules: {
        parentId: [{ required: true, message: '请选择父级菜单', trigger: 'change' }],
        name: [{ required: true, message: '请填写菜单名称', trigger: 'blur' }],
        path: [{ required: true, message: '请填写路由路径', trigger: 'blur' }],
        component: [{ required: true, message: '请填写页面路径', trigger: 'blur' }]
      },
      parentIdProps: {
        value: 'id',
        label: 'name',
        checkStrictly: true,
        emitPath: false
      },

      permDialog: false,
      permLoading: false,
      permList: [],
      menuId: null,
      permForm: {},
      defaultPermForm: {
        id: '',
        name: '',
        btnPerm: ''
      },
      permRules: {
        name: [{ required: true, message: '请填写权限名称', trigger: 'blur' }],
        btnPerm: [{ required: true, message: '请填写权限标识', trigger: 'blur' }]
      }
    }
  },

  computed: {},

  watch: {},

  created() {
    this.init()
    this.getList()
  },

  methods: {
    init() {
      this.editForm = { ...this.defaultEditForm }
      this.permForm = { ...this.defaultPermForm }
    },

    // 获取列表
    async getList() {
      this.loading = true
      const res = await this.$api.menu.getList()
      this.loading = false

      if (res.code === '00000') {
        this.list = res.data

        const topMenu = { id: '0', name: '顶级菜单' }
        this.list2 = [topMenu, ...res.data]
      }
    },

    // 关闭弹窗时清除表单
    clearContents() {
      this.editForm = { ...this.defaultEditForm }
      this.permForm = { ...this.defaultPermForm }
    },

    // 父级菜单改变时触发
    parentIdChange(e) {
      this.$refs.parentIdCascaderRef.dropDownVisible = false
    },

    // 创建菜单
    handleCreateMenu() {
      this.menuDialog = true
    },

    // 行点击
    handleRowClick(row) {
      this.menuId = row.id
      this.getPermList(row.id)
    },

    // 获取权限列表
    async getPermList(id) {
      this.permLoading = true
      const res = await this.$api.permission.getList({ menuId: id })
      this.permLoading = false

      if (res.code === '00000') {
        this.permList = res.data
      }
    },

    // 创建权限
    handleCreatePerm() {
      if (this.menuId) {
        this.permDialog = true
      } else {
        this.$message.error('请先选择一个菜单')
      }
    },

    // 编辑
    handleEdit(row, type) {
      if (type === 'menu') {
        this.editForm = { ...row }
        this.menuDialog = true
      } else if (type === 'perm') {
        this.permForm = { ...row }
        this.permDialog = true
      }
    },

    // 删除
    async handleDelete(row, type) {
      if (type === 'menu') {
        const res = await this.$api.menu.deleteMenu(row.id)
        if (res.code === '00000') {
          this.$message.success('删除成功！')
          this.getList()
        }
      } else if (type === 'perm') {
        const res = await this.$api.permission.deletePerm(row.id)
        if (res.code === '00000') {
          this.$message.success('删除成功！')
          this.getPermList(this.menuId)
        }
      }
    },

    // 保存
    handleSave() {
      this.$refs.editForm.validate(async (valid) => {
        if (!valid) return

        if (this.editForm.id) {
          const res = await this.$api.menu.updateMenu(this.editForm)
          if (res.code === '00000') {
            this.$message.success('修改成功！')
            this.menuDialog = false
            this.getList()
          }
        } else {
          const res = await this.$api.menu.createMenu(this.editForm)
          if (res.code === '00000') {
            this.$message.success('创建成功！')
            this.menuDialog = false
            this.getList()
          }
        }
      })
    },

    // 权限保存
    handlePermSave() {
      this.$refs.permForm.validate(async (valid) => {
        if (!valid) return

        const form = { menuId: this.menuId, ...this.permForm }

        if (form.id) {
          const res = await this.$api.permission.updatePerm(form)
          if (res.code === '00000') {
            this.$message.success('修改成功！')
            this.permDialog = false
            this.getPermList(this.menuId)
          }
        } else {
          const res = await this.$api.permission.createPerm(form)
          if (res.code === '00000') {
            this.$message.success('创建成功！')
            this.permDialog = false
            this.getPermList(this.menuId)
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  .el-card {
  }

  .menu-card {
  }

  .perm-card {
  }
}
</style>
