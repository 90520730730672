<template>
  <div class="page">
    <el-card>
      <div slot="header">
        <span>产品信息</span>
      </div>

      <!-- 表单区域 -->
      <el-form ref="form" :model="form" :rules="rules" label-width="auto">
        <el-form-item label="产品图片">
          <UploadImg :imgUrl.sync="form.basePic" :size="100"></UploadImg>
          <!-- <el-image :src="form.basePic" class="product-img"></el-image> -->
        </el-form-item>

        <el-form-item label="产品名称">
          <el-input v-model="form.proName" disabled></el-input>
        </el-form-item>

        <el-form-item label="开发者">
          <el-input v-model="form.agentName" disabled></el-input>
        </el-form-item>

        <el-form-item label="一级分类">
          <el-col :span="10">
            <el-select v-model="form.firstCategoryId" placeholder="请选择一级分类" @change="onChangeFirstCategory">
              <el-option
                v-for="item in firstCategoryList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-col>
          <el-col class="line" :span="4" style="text-align: right">二级分类&nbsp;&nbsp;&nbsp;</el-col>
          <el-col :span="10">
            <el-select v-model="form.categoryId" placeholder="请选择二级分类">
              <el-option
                v-for="item in secondCategoryList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-col>
        </el-form-item>

        <el-form-item label="产品型号">
          <el-col :span="10">
            <el-input v-model="form.type"></el-input>
          </el-col>
          <el-col class="line" :span="4" style="text-align: right">产品类目&nbsp;&nbsp;&nbsp;</el-col>
          <el-col :span="10">
            <el-input v-model="form.baseName" disabled></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="产品颜色">
          <el-col :span="10">
            <el-input v-model="form.color"></el-input>
          </el-col>
          <el-col class="line" :span="4" style="text-align: right">产品重量&nbsp;&nbsp;&nbsp;</el-col>
          <el-col :span="10">
            <el-input v-model="form.weight"></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="产品规格">
          <el-input v-model="form.size"></el-input>
        </el-form-item>

        <el-form-item label="连接方式" prop="linkWay">
          <el-select v-model="form.linkWay" placeholder="请选择连接方式" multiple>
            <el-option v-for="item in linkWays" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="热点前缀">
          <el-input v-model="form.spotPrefix"></el-input>
        </el-form-item>

        <el-form-item label="配网方式">
          <el-table ref="networkWaysTable" :data="form.networkWays" border>
            <el-table-column label="配网方式" prop="type">
              <template slot-scope="{ row }">
                <span>{{ row.type | networkWayFilter }}</span>
              </template>
            </el-table-column>
            <el-table-column label="闪烁方式" prop="frequency"></el-table-column>
            <el-table-column label="闪烁频率" prop="flashWay"></el-table-column>
            <el-table-column label="示例图" width="82">
              <template slot-scope="{ row }">
                <el-image :src="row.pic" style="width: 60px"></el-image>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="82">
              <div slot-scope="{ row, $index }" class="action-cell">
                <el-button type="primary" round size="mini" @click="handleNetworkWayEdit(row, $index)">编辑</el-button>
                <ConfirmPopover :index="$index" @confirm="handleNetworkWayDelete(row, $index)"></ConfirmPopover>
              </div>
            </el-table-column>
          </el-table>
          <el-button type="primary" size="mini" @click="addNetworkWay()">添 加</el-button>
        </el-form-item>

        <el-form-item label="传感功能">
          <el-checkbox-group v-model="form.sensorType">
            <el-checkbox v-for="item in sensorTypeList" :key="item.value" :label="item.value">
              {{ item.label }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item label="网关类型">
          <el-checkbox-group v-model="form.gateway">
            <el-checkbox v-for="item in gatewayTypes" :key="item.value" :label="item.value">
              {{ item.label }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item label="简介">
          <el-input v-model="form.remark" placeholder="请输入产品简介"></el-input>
        </el-form-item>

        <el-form-item label="配网视频教程">
          <UploadVideo :url.sync="form.videoUrl"></UploadVideo>
        </el-form-item>

        <el-form-item label="创建时间">
          <el-col :span="10">
            <el-input v-model="form.gmtCreate" disabled></el-input>
          </el-col>
          <el-col class="line" :span="4" style="text-align: right">更新时间&nbsp;&nbsp;&nbsp;</el-col>
          <el-col :span="10">
            <el-input v-model="form.gmtModified" disabled></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="设备总量">
          <el-col :span="10">
            <el-input v-model="form.total" disabled></el-input>
          </el-col>
          <el-col class="line" :span="4" style="text-align: right">激活量&nbsp;&nbsp;&nbsp;</el-col>
          <el-col :span="10">
            <el-input v-model="form.usedNum" disabled></el-input>
          </el-col>
          <!-- <el-button type="primary" @click="addDeviceDialog = true" style="margin-top: 10px">添加设备</el-button> -->
        </el-form-item>

        <el-form-item label="说明书" class="editor">
          <Editor v-model="form.specification"></Editor>

          <UploadFile
            :file="specificationFile"
            accept="application/pdf"
            tip="只能上传 .pdf 格式的文件"
            @change="uploadFileChange"
          >
            <el-button type="primary" slot="btn">上传说明书</el-button>
          </UploadFile>
        </el-form-item>

        <el-form-item label="重置介绍">
          <el-input
            v-model="form.guideMsg"
            placeholder="请输入重置介绍"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 10 }"
          ></el-input>
        </el-form-item>

        <el-form-item label="示意图片">
          <UploadImgs
            :list="form.guidePics"
            :accept="'image/jpeg,image/png,image/gif'"
            :limit="3"
            @change="guidePicsChange"
          ></UploadImgs>
        </el-form-item>

        <el-form-item label="生产渠道">
          <el-select v-model="form.checkerIds" placeholder="请选择生产渠道" multiple>
            <el-option
              v-for="item in channelList"
              :key="item.userId"
              :label="item.username"
              :value="item.userId"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <div class="btn-box">
        <el-button @click="handleCancel()">取 消</el-button>
        <el-button type="primary" @click="handleSave()">保 存</el-button>
      </div>
    </el-card>

    <!-- 添加设备弹框 -->
    <el-dialog title="添加设备" :visible.sync="addDeviceDialog" width="30%" @closed="clearContents()">
      <el-form ref="addDeviceForm" :model="addDeviceForm" :rules="addDeviceRules">
        <el-form-item prop="number">
          <el-input v-model="addDeviceForm.number" placeholder="请填写需要添加的设备数量"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="addDeviceDialog = false">取 消</el-button>
        <el-button type="primary" @click="addDeviceConfirm()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 编辑配网方式弹框 -->
    <el-dialog title="编辑配网方式" :visible.sync="networkWayDialog" width="600px" @closed="clearContents()">
      <el-form ref="networkWayForm" :model="networkWayForm" :rules="networkWayRules" label-width="auto">
        <el-form-item label="名称" prop="type">
          <el-select v-model="networkWayForm.type" placeholder="请选择配网方式">
            <el-option
              v-for="item in networkWays"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="闪烁方式" prop="frequency">
          <el-input v-model="networkWayForm.frequency" placeholder="请输入闪烁方式"></el-input>
        </el-form-item>

        <el-form-item label="闪烁频率" prop="flashWay">
          <el-input v-model="networkWayForm.flashWay" placeholder="请输入闪烁频率"></el-input>
        </el-form-item>

        <el-form-item label="示例图" prop="pic">
          <UploadImg :imgUrl.sync="networkWayForm.pic" :accept="'image/jpeg,image/png,image/gif'"></UploadImg>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="networkWayDialog = false">取 消</el-button>
        <el-button type="primary" @click="handleNetworkWaySave()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { linkWays, networkWays, gatewayTypes } from '@/utils/data'

export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      id: null,

      form: {
        baseName: '',
        basePic: null,
        agentName: null,
        linkWay: null,
        sensorType: [],
        gateway: [],
        networkWays: [],
        checkerIds: null,
        gmtCreate: null,
        gmtModified: null,
        total: null,
        usedNum: null,
        specification: null,
        specName: null,
        specUrl: null,
        guideMsg: '',
        guidePics: []
      },
      specificationFile: null,
      addDeviceForm: {
        number: null
      },
      sensorTypeList: [],
      channelList: [],
      firstCategoryList: [],
      secondCategoryList: [],

      linkWays,
      networkWays,
      gatewayTypes,
      rules: {
        linkWay: [{ required: true, message: '请选择连接方式', trigger: 'blur' }],
        networkWays: [{ required: true, message: '请设置配网方式', trigger: 'blur' }]
      },
      addDeviceRules: {
        number: [{ required: true, message: '请填写需要添加的设备数量', trigger: 'blur' }]
      },

      addDeviceDialog: false,

      networkWayDialog: false,
      networkWayForm: {},
      defaultNetworkWayForm: {
        name: '',
        frequency: '',
        flashWay: '',
        pic: ''
      },
      networkWayRules: {
        type: [{ required: true, message: '请选择配网方式', trigger: 'blur' }],
        frequency: [{ required: true, message: '请输入闪烁方式', trigger: 'blur' }],
        flashWay: [{ required: true, message: '请输入闪烁频率', trigger: 'blur' }],
        pic: [{ required: true, message: '请上传示例图', trigger: 'blur' }]
      },
      networkWayEditNum: -1
    }
  },

  computed: {},

  watch: {},

  created() {
    this.id = this.$route.query.id
    this.getDetail()
    this.getSensorTypeList()
    this.getChannelList()
    this.getFirstCategoryList()
  },

  methods: {
    // 获取产品详情
    async getDetail() {
      const res = await this.$api.product.getProductDetail(this.id)

      if (res.code === '00000') {
        if (res.data.linkWay) {
          res.data.linkWay = res.data.linkWay.split(',')
        }

        if (res.data.sensorType) {
          res.data.sensorType = res.data.sensorType.split(',')
        } else {
          res.data.sensorType = []
        }

        if (res.data.gateway) {
          if ([1, 2].includes(res.data.gateway)) {
            res.data.gateway = [res.data.gateway]
          } else if (res.data.gateway === 3) {
            res.data.gateway = [1, 2]
          } else {
            res.data.gateway = []
          }
          // res.data.gateway = res.data.gateway.split(',')
        } else {
          res.data.gateway = []
        }

        if (res.data.checkerIds) {
          res.data.checkerIds = res.data.checkerIds.substr(1, res.data.checkerIds.length - 2)
          res.data.checkerIds = res.data.checkerIds.split(',')
        }

        if (res.data.specName && res.data.specUrl) {
          this.specificationFile = { name: res.data.specName, url: res.data.specUrl }
        }

        if (res.data.guidePics) {
          res.data.guidePics = res.data.guidePics.split(';;')
          const arr = []
          res.data.guidePics.forEach((item, index) => {
            arr.push({ url: item })
          })
          res.data.guidePics = arr
        } else {
          res.data.guidePics = []
        }

        this.form = res.data
        this.getSecondCategoryList(this.form.firstCategoryId)
      }
    },

    // 获取传感器类型列表
    async getSensorTypeList() {
      const res = await this.$api.automation.getSensorTypeList()

      if (res.code === '00000') {
        for (const i in res.data) {
          const obj = {}
          obj.value = i
          obj.label = res.data[i]
          this.sensorTypeList.push(obj)
        }
      }
    },

    // 获取渠道商列表
    async getChannelList() {
      const res = await this.$api.channel.getList({ pageNum: 1, pageSize: 999 })

      if (res.code === '00000') {
        this.channelList = res.data
      }
    },

    // 获取一级分类列表
    async getFirstCategoryList() {
      const res = await this.$api.subProduct.getCategoryList({ levels: 1, pageNum: 1, pageSize: 999 })

      if (res.code === '00000') {
        this.firstCategoryList = res.data
      }
    },

    // 获取二级分类列表
    async getSecondCategoryList(id) {
      const res = await this.$api.subProduct.getCategoryList({ levels: 2, categoryId: id, pageNum: 1, pageSize: 999 })

      if (res.code === '00000') {
        this.secondCategoryList = res.data
      }
    },

    // 一级分类变化时触发
    onChangeFirstCategory(value) {
      this.form.categoryId = null
      this.secondCategoryList = []
      this.getSecondCategoryList(value)
    },

    // 确认添加设备
    addDeviceConfirm() {},

    // 关闭弹窗时清除表单
    clearContents() {
      this.$utils.resetForm(this, 'addDeviceForm')
      this.networkWayForm = JSON.parse(JSON.stringify(this.defaultNetworkWayForm))
    },

    // 添加配网方式
    addNetworkWay() {
      this.isCreateNetworkWay = true
      this.networkWayEditNum = -1
      this.networkWayDialog = true
    },

    // 编辑配网方式
    handleNetworkWayEdit(row, index) {
      this.isCreateNetworkWay = false
      this.networkWayEditNum = index
      this.networkWayForm = { ...row }
      this.networkWayDialog = true
    },

    // 删除配网方式
    handleNetworkWayDelete(row, index) {
      this.form.networkWays.splice(index, 1)
    },

    // 保存配网方式编辑
    handleNetworkWaySave() {
      this.$refs.networkWayForm.validate(async (valid) => {
        if (!valid) return

        if (this.isCreateNetworkWay) {
          this.form.networkWays.push(JSON.parse(JSON.stringify(this.networkWayForm)))
        } else {
          this.$set(this.form.networkWays, this.networkWayEditNum, JSON.parse(JSON.stringify(this.networkWayForm)))
        }
        this.networkWayDialog = false
      })
    },

    // 文件改变时触发
    uploadFileChange(file) {
      this.specificationFile = file

      if (file) {
        this.form.specName = file.name
        this.form.specUrl = file.url
      } else {
        this.form.specName = null
        this.form.specUrl = null
      }
    },

    // 示意图片改变时触发
    guidePicsChange(fileList) {
      this.form.guidePics = fileList
    },

    // 取消编辑，返回上一级
    handleCancel() {
      this.$router.back()
    },

    // 保存
    async handleSave() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return

        const form = JSON.parse(JSON.stringify(this.form))
        form.linkWay = form.linkWay.join(',')
        form.sensorType = form.sensorType.join(',')
        form.gateway = form.gateway.join(',')
        if (['1', '2'].includes(form.gateway)) {
        } else if (form.gateway === ['1', '2']) {
          form.gateway = 3
        }
        if (form.checkerIds) form.checkerIds = ',' + form.checkerIds.join(',') + ','

        const arr = []
        form.guidePics.forEach((item, index) => {
          arr.push(item.url)
        })
        form.guidePics = [...arr]
        form.guidePics = form.guidePics.join(';;')

        const res = await this.$api.product.editProduct({ id: this.id, ...form })

        if (res.code === '00000') {
          this.$message.success('修改成功！')
          this.$router.back()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  .el-card {
    .el-form {
      .el-form-item {
        width: 600px;
        .el-select {
          width: 100%;
        }
        .product-img {
          width: 100px;
          height: 100px;
        }
      }
      .editor {
        width: 600px;
        .upload {
          width: 500px;
          margin-top: 10px;
        }
      }
    }

    .btn-box {
      display: flex;
      justify-content: space-around;
      width: 600px;
      margin: 40px 0 20px;
    }
  }
}
</style>
