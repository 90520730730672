<template>
  <div class="page">
    <el-card>
      <!-- 筛选、操作区域 -->
      <OperationBar>
        <template slot="search-box">
          <el-form :model="queryForm" :inline="true">
            <el-form-item label="关键词">
              <el-input
                v-model="queryForm.search"
                placeholder="请输入关键词"
                clearable
                @keyup.enter.native="handleSearch()"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-date-picker
                v-model="queryDate"
                type="daterange"
                range-separator="至"
                value-format="yyyy-MM-dd"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="dateChange"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="medium" @click="handleSearch()">查询</el-button>
            </el-form-item>
          </el-form>
        </template>
      </OperationBar>

      <!-- 表格 -->
      <el-table :data="list" v-loading="loading" border>
        <el-table-column label="序号" type="index" width="50" header-align="center"></el-table-column>

        <el-table-column label="用户头像">
          <template slot-scope="{ row }">
            <el-image :src="row.avatarUrl"></el-image>
          </template>
        </el-table-column>

        <el-table-column label="绑定手机" prop="mobile"></el-table-column>
        <el-table-column label="昵称" prop="nickName"></el-table-column>
        <el-table-column label="注册时间" prop="gmtCreate" width="100"></el-table-column>

        <el-table-column label="操作" width="300">
          <div slot-scope="{ row, $index }" class="action-cell">
            <el-button type="primary" round size="mini" @click="handleDetail(row)">查看详情</el-button>
            <el-button type="primary" round size="mini" @click="handleDevice(row)">查看设备</el-button>

            <ConfirmPopover
              v-if="row.status == 1"
              :index="$index"
              text="禁用"
              @confirm="handleBan(row)"
            ></ConfirmPopover>

            <el-button v-else type="primary" round size="mini" @click="handleBan(row)">启用</el-button>
          </div>
        </el-table-column>
      </el-table>

      <!-- 分页器 -->
      <PaginationBar
        :pageNo.sync="pagination.pageNum"
        :pageSize.sync="pagination.pageSize"
        :total.sync="pagination.total"
        @change="getList()"
      ></PaginationBar>
    </el-card>

    <el-dialog title="用户信息" :visible.sync="detailDialog" width="400px" :before-close="clearContents()">
      <el-form ref="detailForm" :model="detail" v-loading="detailLoading" label-width="auto">
        <el-form-item label="用户头像">
          <el-image :src="detail.avatarUrl" style="width: 100px"></el-image>
        </el-form-item>
        <el-form-item label="绑定手机号">
          <el-input v-model="detail.mobile" disabled></el-input>
        </el-form-item>
        <el-form-item label="昵称">
          <el-input v-model="detail.nickName" disabled></el-input>
        </el-form-item>
        <el-form-item label="注册时间">
          <el-input v-model="detail.gmtCreate" disabled></el-input>
        </el-form-item>
        <el-form-item label="设备数量">
          <el-input v-model="detail.deviceNum" disabled></el-input>
        </el-form-item>
        <el-form-item label="在线数量">
          <el-input v-model="detail.onlineNum" disabled></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="primary" @click="detailDialog = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      query: {},
      queryForm: {
        search: '',
        startDate: '',
        endDate: ''
      },
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      queryDate: '',
      list: [],
      loading: false,
      detailDialog: false,
      detail: {},
      detailLoading: false
    }
  },

  computed: {},

  watch: {},

  created() {
    this.getList()
  },

  methods: {
    // 获取用户列表
    async getList() {
      this.loading = true
      const res = await this.$api.user.getList({ ...this.query, ...this.pagination })
      this.loading = false

      if (res.code === '00000') {
        this.pagination.total = res.total
        this.list = res.data
      }
    },

    // 搜索事件
    handleSearch() {
      this.query = { ...this.queryForm }
      this.pagination.pageNum = 1
      this.getList()
    },

    // 日期改变
    dateChange(e) {
      if (e) {
        this.queryForm.startDate = e[0]
        this.queryForm.endDate = e[1]
      } else {
        this.queryForm.startDate = ''
        this.queryForm.endDate = ''
      }

      this.handleSearch()
    },

    // 查看详情
    async handleDetail(row) {
      this.detailDialog = true
      this.detailLoading = true
      const res = await this.$api.user.getDetail(row.id)
      this.detailLoading = false
      this.detail = res.data
    },

    // 关闭弹窗时清除表单
    clearContents() {
      this.$utils.resetForm(this, 'detailForm')
    },

    // 查看设备
    handleDevice(row) {
      this.$router.push({ path: '/device/list', query: { search: row.mobile } })
    },

    // 禁用用户
    async handleBan(row) {
      const res = await this.$api.user.banUser(row.id)

      if (res.code === '00000') {
        this.$message.success('操作成功！')
        this.getList()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  .el-card {
    .el-table {
      .el-image {
        width: 75px;
        height: 75px;
      }
    }
  }
}
</style>
