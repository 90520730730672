<template>
  <div class="page">
    <el-card>
      <div slot="header">
        <span>固件更新</span>
      </div>

      <!-- 表单区域 -->
      <el-form ref="form" :model="form" :rules="rules" label-width="auto">
        <el-form-item label="产品名称">
          <el-input v-model="proDetail.proName" disabled></el-input>
        </el-form-item>

        <el-form-item label="版本号" prop="version">
          <el-input v-model="form.version" placeholder="请输入版本号"></el-input>
        </el-form-item>

        <el-form-item label="固件" prop="firmware" class="upload">
          <UploadFile :file="firmwareFile" :firmware="true" @change="uploadFileChange">
            <el-button type="primary" slot="btn">上传固件</el-button>
          </UploadFile>
        </el-form-item>

        <el-form-item label="说明">
          <el-input
            v-model="form.remark"
            placeholder="请输入固件说明"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 10 }"
          ></el-input>
        </el-form-item>

        <el-form-item label="待升级设备">
          <el-input
            v-model="form.serialNumbers"
            placeholder="请输入设备号，多个设备号之间使用;分隔"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 10 }"
          ></el-input>
        </el-form-item>

        <el-form-item label="待更新版本" prop="updatedVersion">
          <el-select v-model="form.updatedVersion" placeholder="请选择待更新版本" multiple>
            <el-option
              v-for="item in this.versionHis"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="强制更新">
          <el-switch v-model="form.isForce" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>

        <el-form-item label="校验码">
          <el-input v-model="form.checkCode" disabled></el-input>
        </el-form-item>
      </el-form>

      <div class="btn-box">
        <el-button @click="handleCancel()">取 消</el-button>
        <el-button type="primary" @click="handleSave()">更 新</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      id: null,
      proDetail: {},
      form: {
        version: '',
        firmware: '',
        remark: '',
        serialNumbers: '',
        versionHis: null,
        updatedVersion: null,
        checkCode: ''
      },
      rules: {
        version: [{ required: true, message: '请输入版本号', trigger: 'blur' }],
        firmware: [{ required: true, message: '请上传固件', trigger: 'blur' }],
        updatedVersion: [{ required: true, message: '请选择待更新版本', trigger: 'blur' }]
      },
      firmwareFile: null,
      versionHis: null
    }
  },

  filters: {},
  // computed: { ...mapState('detail', { proDetail: (state) => state.detail }) },
  watch: {},

  created() {
    this.id = this.$route.query.id

    this.getProDetail()
  },

  methods: {
    // 获取产品详情
    async getProDetail() {
      const res = await this.$api.product.getProductDetail(this.id)

      if (res.code === '00000') {
        this.proDetail = res.data
        this.getVerInfo()
      }
    },

    // 获取产品详情
    async getVerInfo() {
      const res = await this.$api.firmware.getVersionInfo(this.proDetail.proNo)

      if (res.code === '00000') {
        this.setDate(res.data)
      }
    },

    // 设置数据
    setDate(data) {
      data = data || {}

      if (data.firmware) {
        let name = data.firmware.split('/')
        name = name[name.length - 1]
        this.firmwareFile = { name, url: data.firmware, checkCode: data.checkCode }
      }

      const defaultDate = { value: 'ALL', label: '全部' }
      let versionHis = []
      if (data.versionHis) {
        versionHis = data.versionHis.split(',')
        for (let i = 0, len = versionHis.length; i < len; i++) {
          versionHis[i] = { value: versionHis[i], label: versionHis[i] }
        }
        versionHis.pop()
        versionHis.reverse()
      }
      this.versionHis = [defaultDate, ...versionHis]

      if (data.updatedVersion) {
        data.updatedVersion = data.updatedVersion.split(',')
      }

      this.form = data
    },

    // 文件改变时触发
    uploadFileChange(file) {
      this.firmwareFile = file

      if (file) {
        this.form.firmware = file.url
        this.form.checkCode = file.checkCode
      } else {
        this.form.firmware = null
      }
    },

    // 取消编辑，返回上一级
    handleCancel() {
      this.$router.back()
    },

    // 保存
    async handleSave() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return

        const form = { ...this.form }
        form.updatedVersion = form.updatedVersion ? form.updatedVersion.toString() : null

        const res = await this.$api.firmware.updateVersion({ proNo: this.proDetail.proNo, ...form })

        if (res.code === '00000') {
          this.$message.success('更新成功！')
          this.$router.back()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  .el-card {
    .el-form {
      .el-form-item {
        width: 600px;
        .el-select {
          width: 100%;
        }
      }
      .upload {
        width: 500px;
      }
    }

    .btn-box {
      display: flex;
      justify-content: space-around;
      width: 600px;
      margin: 40px 0 20px;
    }
  }
}
</style>
