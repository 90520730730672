<template>
  <div class="page">
    <div style="margin-bottom: 20px">
      <el-button @click="handleCancel()">返 回</el-button>
    </div>

    <el-row :gutter="20">
      <el-col :span="10" :xs="24">
        <el-card class="menu-card">
          <div slot="header">
            <span>菜单分配</span>
          </div>

          <el-tree
            ref="menuTree"
            :data="menuList"
            :props="props"
            node-key="id"
            v-loading="loading"
            show-checkbox
            check-strictly
            default-expand-all
            highlight-current
            @node-click="handleNodeClick"
          ></el-tree>

          <div class="btn-box">
            <el-button type="primary" @click="handleSave()">保 存</el-button>
          </div>
        </el-card>
      </el-col>

      <el-col :span="14" :xs="24">
        <el-card class="menu-card">
          <div slot="header">
            <span>权限分配</span>
          </div>

          <el-checkbox-group v-model="checkedPermList" v-loading="permLoading">
            <el-checkbox v-for="item in menuPermList" :key="item.id" :label="item.id" border>
              {{ item.name }}
            </el-checkbox>
          </el-checkbox-group>

          <div class="btn-box" v-if="menuPermList.length > 0">
            <el-button type="primary" @click="handlePermSave()">保 存</el-button>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      id: null,
      menuList: [],
      menuIds: [],
      props: {
        children: 'children',
        label: 'name'
      },
      loading: false,

      menuId: null,
      permLoading: false,
      menuPermList: [],
      checkedPermList: []
    }
  },

  watch: {},

  created() {
    this.init()
    this.getMenuList()
    this.getRoleMenu()
  },

  methods: {
    init() {
      this.id = this.$route.query.id
    },

    // 获取菜单列表
    async getMenuList() {
      this.loading = true
      const res = await this.$api.menu.getList()
      this.loading = false

      if (res.code === '00000') {
        this.menuList = res.data
      }
    },

    // 获取角色的菜单列表
    async getRoleMenu() {
      this.loading = true
      const res = await this.$api.role.getRoleMenu(this.id)
      this.loading = false

      if (res.code === '00000') {
        this.$refs.menuTree.setCheckedKeys(res.data)
      }
    },

    // 节点点击事件
    handleNodeClick(node) {
      this.menuId = node.id
      this.getMenuPerm(node.id)
      this.getRoleMenuPerm(node.id)
    },

    // 获取菜单权限列表
    async getMenuPerm(id) {
      this.permLoading = true
      const res = await this.$api.permission.getList({ menuId: id })
      this.permLoading = false

      if (res.code === '00000') {
        this.menuPermList = res.data
      }
    },

    // 获取角色菜单权限列表
    async getRoleMenuPerm(menuId) {
      const data = {
        roleId: this.id,
        menuId
      }

      this.permLoading = true
      const res = await this.$api.role.getRoleMenuPerm(data)
      this.permLoading = false

      if (res.code === '00000') {
        this.checkedPermList = res.data
      }
    },

    // 取消编辑，返回上一级
    handleCancel() {
      this.$router.back()
    },

    // 保存
    async handleSave() {
      const checkedKeys = this.$refs.menuTree.getCheckedKeys()
      // const halfCheckedKeys = this.$refs.menuTree.getHalfCheckedKeys()
      // const keys = [...checkedKeys, ...halfCheckedKeys]

      const data = { id: this.id, menuIds: checkedKeys }

      const res = await this.$api.role.updateRoleMenu(data)
      if (res.code === '00000') {
        this.$message.success('修改成功！')
        this.$router.back()
      }
    },

    // 保存
    async handlePermSave() {
      const data = {
        roleId: this.id,
        menuId: this.menuId,
        permissionIds: this.checkedPermList
      }

      const res = await this.$api.role.updateRoleMenuPerm(data)
      if (res.code === '00000') {
        this.$message.success('修改成功！')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  .el-card {
    .btn-box {
      display: flex;
      justify-content: space-around;
      width: 100%;
      margin: 40px 0 20px;
    }
  }

  .menu-card {
    .el-checkbox-group {
      .el-checkbox {
        margin-left: 0;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
