var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('el-card',[_c('OperationBar',[_c('template',{slot:"search-box"},[_c('el-form',{attrs:{"model":_vm.queryForm,"inline":true}},[_c('el-form-item',{attrs:{"label":"关键词"}},[_c('el-input',{attrs:{"placeholder":"请输入关键词","clearable":""},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSearch()}},model:{value:(_vm.queryForm.search),callback:function ($$v) {_vm.$set(_vm.queryForm, "search", $$v)},expression:"queryForm.search"}})],1),_c('el-form-item',{attrs:{"label":"通讯方式"}},[_c('el-select',{attrs:{"placeholder":"请选择通讯方式","clearable":""},on:{"change":function($event){return _vm.handleSearch()}},model:{value:(_vm.queryForm.linkWay),callback:function ($$v) {_vm.$set(_vm.queryForm, "linkWay", $$v)},expression:"queryForm.linkWay"}},_vm._l((_vm.linkWays),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary","size":"medium"},on:{"click":function($event){return _vm.handleSearch()}}},[_vm._v("查询")])],1)],1)],1),_c('template',{slot:"btn-box"},[_c('el-button',{attrs:{"type":"primary","size":"medium","round":""},on:{"click":function($event){return _vm.jumpDetailPage()}}},[_vm._v("新建产品")])],1)],2),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"productListTable",attrs:{"data":_vm.list,"border":""}},[_c('el-table-column',{attrs:{"label":"序号","type":"index","width":"50"}}),_c('el-table-column',{attrs:{"label":"产品信息","width":"400"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"product-info"},[_c('div',{staticClass:"left"},[_c('el-image',{attrs:{"src":row.pic}})],1),_c('div',{staticClass:"right"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(row.name))])])])}}])}),_c('el-table-column',{attrs:{"label":"产品ID","prop":"id"}}),_c('el-table-column',{attrs:{"label":"通讯方式"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _vm._l((row.linkWay),function(item,index){return _c('span',{key:item},[_vm._v(" "+_vm._s(_vm._f("linkWayFilter")(item))+_vm._s(index + 1 == row.linkWay.length ? '' : '、')+" ")])})}}])}),_c('el-table-column',{attrs:{"label":"创建时间","prop":"gmtCreate","width":"100"}}),_c('el-table-column',{attrs:{"label":"操作","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return _c('div',{staticClass:"action-cell"},[_c('el-button',{attrs:{"type":"primary","round":"","size":"mini"},on:{"click":function($event){return _vm.handleDetail(row)}}},[_vm._v("编辑")]),_c('ConfirmPopover',{attrs:{"index":$index},on:{"confirm":function($event){return _vm.handleDelete(row)}}})],1)}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }