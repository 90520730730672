<template>
  <div class="view">
    <el-card>
      <div slot="header" class="clearfix">
        <span>录入{{ detail.name }}</span>
      </div>

      <div class="moduleList">
        <MainProduct :title="detail.product.proName" @change="onMainChange"></MainProduct>

        <div class="module" v-for="(item, index) in detail.remotes" :key="index">
          <!-- 开关 -->
          <SwitchPanel
            v-if="item.id == 38"
            :ref="'subDevice_' + index"
            :title="item.remoteName"
            :serialNumber="serialNumber"
            @change="(data) => onSubChange('38', index, data)"
            @resultChange="(result) => onResultChange('38', index, result)"
          ></SwitchPanel>

          <!-- 智能插座 -->
          <SmartSocket
            v-if="item.id == 39"
            :ref="'subDevice_' + index"
            :title="item.remoteName"
            :serialNumber="serialNumber"
            @change="(data) => onSubChange('39', index, data)"
            @resultChange="(result) => onResultChange('39', index, result)"
          ></SmartSocket>

          <!-- 门磁传感器 -->
          <DoorSensor
            v-if="item.id == 41"
            :ref="'subDevice_' + index"
            :title="item.remoteName"
            :serialNumber="serialNumber"
            @change="(data) => onSubChange('41', index, data)"
            @resultChange="(result) => onResultChange('41', index, result)"
          ></DoorSensor>

          <!-- 人体传感器 -->
          <BodySensor
            v-if="item.id == 42"
            :ref="'subDevice_' + index"
            :title="item.remoteName"
            :serialNumber="serialNumber"
            @change="(data) => onSubChange('42', index, data)"
            @resultChange="(result) => onResultChange('42', index, result)"
          ></BodySensor>
        </div>
      </div>

      <div class="footer">
        <el-button size="medium" @click="returnList()">返 回</el-button>
        <el-button type="primary" size="medium" :disabled="!testResult" @click="putStorage()">入 库</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mqtt from 'mqtt'
import CryptoJS from 'crypto-js'
import MainProduct from './components/MainProduct'
import SwitchPanel from './components/SwitchPanel'
import SmartSocket from './components/SmartSocket'
import DoorSensor from './components/DoorSensor'
import BodySensor from './components/BodySensor'

export default {
  name: '',
  components: { MainProduct, SwitchPanel, SmartSocket, DoorSensor, BodySensor },

  props: {},

  data() {
    return {
      id: null,
      topic: '',
      client: null,
      detail: {
        product: {},
        remotes: []
      },
      serialNumber: '',
      subList: [],
      testResult: false
    }
  },

  filters: {},
  computed: { ...mapState('user', ['userInfo']) },
  watch: {},

  created() {
    this.init()
    this.registerMqtt()
    this.getDetail()
  },

  methods: {
    init() {
      this.id = this.$route.query.id
      this.topic = process.env.VUE_APP_MQTT_BASE_TOPIC + this.userInfo.id
    },

    // 注册 mqtt
    async registerMqtt() {
      const res = await this.$api.other.getMqttOptions()

      if (res.code === '00000') {
        const credential = res.data.deviceCredential
        const instanceId = credential.instanceId
        const clientId = credential.clientId
        const accessKey = credential.deviceAccessKeyId
        const secretKey = credential.deviceAccessKeySecret

        this.client = mqtt.connect('wss://' + instanceId + '.mqtt.aliyuncs.com:443', {
          clientId: clientId,
          username: 'DeviceCredential|' + accessKey + '|' + instanceId,
          password: CryptoJS.HmacSHA1(clientId, secretKey).toString(CryptoJS.enc.Base64)
        })

        this.setMqttEvents()
      }
    },

    // 设置 mqtt 事件
    setMqttEvents() {
      this.client.on('connect', (e) => {
        console.log('mqtt连接成功')
        this.client.subscribe(this.topic, (e) => {
          console.log('topic订阅成功: ' + this.topic)
        })
      })

      // 接收消息
      this.client.on('message', (topic, message) => {
        message = JSON.parse(message.toString())
        console.log(topic, message)

        this.subList.forEach((item, index) => {
          if (item.controlId === message.msg.controlId) {
            this.$refs[`subDevice_${index}`][0].receiveMessage(message.msg)
          }
        })
      })
    },

    // 获取详情
    async getDetail() {
      const res = await this.$api.packageApi.getDetail(this.id)

      if (res.code === '00000') {
        this.subList.length = res.data.remotes.length
        this.detail = res.data
      }
    },

    // 主设备改变时触发
    onMainChange(value) {
      this.serialNumber = value
      this.detail.remotes.forEach((item, index) => {
        this.$refs[`subDevice_${index}`][0].$refs.productFrame.onMainChange()
      })
    },

    // 子设备改变时触发
    onSubChange(type, index, data) {
      data.id = type
      this.subList[index] = data
    },

    // 测试结果改变时触发
    onResultChange(type, index, result) {
      this.subList[index].result = result

      const resultList = []
      let flag = false

      this.subList.forEach((item) => {
        if (item) {
          resultList.push(item.result)
        } else {
          resultList.push(false)
        }
      })

      if (resultList.length < this.subList.length) {
        flag = false
      } else {
        flag = resultList.every((item) => {
          return item === true
        })
      }

      if (flag) {
        this.testResult = true
      } else {
        this.testResult = false
      }
    },

    // 返回
    returnList() {
      this.$router.back()
    },

    // 入库
    async putStorage() {
      const query = {
        packageId: this.id,
        serialNumber: this.serialNumber,
        subAddrList: []
      }

      this.subList.forEach((item, index) => {
        query.subAddrList.push(item.controlId)
      })

      const res = await this.$api.packageApi.enteringPackage(query)

      if (res.code === '00000') {
        this.$message.success('操作成功！')
        this.$router.back()
      }
    }
  },

  destroyed() {
    this.client.end()
  }
}
</script>

<style lang="scss" scoped>
.view {
  .el-card {
    .moduleList {
      .module {
        & > div {
          margin-top: 40px;
        }
      }
    }

    .footer {
      display: flex;
      justify-content: space-around;
      width: 840px;
      margin-top: 40px;
    }
  }
}
</style>
