<template>
  <div class="view">
    <!-- 主体区域 -->
    <el-card>
      <!-- 筛选、操作区域 -->
      <OperationBar>
        <template slot="search-box">
          <el-form :model="queryForm" :inline="true">
            <el-form-item label="关键词">
              <el-input
                v-model="queryForm.search"
                placeholder="请输入关键词"
                clearable
                @keyup.enter.native="handleSearch()"
              ></el-input>
            </el-form-item>
            <el-form-item label="上下架状态">
              <el-select v-model="queryForm.status" placeholder="请选择上下架状态" clearable @change="handleSearch()">
                <el-option label="上架" :value="1"></el-option>
                <el-option label="下架" :value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="类型">
              <el-select v-model="queryForm.type" placeholder="请选择类型" clearable @change="handleSearch()">
                <el-option label="生活服务" :value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="medium" @click="handleSearch()">查询</el-button>
            </el-form-item>
          </el-form>
        </template>

        <template slot="btn-box">
          <el-button type="primary" size="medium" round @click="handleCreate()">新建</el-button>
        </template>
      </OperationBar>

      <!-- 表格 -->
      <el-table ref="productListTable" :data="list" v-loading="loading" border>
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column label="图标" width="120">
          <template slot-scope="{ row }">
            <el-image class="col-icon" :src="row.icon"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="名称" prop="name"></el-table-column>
        <el-table-column label="链接" prop="link"></el-table-column>
        <el-table-column label="排序" prop="sort" width="100"></el-table-column>
        <!-- <el-table-column label="创建时间" prop="gmtCreate" width="100"></el-table-column> -->

        <el-table-column label="操作" width="200">
          <div slot-scope="{ row, $index }" class="action-cell">
            <el-button type="primary" round size="mini" @click="handleEdit(row)">编辑</el-button>
            <ConfirmPopover :index="$index" @confirm="handleDelete(row)"></ConfirmPopover>
          </div>
        </el-table-column>
      </el-table>

      <!-- 分页器 -->
      <PaginationBar
        :pageNo.sync="pagination.pageNum"
        :pageSize.sync="pagination.pageSize"
        :total="pagination.total"
        @change="getList()"
      ></PaginationBar>
    </el-card>

    <!-- 编辑弹框 -->
    <el-dialog title="编辑" :visible.sync="editDialog" width="600px" @closed="clearContents()">
      <el-form ref="editForm" :model="editForm" :rules="editRules" label-width="auto">
        <el-form-item label="图标" prop="icon">
          <UploadImg :imgUrl.sync="editForm.icon"></UploadImg>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="editForm.name" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="链接" prop="link">
          <el-input v-model="editForm.link" placeholder="请输入链接"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-switch v-model="editForm.status" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input-number v-model="editForm.sort" :min="1" :step="1" step-strictly></el-input-number>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="editDialog = false">取 消</el-button>
        <el-button type="primary" @click="handleSave()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      query: {},
      queryForm: {
        search: '',
        status: null,
        proId: ''
      },
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      loading: false,
      list: [],

      editDialog: false,
      editForm: {},
      editRules: {
        icon: [{ required: true, message: '请上传图标', trigger: 'blur' }],
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        link: [{ required: false, message: '请输入链接', trigger: 'blur' }],
        sort: [{ required: true, message: '请选择排序', trigger: 'blur' }]
      }
    }
  },

  filters: {},
  computed: {},
  watch: {},

  created() {
    this.getList()
  },

  methods: {
    // 获取列表
    async getList() {
      this.loading = true
      const res = await this.$api.app.getLifeServiceList({ ...this.query, ...this.pagination })
      this.loading = false
      if (res.code === '00000') {
        this.pagination.total = res.total
        this.list = res.data
      }
    },

    // 搜索事件
    handleSearch() {
      this.query = { ...this.queryForm }
      this.pagination.pageNum = 1
      this.getList()
    },

    // 新建
    handleCreate() {
      this.editForm = {
        status: 1
      }
      this.editDialog = true
    },

    // 编辑
    handleEdit(row) {
      this.editForm = { ...row }
      this.editDialog = true
    },

    // 关闭弹窗时清除表单
    clearContents() {
      this.editForm = {}
    },

    // 删除
    async handleDelete(row) {
      const res = await this.$api.app.deleteLifeService(row.id)

      if (res.code === '00000') {
        this.$message.success('删除成功！')
        this.getList()
      }
    },

    // 编辑
    handleSave(row) {
      this.$refs.editForm.validate(async (valid) => {
        if (!valid) return

        if (this.editForm.id) {
          const res = await this.$api.app.editLifeService(this.editForm)

          if (res.code === '00000') {
            this.$message.success('修改成功！')
            this.editDialog = false
            this.getList()
          }
        } else {
          const res = await this.$api.app.createLifeService(this.editForm)

          if (res.code === '00000') {
            this.$message.success('创建成功！')
            this.editDialog = false
            this.getList()
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.view {
  .el-card {
    .el-table {
      .col-icon {
        width: 75px;
        height: 75px;
      }
    }
  }
}
</style>
