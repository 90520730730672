<template>
  <div class="page">
    <el-card>
      <div class="title">注册</div>

      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item prop="company">
          <el-input v-model="form.company" placeholder="请输入企业名称"></el-input>
        </el-form-item>

        <el-form-item prop="password">
          <el-input
            v-model.trim="form.password"
            type="password"
            placeholder="请输入 6 - 16 位密码，区分大小写"
          ></el-input>
        </el-form-item>

        <el-form-item prop="confirmPassword">
          <el-input v-model.trim="form.confirmPassword" type="password" placeholder="请确认密码"></el-input>
        </el-form-item>

        <el-form-item prop="mobile">
          <el-input v-model.trim="form.mobile" placeholder="请输入手机号"></el-input>
        </el-form-item>

        <el-form-item prop="code">
          <el-input v-model.trim="form.code" placeholder="请输入验证码">
            <SmsCodeBtn slot="append" :mobile="this.form.mobile"></SmsCodeBtn>
          </el-input>
        </el-form-item>

        <el-form-item prop="checked" class="checkbox">
          <el-checkbox v-model="form.checked">
            <div class="content">
              阅读并同意
              <el-link type="primary" href="https://baidu.com" target="_blank">《服务条款》</el-link>
              、
              <el-link type="primary" href="https://baidu.com" target="_blank">《隐私声明》</el-link>
            </div>
          </el-checkbox>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" :loading="registerLoading" style="width: 100%" @click="handleRegister()"
            >注 册</el-button
          >
        </el-form-item>
      </el-form>

      <div class="action">
        <el-link type="primary" @click="jumpPage()">登录 ></el-link>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {},

  data() {
    const validateConfirmPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form.password) {
        callback(new Error('两次输入密码不一致'))
      } else {
        callback()
      }
    }
    const validateMobile = (rule, value, callback) => {
      const mobileReg = /^1[3456789]\d{9}$/

      if (mobileReg.test(value)) {
        callback()
      } else {
        callback(new Error('手机号码格式不正确'))
      }
    }
    const validateChecked = (rule, value, callback) => {
      if (value === false) {
        callback(new Error('请阅读并同意'))
      } else {
        callback()
      }
    }

    return {
      form: {
        company: '',
        password: '',
        confirmPassword: '',
        mobile: '',
        code: '',
        checked: false
      },

      rules: {
        company: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 16, message: '密码长度应为 6 - 16 位', trigger: 'blur' },
          { pattern: /^(\w){6,16}$/, message: '只能输入字母、数字或下划线' }
        ],
        confirmPassword: [
          { required: true, message: '请确认密码', trigger: 'blur' },
          { validator: validateConfirmPassword, trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: validateMobile, trigger: 'blur' }
        ],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        checked: [{ validator: validateChecked, trigger: 'change' }]
      },

      registerLoading: false
    }
  },

  computed: {},

  watch: {},

  created() {},

  methods: {
    // 注册
    handleRegister() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return

        this.registerLoading = true
        const res = await this.$api.login.register(this.form)
        this.registerLoading = false

        if (res.code === '00000') {
          this.$message.success('注册成功，请登录!')
          this.$router.push('/login')
        }
      })
    },

    // 跳转登录页
    jumpPage() {
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #2d3a4b;

  .el-card {
    width: 400px;
    // height: 500px;

    .title {
      margin: 40px 0 50px;
      font-size: 30px;
    }

    .el-form {
      .checkbox {
        .content {
          display: flex;
          align-items: center;
        }
      }
    }

    .action {
      // margin-top: 20px;
      text-align: right;
    }
  }
}
</style>
