<template>
  <div class="page">
    <!-- 主体区域 -->
    <el-card>
      <!-- 筛选、操作区域 -->
      <OperationBar>
        <template slot="search-box">
          <el-form :model="queryForm" :inline="true">
            <el-form-item label="关键词">
              <el-input
                v-model="queryForm.search"
                placeholder="请输入关键词"
                clearable
                @keyup.enter.native="handleSearch()"
              ></el-input>
            </el-form-item>
            <el-form-item label="通讯方式">
              <el-select v-model="queryForm.linkWay" placeholder="请选择通讯方式" clearable @change="handleSearch()">
                <el-option
                  v-for="item in linkWays"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="medium" @click="handleSearch()">查询</el-button>
            </el-form-item>
          </el-form>
        </template>

        <template slot="btn-box">
          <el-button type="primary" size="medium" round @click="jumpDetailPage()">新建产品</el-button>
        </template>
      </OperationBar>

      <!-- 表格 -->
      <el-table ref="productListTable" :data="list" v-loading="loading" border>
        <el-table-column label="序号" type="index" width="50"></el-table-column>

        <el-table-column label="产品信息" width="400">
          <div slot-scope="{ row }" class="product-info">
            <div class="left">
              <el-image :src="row.pic"></el-image>
            </div>
            <div class="right">
              <div class="title">{{ row.name }}</div>
            </div>
          </div>
        </el-table-column>

        <el-table-column label="产品ID" prop="id"></el-table-column>

        <el-table-column label="通讯方式">
          <template slot-scope="{ row }">
            <span v-for="(item, index) in row.linkWay" :key="item">
              {{ item | linkWayFilter }}{{ index + 1 == row.linkWay.length ? '' : '、' }}
            </span>
          </template>
        </el-table-column>

        <el-table-column label="创建时间" prop="gmtCreate" width="100"></el-table-column>

        <el-table-column label="操作" width="200">
          <div slot-scope="{ row, $index }" class="action-cell">
            <el-button type="primary" round size="mini" @click="handleDetail(row)">编辑</el-button>
            <ConfirmPopover :index="$index" @confirm="handleDelete(row)"></ConfirmPopover>
          </div>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { linkWays } from '@/utils/data'

export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      query: {},
      queryForm: {
        search: '',
        linkWay: null
      },

      linkWays,
      loading: false,

      list: []
    }
  },

  filters: {},

  computed: {},

  watch: {},

  created() {
    this.getList()
  },

  methods: {
    ...mapMutations('detail', ['setDetail', 'deleteDetail']),

    // 获取产品库列表
    async getList() {
      this.loading = true
      const res = await this.$api.product.getLibraryList(this.query)
      this.loading = false

      if (res.code === '00000') {
        this.list = this.$utils.stringToArray(res.data, 'linkWay')
      }
    },

    // 搜索事件
    handleSearch() {
      this.query = { ...this.queryForm }
      this.getList()
    },

    // 跳转详情页面
    jumpDetailPage(detail) {
      if (detail) {
        this.setDetail(detail)
      } else {
        this.deleteDetail()
      }

      this.$router.push({ path: 'libraryDetail' })
    },

    // 查看详情
    handleDetail(row) {
      this.jumpDetailPage(row)
    },

    // 查看设备
    handleDevice(row) {},

    // 删除
    async handleDelete(row) {
      const res = await this.$api.product.deleteLibraryProduct(row.id)

      if (res.code === '00000') {
        this.$message.success('删除成功！')
        this.getList()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  .el-card {
    .el-table {
      .product-info {
        display: flex;
        align-items: center;

        .left {
          .el-image {
            width: 75px;
            height: 75px;
          }
        }

        .right {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-around;
          height: 80px;
          margin-left: 20px;
          // text-align: left;

          .title {
            color: #4e8cff;
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>
