<template>
  <div class="page">
    <el-card>
      <!-- 筛选、操作区域 -->
      <OperationBar>
        <template slot="btn-box">
          <el-button type="primary" size="medium" round @click="handleCreate()">新建套餐</el-button>
        </template>
      </OperationBar>

      <!-- 表格 -->
      <el-table :data="list" v-loading="loading" border>
        <el-table-column label="序号" type="index" width="50" header-align="center"></el-table-column>

        <el-table-column label="套餐信息">
          <template slot-scope="{ row }">
            <div class="pacInfo-wrapper">
              <div class="title">{{ row.name }}</div>
              <div class="proNum">{{ '产品数：' + (row.remotes.length + 1) }}</div>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="关联产品" width="400">
          <template slot-scope="{ row }">
            <div class="proPic-wrapper">
              <el-image :src="row.product.basePic"></el-image>
              <el-image v-for="(item, index) in row.remotes.slice(0, 3)" :key="index" :src="item.remotePic"></el-image>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="创建时间" prop="gmtCreate" width="100"></el-table-column>
        <el-table-column label="总数量" prop="total" width="100"></el-table-column>
        <el-table-column label="已出库" width="100">
          <template slot-scope="{ row }">
            {{ row.total - row.stock }}
          </template>
        </el-table-column>
        <el-table-column label="库存" prop="stock" width="100"></el-table-column>

        <el-table-column label="操作" width="300">
          <div slot-scope="{ row }" class="action-cell">
            <el-button type="primary" round size="mini" @click="handleDetail(row)">查看详情</el-button>
            <el-button type="primary" round size="mini" @click="handleEntering(row)">录入套餐</el-button>
            <el-button type="primary" round size="mini" @click="handleOutbound(row)">出库</el-button>
          </div>
        </el-table-column>
      </el-table>

      <!-- 分页器 -->
      <PaginationBar
        :pageNo.sync="pagination.pageNum"
        :pageSize.sync="pagination.pageSize"
        :total.sync="pagination.total"
        @change="getList()"
      ></PaginationBar>
    </el-card>

    <!-- 编辑套餐弹框 -->
    <el-dialog
      title="编辑套餐"
      :visible.sync="editDialog"
      custom-class="edit-dialog"
      width="600px"
      @closed="clearContents()"
    >
      <el-form ref="editForm" :model="editForm" :rules="editRules" label-width="auto">
        <el-form-item label="套餐名称" prop="name">
          <el-input v-model="editForm.name" placeholder="请输入套餐名称"></el-input>
        </el-form-item>

        <div ref="productList" style="max-height: 500px; overflow-y: auto">
          <el-form-item label="主产品">
            <el-card>
              <div>
                <div v-if="editForm.proId" class="selected-product">
                  <el-image :src="editForm.mainPro.basePic"></el-image>
                  <div class="name">{{ editForm.mainPro.proName }}</div>
                </div>
              </div>
              <el-button type="primary" size="small" @click="selectProduct('main')">选择</el-button>
            </el-card>
          </el-form-item>

          <el-form-item v-for="(item, index) in selectedSubProductList" :key="index" :label="'子产品' + (index + 1)">
            <el-card>
              <div>
                <div class="selected-product">
                  <el-image :src="item.remotePic"></el-image>
                  <div class="name">{{ item.remoteName }}</div>
                </div>
              </div>
              <el-button type="danger" size="small" @click="deleteProduct(index)">移除</el-button>
            </el-card>
          </el-form-item>

          <el-form-item :label="'子产品' + (selectedSubProductList.length + 1)">
            <el-card>
              <div></div>
              <el-button type="primary" size="small" @click="selectProduct('sub')">选择</el-button>
            </el-card>
          </el-form-item>
        </div>
      </el-form>

      <span slot="footer">
        <el-button @click="editDialog = false">取 消</el-button>
        <el-button type="primary" @click="handleEditSave()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 套餐详情弹框 -->
    <el-dialog title="套餐详情" :visible.sync="detailDialog" custom-class="edit-dialog" width="600px">
      <el-form ref="detailForm" :model="detail" label-width="auto">
        <el-form-item label="套餐名称">
          <el-input v-model="detail.name" placeholder="请输入套餐名称" clearable></el-input>
        </el-form-item>

        <div ref="productList" style="max-height: 500px; overflow-y: auto">
          <el-form-item label="主产品">
            <el-card>
              <div class="selected-product">
                <el-image :src="detail.product.basePic"></el-image>
                <div class="name">{{ detail.product.proName }}</div>
              </div>
            </el-card>
          </el-form-item>

          <el-form-item v-for="(item, index) in detail.remotes" :key="index" :label="'子产品' + (index + 1)">
            <el-card>
              <div class="selected-product">
                <el-image :src="item.remotePic"></el-image>
                <div class="name">{{ item.remoteName }}</div>
              </div>
            </el-card>
          </el-form-item>
        </div>
      </el-form>

      <span slot="footer">
        <el-button @click="detailDialog = false">取 消</el-button>
        <el-button type="primary" @click="detailDialog = false">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 选择产品弹框 -->
    <el-dialog title="选择产品" :visible.sync="selectProductDialog" custom-class="select-product-dialog" width="500px">
      <template v-if="selectProductType == 'main'">
        <div
          v-for="item in mainProductList"
          :key="item.id"
          class="select-product-option"
          @click="confirmSelectProduct(item)"
        >
          <el-image :src="item.basePic"></el-image>
          <div class="name">{{ item.proName }}</div>
        </div>
      </template>

      <template v-if="selectProductType == 'sub'">
        <div
          v-for="item in subProductList"
          :key="item.id"
          class="select-product-option"
          @click="confirmSelectProduct(item)"
        >
          <el-image :src="item.remotePic"></el-image>
          <div class="name">{{ item.remoteName }}</div>
        </div>
      </template>
    </el-dialog>

    <!-- 出库弹框 -->
    <el-dialog
      :title="outboundForm.name + '出库'"
      :visible.sync="outboundDialog"
      custom-class="outbound-dialog"
      width="600px"
    >
      <el-form ref="outboundForm" :model="outboundForm" :rules="outboundRules" label-width="auto">
        <el-form-item label="出库数量" prop="total">
          <el-input v-model="outboundForm.total" placeholder="请输入需要出库的套餐数量" clearable></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="outboundDialog = false">取 消</el-button>
        <el-button type="primary" @click="confirmOutbound()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      query: {},
      queryForm: {
        search: ''
      },
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      queryDate: '',
      list: [],
      loading: false,

      editDialog: false,
      editForm: {
        remotes: []
      },
      editRules: {
        name: [{ required: true, message: '请输入套餐名称', trigger: 'blur' }]
      },
      mainProductList: [],
      subProductList: [],
      selectedSubProductList: [],

      selectProductDialog: false,
      selectProductType: '',

      detail: {
        product: {},
        remotes: []
      },
      detailDialog: false,

      outboundDialog: false,
      outboundForm: {
        id: null,
        name: '',
        total: ''
      },
      outboundRules: {
        total: [{ required: true, message: '请输入出库数量', trigger: 'blur' }]
      }
    }
  },

  computed: {},

  watch: {},

  created() {
    this.getList()
  },

  methods: {
    // 获取列表
    async getList() {
      this.loading = true
      const res = await this.$api.packageApi.getList({ ...this.query, ...this.pagination })
      this.loading = false

      if (res.code === '00000') {
        this.pagination.total = res.total
        this.list = res.data
      }
    },

    // 搜索事件
    handleSearch() {
      this.query = { ...this.queryForm }
      this.pagination.pageNum = 1
      this.getList()
    },

    // 查看详情
    handleDetail(row) {
      this.$router.push({ path: 'comboList', query: { id: row.id } })
    },

    // 关闭弹窗时清除表单
    clearContents() {
      this.editForm = {
        remotes: []
      }
    },

    // 新建
    async handleCreate() {
      this.editForm.remotes = []
      this.editDialog = true
      this.getMainProductList()
      this.getSubProductList()
    },

    // 获取主产品列表
    async getMainProductList() {
      const res = await this.$api.packageApi.getMainProductList()

      if (res.code === '00000') {
        this.mainProductList = res.data
      }
    },

    // 获取子产品列表
    async getSubProductList() {
      const res = await this.$api.packageApi.getSubProductList()

      if (res.code === '00000') {
        this.subProductList = res.data
      }
    },

    // 选择产品
    selectProduct(type) {
      this.selectProductType = type
      this.selectProductDialog = true
    },

    // 确认选择产品
    confirmSelectProduct(item) {
      this.selectProductDialog = false

      if (this.selectProductType === 'main') {
        this.editForm.proId = item.id
        this.editForm.mainPro = JSON.parse(JSON.stringify(item))
      } else if (this.selectProductType === 'sub') {
        this.editForm.remotes.push({ id: item.id })
        this.selectedSubProductList.push(JSON.parse(JSON.stringify(item)))
        this.$nextTick(() => {
          const ele = this.$refs.productList
          ele.scrollTo({ top: ele.scrollHeight, behavior: 'smooth' })
        })
      }
    },

    // 移除产品
    deleteProduct(index) {
      this.editForm.remotes.splice(index, 1)
      this.selectedSubProductList.splice(index, 1)
    },

    // 保存编辑
    handleEditSave() {
      this.$refs.editForm.validate(async (valid) => {
        if (!valid) return

        const res = await this.$api.packageApi.createPackage(this.editForm)
        if (res.code === '00000') {
          this.$message.success('操作成功！')
          this.editDialog = false
          this.getList()
        }
      })
    },

    // 录入套餐
    handleEntering(row) {
      this.$router.push({ path: 'createCombo', query: { id: row.id } })
    },

    // 出库
    handleOutbound(row) {
      this.outboundForm.id = row.id
      this.outboundForm.name = row.name
      this.outboundForm.total = ''
      this.outboundDialog = true
    },

    // 确认出库
    confirmOutbound() {
      this.$refs.outboundForm.validate(async (valid) => {
        if (!valid) return

        const res = await this.$api.packageApi.outboundPackage(this.outboundForm)
        if (res.code === '00000') {
          this.$message.success('操作成功！')
          this.outboundDialog = false
          this.getList()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  .el-card {
    .el-table {
      .pacInfo-wrapper {
        .title {
          color: #1086ff;
          font-size: 18px;
        }
        .proNum {
          margin-top: 4px;
        }
      }

      .proPic-wrapper {
        .el-image {
          width: 75px;
          height: 75px;
        }
      }
    }
  }

  /deep/ .edit-dialog {
    .el-card__body {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // max-height: 600px;
      padding: 4px 20px;

      .selected-product {
        display: flex;
        align-items: center;
        .el-image {
          width: 75px;
          height: 75px;
        }
        .name {
          margin-left: 20px;
          font-size: 18px;
        }
      }
    }
  }

  /deep/ .select-product-dialog {
    .el-dialog__body {
      padding: 4px 20px 10px;

      .select-product-option {
        display: flex;
        align-items: center;
        margin-top: 4px;
        cursor: pointer;

        &:hover {
          background-color: #c6e2ff;
        }
        &:first-child {
          margin-top: 0;
        }
        .el-image {
          width: 75px;
          height: 75px;
        }
        .name {
          margin-left: 20px;
          font-size: 18px;
        }
      }
    }
  }
}
</style>
