<template>
  <div class="page">
    <!-- 主体区域 -->
    <el-card>
      <!-- 筛选、操作区域 -->
      <OperationBar>
        <template slot="search-box">
          <el-form :model="queryForm" :inline="true">
            <el-form-item label="关键词">
              <el-input
                v-model="queryForm.search"
                placeholder="请输入关键词"
                clearable
                @keyup.enter.native="handleSearch()"
              ></el-input>
            </el-form-item>
            <el-form-item label="一级分类">
              <el-select
                v-model="queryForm.firstCategoryId"
                placeholder="请选择一级分类"
                clearable
                @change="categoryFirstIdChange"
              >
                <el-option
                  v-for="item in firstCategoryList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="二级分类">
              <el-select v-model="queryForm.categoryId" placeholder="请选择二级分类" clearable @change="handleSearch()">
                <el-option
                  v-for="item in secondCategoryList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="medium" @click="handleSearch()">查询</el-button>
            </el-form-item>
          </el-form>
        </template>

        <template slot="btn-box">
          <el-button type="primary" size="medium" round @click="createRemote()">新建遥控</el-button>
        </template>
      </OperationBar>

      <!-- 表格 -->
      <el-table ref="productListTable" :data="list" v-loading="loading" border>
        <el-table-column label="序号" type="index" width="50"></el-table-column>

        <el-table-column label="分类图片">
          <template slot-scope="{ row }">
            <div class="product-pic">
              <el-image :src="row.remotePic"></el-image>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="遥控名称" prop="remoteName"></el-table-column>
        <el-table-column label="一级类目" prop="firstCategoryName"></el-table-column>
        <el-table-column label="二级类目" prop="categoryName"></el-table-column>

        <el-table-column label="是否显示" width="120">
          <template slot-scope="{ row }">
            <el-switch
              v-model="row.isShow"
              :active-value="1"
              :inactive-value="0"
              @change="isShowChange(row)"
            ></el-switch>
          </template>
        </el-table-column>

        <el-table-column label="排序" prop="sort"></el-table-column>

        <el-table-column label="操作" width="280">
          <div slot-scope="{ row, $index }" class="action-cell">
            <el-button type="primary" round size="mini" @click="handleEdit(row)">编辑</el-button>
            <el-button type="primary" round size="mini" @click="downloadQRCode(row)">下载二维码</el-button>
            <ConfirmPopover :index="$index" @confirm="handleDelete(row)"></ConfirmPopover>
          </div>
        </el-table-column>
      </el-table>

      <!-- 分页器 -->
      <PaginationBar
        :pageNo.sync="pagination.pageNum"
        :pageSize.sync="pagination.pageSize"
        :total="pagination.total"
        @change="getList()"
      ></PaginationBar>
    </el-card>

    <!-- 编辑遥控弹框 -->
    <el-dialog title="编辑遥控" :visible.sync="editDialog" width="800px" @closed="clearContents()">
      <el-form ref="editForm" :model="editForm" :rules="editRules" label-width="auto">
        <el-form-item label="分类图片" prop="remotePic">
          <UploadImg :imgUrl.sync="editForm.remotePic"></UploadImg>
        </el-form-item>

        <el-form-item label="遥控名称" prop="remoteName">
          <el-input v-model="editForm.remoteName" placeholder="请输入遥控名称"></el-input>
        </el-form-item>

        <el-form-item label="一级分类" prop="categoryId">
          <el-select v-model="editForm.firstCategoryId" placeholder="请选择一级分类" @change="categoryFirstIdChange2">
            <el-option v-for="item in firstCategoryList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="二级分类" prop="categoryId">
          <el-select v-model="editForm.categoryId" placeholder="请选择二级分类">
            <el-option
              v-for="item in secondCategoryList2"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="匹配说明">
          <el-button type="primary" size="mini" @click="createMatchType()">添 加</el-button>
          <!-- 表格 -->
          <el-table
            v-if="editForm.items.length > 0"
            ref="matchTypeTable"
            :data="editForm.items"
            border
            max-height="300"
          >
            <el-table-column label="示例图" width="80">
              <template slot-scope="{ row }">
                <div class="product-pic">
                  <el-image :src="row.guidePics" style="width: 60px"></el-image>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="产品图片" width="80">
              <template slot-scope="{ row }">
                <div class="product-pic">
                  <el-image :src="row.pic" style="width: 60px"></el-image>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="产品名称" prop="name"></el-table-column>
            <el-table-column label="匹配说明" prop="guideMsg"></el-table-column>
            <el-table-column label="型号" prop="itemNo"></el-table-column>

            <el-table-column label="操作" width="100">
              <div slot-scope="{ row, $index }" class="action-cell">
                <el-button type="primary" round size="mini" @click="handleMatchTypeEdit(row, $index)">编辑</el-button>
                <ConfirmPopover :index="$index" @confirm="handleMatchTypeDelete(row, $index)"></ConfirmPopover>
              </div>
            </el-table-column>
          </el-table>
        </el-form-item>

        <el-form-item label="视频">
          <UploadVideo :url.sync="editForm.videoUrl"></UploadVideo>
        </el-form-item>

        <el-form-item label="排序" prop="sort">
          <el-input-number v-model="editForm.sort" :min="1" :step="1" step-strictly></el-input-number>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="editDialog = false">取 消</el-button>
        <el-button type="primary" @click="handleEditSave()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 编辑匹配类型弹框 -->
    <el-dialog title="编辑匹配类型" :visible.sync="matchTypeDialog" width="600px" @closed="clearContents2()">
      <el-form ref="matchTypeForm" :model="matchTypeForm" :rules="matchTypeRules" label-width="auto">
        <el-form-item label="示例图" prop="guidePics">
          <UploadImg :imgUrl.sync="matchTypeForm.guidePics" :accept="'image/jpeg,image/png,image/gif'"></UploadImg>
        </el-form-item>

        <el-form-item label="产品图片" prop="pic">
          <UploadImg :imgUrl.sync="matchTypeForm.pic" :accept="'image/jpeg,image/png,image/gif'"></UploadImg>
        </el-form-item>

        <el-form-item label="名称" prop="name">
          <el-input v-model="matchTypeForm.name" placeholder="请输入名称"></el-input>
        </el-form-item>

        <el-form-item label="匹配说明" prop="guideMsg">
          <el-input
            v-model="matchTypeForm.guideMsg"
            type="textarea"
            maxlength="50"
            show-word-limit
            :rows="3"
            placeholder="请输入匹配说明"
          ></el-input>
        </el-form-item>

        <el-form-item label="型号" prop="itemNo">
          <el-input v-model="matchTypeForm.itemNo" placeholder="请输入产品型号"></el-input>
        </el-form-item>

        <el-form-item label="按钮数量" prop="size">
          <el-input-number v-model="matchTypeForm.size" :min="0" :step="1" step-strictly></el-input-number>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="matchTypeDialog = false">取 消</el-button>
        <el-button type="primary" @click="handleMatchTypeSave()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {},

  data() {
    const validateBtnSize = (rule, value, callback) => {
      const arr = JSON.parse(JSON.stringify(this.editForm.items))
      if (this.editNum !== -1) {
        arr.splice(this.editNum, 1)
      }
      const isExist = arr.some((item) => item.size === value)

      if (isExist) {
        callback(new Error('同一遥控器下按钮数量需唯一'))
      } else {
        callback()
      }
    }

    return {
      query: {},
      queryForm: {
        search: '',
        firstCategoryId: null,
        categoryId: null
      },
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      loading: false,
      list: [],
      firstCategoryList: [],
      secondCategoryList: [],
      secondCategoryList2: [],

      editDialog: false,
      editForm: {},
      defaultEditForm: {
        remotePic: '',
        remoteName: '',
        firstCategoryId: '',
        categoryId: '',
        items: [],
        videoUrl: '',
        sort: ''
      },
      editRules: {
        remotePic: [{ required: true, message: '请上传分类图片', trigger: 'blur' }],
        remoteName: [{ required: true, message: '请输入遥控名称', trigger: 'blur' }],
        categoryId: [{ required: true, message: '请选择所属分类', trigger: 'blur' }],
        sort: [{ required: true, message: '请设置排序', trigger: 'blur' }]
      },

      matchTypeDialog: false,
      matchTypeForm: {},
      defaultMatchTypeForm: {
        name: '',
        itemNo: '',
        guidePics: '',
        pic: '',
        guideMsg: '',
        size: ''
      },
      matchTypeRules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        // itemNo: [{ required: true, message: '请输入产品型号', trigger: 'blur' }],
        guidePics: [{ required: true, message: '请上传示例图', trigger: 'blur' }],
        pic: [{ required: true, message: '请上传产品图片', trigger: 'blur' }],
        guideMsg: [{ required: true, message: '请输入匹配说明', trigger: 'blur' }],
        size: [
          { required: true, message: '请设置按钮数量', trigger: 'blur' },
          { validator: validateBtnSize, trigger: 'blur' }
        ]
      },
      isCreateMatchType: true,
      editNum: -1
    }
  },

  filters: {},

  computed: {},

  watch: {},

  created() {
    this.init()
    this.getList()
    this.getFirstCategoryList()
  },

  methods: {
    init() {
      // this.editForm = { ...this.defaultEditForm }
      this.editForm = JSON.parse(JSON.stringify(this.defaultEditForm))
      this.matchTypeForm = JSON.parse(JSON.stringify(this.defaultMatchTypeForm))
    },

    // 获取列表
    async getList() {
      this.loading = true
      const res = await this.$api.subProduct.getRemoteList({ ...this.query, ...this.pagination })
      this.loading = false

      if (res.code === '00000') {
        this.list = res.data
        this.pagination.total = res.total
      }
    },

    // 获取一级分类列表
    async getFirstCategoryList() {
      const res = await this.$api.subProduct.getCategoryList({ levels: 1, pageNum: 1, pageSize: 999 })

      if (res.code === '00000') {
        this.firstCategoryList = res.data
      }
    },

    // 获取二级分类列表
    async getSecondCategoryList(id) {
      const res = await this.$api.subProduct.getCategoryList({ levels: 2, categoryId: id, pageNum: 1, pageSize: 999 })

      if (res.code === '00000') {
        this.secondCategoryList = res.data
      }
    },

    // 获取二级分类列表
    async getSecondCategoryList2(id) {
      const res = await this.$api.subProduct.getCategoryList({ levels: 2, categoryId: id, pageNum: 1, pageSize: 999 })

      if (res.code === '00000') {
        this.secondCategoryList2 = res.data
      }
    },

    // 搜索事件
    handleSearch() {
      this.query = { ...this.queryForm }
      this.pagination.pageNum = 1
      this.getList()
    },

    // 添加遥控
    createRemote() {
      this.editDialog = true
    },

    // 添加匹配类型
    createMatchType() {
      this.isCreateMatchType = true
      this.editNum = -1
      this.matchTypeDialog = true
    },

    // 显示隐藏状态变化时
    async isShowChange(row) {
      const res = await this.$api.subProduct.setRemoteShow(row.id)

      if (res.code === '00000') {
        this.$message.success('操作成功！')
        this.getList()
      }
    },

    // 编辑
    handleEdit(row) {
      this.editForm = { ...row }
      this.getSecondCategoryList2(this.editForm.firstCategoryId)
      this.editDialog = true
    },

    // 编辑匹配类型
    handleMatchTypeEdit(row, index) {
      this.isCreateMatchType = false
      this.editNum = index
      this.matchTypeForm = { ...row }
      this.matchTypeDialog = true
    },

    // 一级分类变化时触发
    categoryFirstIdChange(value) {
      this.queryForm.categoryId = null
      this.secondCategoryList = []
      this.handleSearch()
      this.getSecondCategoryList(value)
    },

    // 一级分类变化时触发
    categoryFirstIdChange2(value) {
      this.editForm.categoryId = ''
      this.secondCategoryList2 = []
      this.getSecondCategoryList2(value)
    },

    // 关闭弹窗时清除表单
    clearContents() {
      // this.editForm = { ...this.defaultEditForm }
      this.editForm = JSON.parse(JSON.stringify(this.defaultEditForm))
      this.matchTypeForm = JSON.parse(JSON.stringify(this.defaultMatchTypeForm))
    },

    // 关闭弹窗时清除表单
    clearContents2() {
      this.matchTypeForm = JSON.parse(JSON.stringify(this.defaultMatchTypeForm))
    },

    // 下载二维码
    async downloadQRCode(row) {
      const res = await this.$api.download.getQRCode({
        type: 1,
        id: row.id
      })

      if (res.code === '00000') {
        const link = document.createElement('a')
        link.href = res.data
        link.download = row.remoteName + '.png'
        link.click()
      }
    },

    // 删除
    async handleDelete(row) {
      const res = await this.$api.subProduct.deleteRemote(row.id)

      if (res.code === '00000') {
        this.$message.success('删除成功！')
        if (this.list.length === 1 && this.pagination.pageNum > 1) {
          this.pagination.pageNum--
        }
        this.getList()
      }
    },

    // 删除匹配类型
    handleMatchTypeDelete(row, index) {
      this.editForm.items.splice(index, 1)
    },

    // 保存编辑
    handleEditSave() {
      this.$refs.editForm.validate(async (valid) => {
        if (!valid) return

        const res = await this.$api.subProduct.editRemote(this.editForm)
        if (res.code === '00000') {
          this.$message.success('操作成功！')
          this.editDialog = false
          this.getList()
        }
      })
    },

    // 保存匹配类型编辑
    handleMatchTypeSave() {
      this.$refs.matchTypeForm.validate(async (valid) => {
        if (!valid) return

        if (this.isCreateMatchType) {
          this.editForm.items.push(JSON.parse(JSON.stringify(this.matchTypeForm)))
        } else {
          this.$set(this.editForm.items, this.editNum, JSON.parse(JSON.stringify(this.matchTypeForm)))
        }
        this.matchTypeDialog = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  .el-card {
    .el-table {
      .product-pic {
        .el-image {
          width: 75px;
          height: 75px;
        }
      }
    }
  }
}
</style>
