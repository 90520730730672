<template>
  <div class="page">
    <el-card>
      <div slot="header">
        <span>管理员信息</span>
      </div>

      <!-- 表单区域 -->
      <el-form ref="form" :model="form" :rules="rules" label-width="auto">
        <el-form-item label="头像" prop="avatar">
          <UploadImg :imgUrl.sync="form.avatar"></UploadImg>
        </el-form-item>

        <el-form-item label="管理员名称" prop="nickname">
          <el-input v-model="form.nickname" placeholder="请输入管理员名称"></el-input>
        </el-form-item>

        <el-form-item label="管理员账号" prop="username">
          <el-input v-model="form.username" placeholder="请输入管理员账号" :disabled="id"></el-input>
        </el-form-item>

        <el-form-item label="角色" prop="roleId">
          <el-select v-model="form.roleId" placeholder="请选择管理员角色">
            <el-option v-for="item in roles" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="创建时间">
          <el-col :span="10">
            <el-input v-model="form.gmtCreate" disabled></el-input>
          </el-col>
          <el-col class="line" :span="4" style="text-align: right">更新时间&nbsp;&nbsp;&nbsp;</el-col>
          <el-col :span="10">
            <el-input v-model="form.gmtModified" disabled></el-input>
          </el-col>
        </el-form-item>
      </el-form>

      <div class="btn-box">
        <el-button @click="handleCancel()">取 消</el-button>
        <el-button type="primary" @click="handleSave()">保 存</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      id: null,

      form: {
        avatar: '',
        nickname: '',
        username: '',
        roleId: null,
        roleIds: null,
        gmtCreate: null,
        gmtModified: null
      },

      rules: {
        avatar: [{ required: true, message: '请上传头像', trigger: 'blur' }],
        nickname: [{ required: true, message: '请输入管理员名称', trigger: 'blur' }],
        username: [{ required: true, message: '请输入管理员账号', trigger: 'blur' }],
        roleId: [{ required: true, message: '请选择管理员角色', trigger: 'blur' }]
      },

      pagination: {
        page: 1,
        limit: 9999
      },
      roles: []
    }
  },

  watch: {},

  created() {
    this.id = this.$route.query.id
    if (this.id) this.getDetail()
    this.getRoleList()
  },

  methods: {
    // 获取账号详情
    async getDetail() {
      const res = await this.$api.account.getDetail(this.id)

      if (res.code === '00000') {
        res.data.roleId = res.data.roleIds[0]

        this.form = res.data
      }
    },

    // 获取角色列表
    async getRoleList() {
      const res = await this.$api.role.getList(this.pagination)

      if (res.code === '00000') {
        this.roles = res.data
      }
    },

    // 取消编辑，返回上一级
    handleCancel() {
      this.$router.back()
    },

    // 保存
    async handleSave() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return

        this.form.roleIds = [this.form.roleId]

        if (this.id) {
          const res = await this.$api.account.updateAccount({ id: this.id, ...this.form })

          if (res.code === '00000') {
            this.$message.success('修改成功！')
            this.$router.back()
          }
        } else {
          const res = await this.$api.account.createAccount(this.form)

          if (res.code === '00000') {
            this.$message.success('创建成功！')
            this.$router.back()
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  .el-card {
    .el-form {
      .el-form-item {
        width: 600px;
        .el-select {
          width: 100%;
        }
      }
    }

    .btn-box {
      display: flex;
      justify-content: space-around;
      width: 600px;
      margin: 40px 0 20px;
    }
  }
}
</style>
