<template>
  <div class="page">
    <el-card>
      <div slot="header">
        <div style="display: flex; align-items: center; justify-content: space-between">
          <span>供货产品</span>
          <el-button type="primary" size="medium" round @click="addProduct()">新增产品</el-button>
        </div>
      </div>

      <!-- 表格 -->
      <el-table ref="listTable" :data="list" v-loading="loading" border>
        <el-table-column label="序号" type="index" width="50"></el-table-column>

        <el-table-column label="产品图片" prop="pic">
          <template slot-scope="{ row }">
            <div class="product-pic">
              <el-image :src="row.pic"></el-image>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="产品名称" prop="proName"></el-table-column>
        <el-table-column label="产品型号" prop="proNo"></el-table-column>
        <el-table-column label="创建时间" prop="gmtCreate"></el-table-column>

        <el-table-column label="操作" width="200">
          <div slot-scope="{ row, $index }" class="action-cell">
            <el-button type="primary" round size="mini" @click="viewDetails(row)">查看详情</el-button>
            <ConfirmPopover :index="$index" @confirm="handleDelete(row)"></ConfirmPopover>
          </div>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 新增产品弹框 -->
    <el-dialog
      title="新增产品"
      :visible.sync="productListDialog"
      custom-class="productListDialog"
      width="600px"
      top="10vh"
    >
      <el-table ref="listTable" :data="subProductList" border>
        <el-table-column label="序号" type="index" width="50"></el-table-column>

        <el-table-column label="产品图片">
          <template slot-scope="{ row }">
            <div class="product-pic">
              <el-image :src="row.remotePic" style="width: 60px"></el-image>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="产品名称" prop="remoteName"></el-table-column>

        <el-table-column label="操作" width="100">
          <div slot-scope="{ row }" class="action-cell">
            <el-button type="primary" round size="mini" @click="handleSelectProduct(row)">选择</el-button>
          </div>
        </el-table-column>
      </el-table>
    </el-dialog>

    <!-- 编辑产品弹框 -->
    <el-dialog
      title="产品信息"
      :visible.sync="editProductDialog"
      custom-class="editProductDialog"
      width="600px"
      @closed="clearContents()"
    >
      <!-- 表单区域 -->
      <el-form ref="form" :model="selectProduct" :rules="rules" label-width="auto">
        <el-form-item label="产品图片">
          <el-image :src="selectProduct.remotePic" style="width: 100px"></el-image>
        </el-form-item>
        <el-form-item label="产品名称">
          <el-input v-model="selectProduct.remoteName" disabled></el-input>
        </el-form-item>

        <el-form-item label="产品型号" prop="proNoArray">
          <el-tag
            v-for="tag in selectProduct.proNoArray"
            :key="tag"
            closable
            :disable-transitions="false"
            @close="handleTagClose(tag)"
          >
            {{ tag }}
          </el-tag>
          <el-input
            class="tag-input"
            v-if="showTagInput"
            v-model="tagInputValue"
            ref="saveTagInput"
            size="small"
            @keyup.enter.native="handleTagInputConfirm"
            @blur="handleTagInputConfirm"
          >
          </el-input>
          <el-button v-else class="button-new-tag" size="small" @click="handleShowTagInput()">+ 添加型号</el-button>
        </el-form-item>

        <el-form-item v-if="selectProduct.gmtCreate" label="创建时间">
          <el-input v-model="selectProduct.gmtCreate" disabled></el-input>
        </el-form-item>
        <el-form-item v-if="selectProduct.gmtModified" label="更新时间">
          <el-input v-model="selectProduct.gmtModified" disabled></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="selectProduct.note"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="editProductDialog = false">取 消</el-button>
        <el-button type="primary" @click="handleProductSave()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      id: null,
      list: [],
      loading: false,
      subProductList: [],
      productListDialog: false,
      editProductDialog: false,
      selectProduct: {},
      showTagInput: false,
      tagInputValue: '',
      rules: {
        proNoArray: [{ required: true, message: '请填写产品型号', trigger: 'blur' }]
      }
    }
  },

  filters: {},
  computed: {},
  watch: {},

  created() {
    this.id = this.$route.query.id
    if (this.id) this.getDetail()
    this.getSubProductList()
  },

  methods: {
    // 获取详情
    async getDetail() {
      this.loading = true
      const res = await this.$api.supplier.getDetail(this.id)
      this.loading = false

      if (res.code === '00000') {
        this.list = res.data
      }
    },

    // 获取子产品列表
    async getSubProductList() {
      const res = await this.$api.subProduct.getRemoteList({ pageNum: 1, pageSize: 9999 })

      if (res.code === '00000') {
        this.subProductList = res.data
      }
    },

    // 新增产品
    addProduct() {
      this.productListDialog = true
    },

    // 选择产品
    handleSelectProduct(row) {
      this.selectProduct = JSON.parse(JSON.stringify(row))

      if (this.selectProduct.pic) this.selectProduct.remotePic = this.selectProduct.pic
      if (this.selectProduct.proName) this.selectProduct.remoteName = this.selectProduct.proName

      if (this.selectProduct.proNo) {
        this.selectProduct.proNoArray = this.selectProduct.proNo.split(',')
      } else {
        this.selectProduct.proNoArray = []
      }

      this.editProductDialog = true
    },

    // 查看详情
    viewDetails(row) {
      this.handleSelectProduct(row)
    },

    // 关闭弹窗时清除表单
    clearContents() {
      this.selectProduct = {}
    },

    // 显示标签输入框
    handleShowTagInput() {
      this.showTagInput = true
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },

    // 确认标签输入框
    handleTagInputConfirm() {
      if (this.tagInputValue) {
        this.selectProduct.proNoArray.push(this.tagInputValue)
      }

      this.showTagInput = false
      this.tagInputValue = ''
    },

    // 删除标签
    handleTagClose(tag) {
      this.selectProduct.proNoArray.splice(this.selectProduct.proNoArray.indexOf(tag), 1)
      this.$forceUpdate()
    },

    // 确认新增产品
    async handleProductSave(row) {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return

        const form = JSON.parse(JSON.stringify(this.selectProduct))
        form.proId = this.selectProduct.id
        form.supId = this.id
        form.proNo = form.proNoArray.join(',')

        const res = await this.$api.supplier.addSupplierProduct(form)
        if (res.code === '00000') {
          this.$message.success('操作成功！')
          this.productListDialog = false
          this.editProductDialog = false
          this.tagInputValue = ''
          this.getDetail()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  .el-card {
    .el-table {
      .el-image {
        width: 75px;
        height: 75px;
      }
    }
  }

  /deep/ .productListDialog {
    .el-dialog__body {
      overflow-y: auto;
      max-height: 70vh;

      .el-table {
        tbody {
          td {
            padding: 4px 0;
          }
        }
      }
    }
  }

  /deep/ .editProductDialog {
    .el-dialog__body {
      .el-form {
        .el-form-item {
          .el-tag {
            margin-right: 10px;
          }

          .button-new-tag {
            // margin-left: 10px;
            height: 32px;
            line-height: 30px;
            padding-top: 0;
            padding-bottom: 0;
          }

          .tag-input {
            width: 100px;
            // margin-left: 10px;
            // vertical-align: bottom;
          }
        }
      }
    }
  }
}
</style>
