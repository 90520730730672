<template>
  <div class="page">
    <el-card>
      <div slot="header">
        <span>订单详情</span>
      </div>

      <!-- 表单区域 -->
      <el-form ref="form" :model="detail" label-width="auto">
        <el-form-item label="订单状态">
          <el-steps v-if="detail.status != -1" :active="stepIndex" align-center>
            <el-step title="下单"></el-step>
            <el-step title="生产中"></el-step>
            <el-step title="已入库"></el-step>
          </el-steps>
          <div v-else>已取消</div>
        </el-form-item>

        <el-form-item label="订单编号">
          <el-input v-model="detail.orderNo" disabled></el-input>
        </el-form-item>

        <el-form-item label="订单数量">
          <el-col :span="10">
            <el-input v-model="detail.num" disabled></el-input>
          </el-col>
          <el-col class="line" :span="4" style="text-align: right">已入库数量&nbsp;&nbsp;&nbsp;</el-col>
          <el-col :span="10">
            <el-input v-model="detail.numed" disabled></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="下单时间">
          <el-col :span="10">
            <el-input v-model="detail.gmtCreate" disabled></el-input>
          </el-col>
          <el-col class="line" :span="4" style="text-align: right">订单完成时间&nbsp;&nbsp;&nbsp;</el-col>
          <el-col :span="10">
            <el-input v-model="detail.gmtModified" disabled></el-input>
          </el-col>
        </el-form-item>
      </el-form>

      <el-table ref="productDetailListTable" :data="productDetailList" border>
        <el-table-column label="产品图片">
          <template slot-scope="{ row }">
            <div class="product-pic">
              <el-image :src="row.basePic"></el-image>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="产品名称" prop="proName"></el-table-column>
        <el-table-column label="产品型号" prop="type"></el-table-column>
        <el-table-column label="颜色" prop="color"></el-table-column>
        <el-table-column label="连接方式" prop="linkWayStr"></el-table-column>

        <el-table-column label="操作" width="120">
          <div slot-scope="{ row }" class="action-cell">
            <el-button type="primary" round size="mini" @click="checkProductDetail(row)">查看详情</el-button>
          </div>
        </el-table-column>
      </el-table>

      <div class="btn-box">
        <el-button @click="$router.back()">返 回</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import { linkWays } from '@/utils/data'

export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      detail: {},
      stepIndex: 0,
      productDetail: {},
      productDetailList: []
    }
  },

  filters: {},
  computed: {},
  watch: {},

  created() {
    this.init()
    this.getProductDetail()
  },

  methods: {
    init() {
      this.detail = JSON.parse(localStorage.getItem('orderDetail'))

      if (this.detail.status === 0) {
        this.stepIndex = 0
      } else if (this.detail.status === 5) {
        this.stepIndex = 1
      } else if (this.detail.status === 10) {
        this.stepIndex = 99
      }
    },

    // 获取产品详情
    async getProductDetail() {
      const res = await this.$api.productFactory.getDetail(this.detail.proNo)

      if (res.code === '00000') {
        if (res.data.linkWay) {
          res.data.linkWay = res.data.linkWay.split(',')
        }

        this.productDetail = res.data
        this.formatLinkWay()
      }
    },

    // linkWay 转字符串
    formatLinkWay() {
      let str = ''
      this.productDetail.linkWay.forEach((item, index) => {
        const obj = linkWays.find((item2) => item2.value === item)
        str += obj ? obj.label : '未知'
        if (index + 1 === this.productDetail.linkWay.length) {
        } else {
          str += '、'
        }
      })
      this.productDetail.linkWayStr = str
      this.productDetailList.push(this.productDetail)
      console.log('🚀 ~ formatLinkWay ~ this.productDetailList', this.productDetailList)
    },

    checkProductDetail(row) {
      this.$router.push({
        path: '/product_factory/detail',
        query: {
          proNo: row.proNo
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  .el-card {
    .el-form {
      .el-form-item {
        width: 800px;
        .el-select {
          width: 100%;
        }
        .product-img {
          width: 100px;
          height: 100px;
        }
      }
    }

    .el-table {
      .product-pic {
        .el-image {
          width: 75px;
          height: 75px;
        }
      }
    }

    .btn-box {
      display: flex;
      justify-content: space-around;
      width: 800px;
      margin: 40px 0 20px;
    }
  }
}
</style>
