<template>
  <div class="page">
    <el-card>
      <div slot="header">
        <span v-if="type == 1">自动化基础信息</span>
        <span v-if="type == 2">场景基础信息</span>
      </div>

      <el-form v-if="type == 1" ref="form1" :model="form" :rules="rules" label-width="auto">
        <el-form-item label="图标" prop="icon">
          <UploadImg :imgUrl.sync="form.icon"></UploadImg>
        </el-form-item>
        <el-form-item label="自动化名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入场景名称"></el-input>
        </el-form-item>
        <el-form-item label="所属分类" prop="categoryId">
          <el-select v-model="form.categoryId" placeholder="请选择所属分类">
            <el-option v-for="item in categoryList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="场景教程" prop="video">
          <UploadVideo :url.sync="form.video"></UploadVideo>
        </el-form-item>
      </el-form>

      <el-form v-if="type == 2" ref="form1" :model="form" :rules="rules" label-width="auto">
        <el-form-item label="场景图" prop="icon">
          <UploadImg :imgUrl.sync="form.icon"></UploadImg>
        </el-form-item>
        <el-form-item label="场景名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入场景名称"></el-input>
        </el-form-item>
        <el-form-item label="场景介绍" prop="intro">
          <el-input
            v-model="form.intro"
            type="textarea"
            maxlength="50"
            show-word-limit
            :rows="3"
            placeholder="请输入场景介绍"
          ></el-input>
        </el-form-item>
        <el-form-item label="场景教程" prop="video">
          <UploadVideo :url.sync="form.video"></UploadVideo>
        </el-form-item>
      </el-form>
    </el-card>

    <el-card style="margin-top: 10px">
      <div slot="header">
        <span>控制内容设置</span>
      </div>

      <div v-if="type == 2" class="sceneType">
        <el-button :type="form.type == 0 ? 'primary' : ''" @click="changeSceneType(0)">智能场景</el-button>
        <el-button :type="form.type == 1 ? 'primary' : ''" @click="changeSceneType(1)">手动场景</el-button>
      </div>

      <el-card v-for="(item, index) in form.sceneConditions" :key="item.nanoid" class="controlModule-wrapper">
        <div v-if="type == 2 && form.type == 0 && item.control != 'AI'" style="margin-bottom: 10px; text-align: right">
          <el-button
            v-if="form.sceneConditions.length == index + 1"
            type="primary"
            icon="el-icon-plus"
            @click="createControlModule()"
            >添加控制模块</el-button
          >
          <el-button v-else type="danger" icon="el-icon-minus" @click="deleteControlModule(index)"
            >删除控制模块</el-button
          >
        </div>

        <el-form :ref="'form2_' + index" :model="item" :rules="rules" label-width="auto">
          <el-form-item v-if="form.type == 0" label="控制条件" required>
            <div class="item-row">
              <!-- 第一列 -->
              <el-form-item prop="control">
                <el-select
                  v-model="item.control"
                  placeholder="请选择控制条件"
                  @change="(value) => onControlChange(value, index)"
                >
                  <el-option
                    v-for="sensor in sensorTypeList"
                    :key="sensor.value"
                    :label="sensor.label"
                    :value="sensor.label"
                  ></el-option>
                </el-select>
              </el-form-item>
              <!-- 第二列 -->
              <el-form-item v-if="conditionList1.includes(item.control)" class="ml20" label="判定条件" prop="judge">
                <el-select v-model="item.judge" placeholder="请选择判定条件">
                  <el-option label="高于" value="高于"></el-option>
                  <el-option label="低于" value="低于"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                v-else-if="['人体传感器', '人体(光照)传感器'].includes(item.control)"
                class="ml20"
                label="判定条件"
                prop="judge"
              >
                <el-select v-model="item.judge" placeholder="请选择判定条件">
                  <el-option label="有人" value="有人"></el-option>
                  <el-option label="无人" value="无人"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-else-if="['定时'].includes(item.control)" label="时间" prop="numerical">
                <el-time-picker
                  v-model="item.numerical"
                  arrow-control
                  format="HH:mm"
                  value-format="HH:mm"
                  placeholder="请选择时间"
                ></el-time-picker>
              </el-form-item>
              <el-form-item
                v-else-if="['门磁传感器'].includes(item.control)"
                class="ml20"
                label="判定条件"
                prop="judge"
              >
                <el-select v-model="item.judge" placeholder="请选择判定条件">
                  <el-option label="开" value="开"></el-option>
                  <el-option label="关" value="关"></el-option>
                </el-select>
              </el-form-item>
              <!-- 第三列 -->
              <el-form-item v-if="conditionList1.includes(item.control)" label="数值" prop="numerical">
                <el-input-number v-model="item.numerical" placeholder="请设置值"></el-input-number>
              </el-form-item>
            </div>
          </el-form-item>

          <el-form-item label="动作" prop="sceneContents" style="width: 900px">
            <el-button type="primary" size="mini" @click="createDevice(index)">添加设备</el-button>
            <el-button v-if="type == 2" type="primary" size="mini" @click="createDelay(index)">添加延时</el-button>
            <el-button type="primary" size="mini" @click="createPush(index)">添加推送</el-button>
            <!-- 表格 -->
            <el-table ref="deviceTable" :data="item.sceneContents" border>
              <el-table-column label="示例图" width="100">
                <template slot-scope="{ row }">
                  <el-image
                    v-if="row.remoteId == '-1'"
                    src="https://cloud.lantin.me/pro/icon/%E5%A4%9C%E7%81%AF.png"
                  ></el-image>
                  <el-image
                    v-else-if="row.remoteId == '-2'"
                    src="https://cloud.lantin.me/pro/icon/%E5%BB%B6%E6%97%B6.png"
                  ></el-image>
                  <el-image
                    v-else-if="row.remoteId == '-3'"
                    src="https://cloud.lantin.me/pro/icon/%E6%8E%A8%E9%80%81.png"
                  ></el-image>
                  <el-image v-else :src="row.remotePic"></el-image>
                </template>
              </el-table-column>
              <el-table-column label="产品类型" prop="remoteName" width="200">
                <template slot-scope="{ row }">
                  <span v-if="row.remoteId == '-1'">智能伴侣-夜灯</span>
                  <span v-else-if="row.remoteId == '-2'">延时</span>
                  <span v-else-if="row.remoteId == '-3'">推送</span>
                  <span v-else>{{ row.remoteName }}</span>
                </template>
              </el-table-column>
              <el-table-column label="设备状态" width="420">
                <template slot-scope="{ row, $index }">
                  <!-- 开关、模式 -->
                  <div v-if="deviceStatusList1.includes(row.remoteId)" class="status-setting">
                    <div class="status-setting-item">
                      <!-- <span>开关</span> -->
                      <el-select v-model="item.sceneContents[$index].onOff" placeholder="开关" clearable>
                        <el-option label="开" value="开"></el-option>
                        <el-option label="关" value="关"></el-option>
                      </el-select>
                    </div>
                    <div class="status-setting-item">
                      <!-- <span>模式</span> -->
                      <el-select
                        v-if="row.remoteId == -1"
                        v-model="item.sceneContents[$index].model"
                        placeholder="模式"
                        clearable
                      >
                        <el-option label="助眠" value="助眠"></el-option>
                        <el-option label="唤醒" value="唤醒"></el-option>
                        <el-option label="呼吸" value="呼吸"></el-option>
                      </el-select>
                      <el-select
                        v-if="row.remoteId == 2"
                        v-model="item.sceneContents[$index].model"
                        placeholder="模式"
                        clearable
                      >
                        <el-option label="制冷" value="制冷"></el-option>
                        <el-option label="制热" value="制热"></el-option>
                        <el-option label="除湿" value="除湿"></el-option>
                        <el-option label="自动" value="自动"></el-option>
                      </el-select>
                    </div>
                  </div>
                  <!-- 延时 -->
                  <div v-else-if="['-2'].includes(row.remoteId)" class="status-setting">
                    <el-select v-model="item.sceneContents[$index].time" placeholder="延时时间" clearable>
                      <el-option label="一小时" :value="3600"></el-option>
                      <el-option label="两小时" :value="7200"></el-option>
                    </el-select>
                  </div>
                  <!-- 推送 -->
                  <div v-else-if="['-3'].includes(row.remoteId)" class="status-setting push-setting">
                    <el-input
                      v-model="item.sceneContents[$index].content"
                      type="textarea"
                      maxlength="50"
                      show-word-limit
                      :rows="2"
                      placeholder="请输入推送内容"
                    ></el-input>
                    <el-input
                      v-model="item.sceneContents[$index].smsMsg"
                      type="textarea"
                      maxlength="50"
                      show-word-limit
                      :rows="2"
                      placeholder="请输入短信内容"
                    ></el-input>
                  </div>
                  <!-- 开关(其他) -->
                  <div v-else class="status-setting">
                    <div class="status-setting-item">
                      <!-- <span>开关</span> -->
                      <el-select v-model="item.sceneContents[$index].onOff" placeholder="开关" clearable>
                        <el-option label="开" value="开"></el-option>
                        <el-option label="关" value="关"></el-option>
                      </el-select>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="100">
                <div slot-scope="{ row, $index }" class="action-cell">
                  <ConfirmPopover :index="$index" @confirm="deleteDevice(row, index, $index)"></ConfirmPopover>
                </div>
              </el-table-column>
            </el-table>
          </el-form-item>
        </el-form>
      </el-card>

      <div class="btn-box">
        <el-button @click="handleCancel()">取 消</el-button>
        <el-button type="primary" @click="handleSave()">保 存</el-button>
      </div>
    </el-card>

    <!-- 选择设备弹框 -->
    <el-dialog
      title="选择设备"
      :visible.sync="createDeviceDialog"
      custom-class="selectDeviceDialog"
      width="950px"
      top="10vh"
    >
      <div class="selectDeviceDialog_body">
        <el-card
          v-for="device in remoteList"
          :key="device.id"
          class="device-item"
          @click.native="selectDeviceItem(device)"
        >
          <el-image :src="device.remotePic"></el-image>
          <div class="name">{{ device.remoteName }}</div>
        </el-card>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { nanoid } from 'nanoid'

export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      id: null,
      type: null,

      form: {
        name: '',
        icon: '',
        categoryId: '',
        intro: '',
        video: '',
        type: 0,
        sceneConditions: []
      },

      rules: {
        name: [{ required: true, message: '请输入自动化名称', trigger: 'blur' }],
        icon: [{ required: true, message: '请上传图标', trigger: 'blur' }],
        intro: [{ required: true, message: '请选择所属分类', trigger: 'blur' }],
        video: [{ required: false }],
        control: [{ required: true, message: '请选择控制条件', trigger: 'blur' }],
        judge: [{ required: true, message: '请选择判定条件', trigger: 'blur' }],
        numerical: [{ required: true, message: '请设置值', trigger: 'blur' }],
        sceneContents: [{ required: true, message: '请设置动作', trigger: 'blur' }]
      },

      categoryList: [],
      remoteList: [],
      sensorTypeList: [
        { value: -1, label: 'AI' },
        { value: -2, label: '定时' }
      ],

      selectModuleIndex: -1,
      createDeviceDialog: false,

      conditionList1: ['温度', '湿度', 'VOC', 'CO2', '甲醛'], // 判定条件列表1

      deviceStatusList1: ['-1', '2'] // 设备状态列表1
    }
  },

  filters: {},
  computed: {},
  watch: {},

  created() {
    this.init()
    this.getCategoryList()
    this.getRemoteList()
    this.getSensorTypeList()
  },

  methods: {
    init() {
      this.id = this.$route.query.id
      this.type = this.$route.query.type

      if (this.id) {
        this.getDetail()
      } else {
        this.form.sceneConditions = [
          {
            nanoid: nanoid(),
            control: '',
            judge: '',
            numerical: '',
            sceneContents: []
          }
        ]
      }
    },

    // 获取详情
    async getDetail() {
      const res = await this.$api.automation.getSceneDetail(this.id)

      if (res.code === '00000') {
        if (res.data.type === 0) {
          res.data.sceneConditions.forEach((item) => {
            item.nanoid = nanoid()
          })
          this.form = res.data
        } else if (res.data.type === 1) {
          res.data.sceneContents.forEach((item) => {
            item.nanoid = nanoid()
          })
          res.data.sceneConditions = [{ sceneContents: [] }]
          res.data.sceneConditions[0].sceneContents = JSON.parse(JSON.stringify(res.data.sceneContents))

          this.form = res.data
        }
      }
    },

    // 获取分类列表
    async getCategoryList() {
      const res = await this.$api.automation.getCategoryList({
        pageNum: 1,
        pageSize: 999
      })

      if (res.code === '00000') {
        this.categoryList = res.data
      }
    },

    // 获取遥控列表
    async getRemoteList() {
      const res = await this.$api.subProduct.getRemoteList({
        pageNum: 1,
        pageSize: 999
      })

      if (res.code === '00000') {
        this.remoteList = res.data
        // this.remoteList = res.data.filter((item) => item.isShow === 1)
        this.remoteList = [
          {
            id: '0',
            remoteName: '摄像头',
            remotePic: 'https://cloud.lantin.me/pro/cloud/26ddb0ec0aa14b148dbfbd0ba69e0237_400*400.png'
          },
          {
            id: '-1',
            remoteName: '智能伴侣-夜灯',
            remotePic: 'https://cloud.lantin.me/pro/icon/%E5%A4%9C%E7%81%AF.png'
          },
          ...this.remoteList
        ]
      }
    },

    // 获取传感器类型列表
    async getSensorTypeList() {
      const res = await this.$api.automation.getSensorTypeList()

      if (res.code === '00000') {
        for (const i in res.data) {
          const obj = {}
          obj.value = i
          obj.label = res.data[i]
          this.sensorTypeList.push(obj)
        }
      }
    },

    // 切换场景类型
    changeSceneType(value) {
      this.form.type = value
      this.form.sceneConditions = [
        {
          nanoid: nanoid(),
          control: '',
          judge: '',
          numerical: '',
          sceneContents: []
        }
      ]
    },

    // 控制条件变化时
    onControlChange(value, index) {
      if (value === 'AI') {
        const obj = JSON.parse(JSON.stringify(this.form.sceneConditions[index]))
        this.form.sceneConditions = [obj]
        this.selectModuleIndex = 0
      } else {
        this.selectModuleIndex = index
      }

      this.form.sceneConditions[this.selectModuleIndex].judge = ''
      this.form.sceneConditions[this.selectModuleIndex].numerical = ''
    },

    // 添加控制模块
    createControlModule() {
      this.form.sceneConditions.push({
        nanoid: nanoid(),
        control: '',
        judge: '',
        numerical: '',
        sceneContents: []
      })
    },

    // 删除控制模块
    deleteControlModule(index) {
      this.selectModuleIndex = index
      this.form.sceneConditions.splice(this.selectModuleIndex, 1)
    },

    // 添加设备
    createDevice(index) {
      this.selectModuleIndex = index
      this.createDeviceDialog = true
    },

    // 选择设备
    selectDeviceItem(device) {
      const data = {
        ...device,
        remoteId: device.id,
        onOff: '',
        model: ''
      }
      this.form.sceneConditions[this.selectModuleIndex].sceneContents.push(data)

      this.createDeviceDialog = false
    },

    // 添加延时
    createDelay(index) {
      this.selectModuleIndex = index

      const data = {
        id: '-2',
        remoteId: '-2',
        remoteName: '延时',
        remotePic: 'https://cloud.lantin.me/pro/icon/%E5%BB%B6%E6%97%B6.png',
        time: ''
      }
      this.form.sceneConditions[this.selectModuleIndex].sceneContents.push(data)
    },

    // 添加推送
    createPush(index) {
      this.selectModuleIndex = index

      const data = {
        id: '-3',
        remoteId: '-3',
        remoteName: '推送',
        remotePic: 'https://cloud.lantin.me/pro/icon/%E6%8E%A8%E9%80%81.png',
        content: ''
      }
      this.form.sceneConditions[this.selectModuleIndex].sceneContents.push(data)
    },

    // 删除设备
    deleteDevice(row, index, $index) {
      this.selectModuleIndex = index
      this.form.sceneConditions[this.selectModuleIndex].sceneContents.splice($index, 1)
    },

    // 取消编辑，返回上一级
    handleCancel() {
      this.$router.back()
    },

    // 保存
    async handleSave() {
      this.$refs.form1.validate(async (valid) => {
        if (!valid) return

        if (this.form.type === 0) {
          for (var i = 0; i < this.form.sceneConditions.length; i++) {
            this.$refs[`form2_${i}`][0].validate(async (valid) => {
              if (!valid) return

              if (this.form.sceneConditions.length === i + 1) {
                const res = await this.$api.automation.editScene(this.form)
                if (res.code === '00000') {
                  this.$message.success('添加成功！')
                  this.$router.back()
                }
              }
            })
          }
        } else if (this.form.type === 1) {
          this.$refs.form2_0[0].validate(async (valid) => {
            if (!valid) return

            const data = JSON.parse(JSON.stringify(this.form))
            data.sceneContents = []

            this.form.sceneConditions[0].sceneContents.forEach((item, index) => {
              data.sceneContents.push(JSON.parse(JSON.stringify(item)))
            })

            const res = await this.$api.automation.editScene(data)
            if (res.code === '00000') {
              this.$message.success('添加成功！')
              this.$router.back()
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  .el-card {
    .el-form {
      .el-form-item {
        width: 600px;
        .el-select {
          // width: 100%;
        }
        .product-img {
          width: 100px;
          height: 100px;
        }
        .item-row {
          display: flex;
          align-items: center;
          width: 1000px;
          .el-form-item {
            width: auto;
          }
          .ml20 {
            margin-left: 20px;
          }
        }
        .el-table {
          .el-image {
            width: 60px;
            height: 60px;
          }
          .status-setting {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .status-setting-item {
              // margin-right: 20px;
              // &:last-child {
              //   margin-right: 0;
              // }
              span {
                margin-right: 10px;
              }
              .el-select {
                width: 180px;
              }
            }
          }

          .push-setting {
            display: block;
          }
        }
      }
      .editor {
        width: 600px;
        .upload {
          width: 500px;
          margin-top: 10px;
        }
      }
    }

    .btn-box {
      display: flex;
      justify-content: space-around;
      width: 600px;
      margin: 40px 0 20px;
    }

    .sceneType {
      display: flex;
      justify-content: space-around;
      width: 400px;
      margin-bottom: 20px;
    }

    .controlModule-wrapper {
      margin-bottom: 10px;

      /deep/ .el-card__body {
        padding: 16px 20px;
      }
    }
  }

  /deep/ .selectDeviceDialog {
    .selectDeviceDialog_body {
      display: flex;
      flex-wrap: wrap;
      overflow-y: auto;
      max-height: 70vh;

      .device-item {
        display: flex;
        // align-items: center;
        margin: 0 20px 20px 0;
        cursor: pointer;

        .el-card__body {
          display: flex;
          align-items: center;
          width: 250px;
          padding: 10px;

          .el-image {
            width: 50px;
          }
          .name {
            margin-left: 10px;
            // font-weight: 700;
          }
        }
      }
    }
  }
}
</style>
