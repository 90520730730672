var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view"},[_c('el-card',[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('div',[_vm._v("设备测试历史记录")])]),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"productListTable",attrs:{"data":_vm.list,"border":""}},[_c('el-table-column',{attrs:{"label":"序号","type":"index","width":"50"}}),_c('el-table-column',{attrs:{"label":"温湿度"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.successSteps.includes('1'))?_c('div',{staticClass:"state-wrapper"},[_c('div',{staticClass:"state-dot success-dot"}),_c('div',[_vm._v("合格")])]):(row.errorSteps.includes('1'))?_c('div',{staticClass:"state-wrapper"},[_c('div',{staticClass:"state-dot danger-dot"}),_c('div',[_vm._v("不合格")])]):_c('div',{staticClass:"state-wrapper"},[_c('div',{staticClass:"state-dot info-dot"}),_c('div',[_vm._v("未完成")])])]}}])}),_c('el-table-column',{attrs:{"label":"触摸按键"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.successSteps.includes('2'))?_c('div',{staticClass:"state-wrapper"},[_c('div',{staticClass:"state-dot success-dot"}),_c('div',[_vm._v("合格")])]):(row.errorSteps.includes('2'))?_c('div',{staticClass:"state-wrapper"},[_c('div',{staticClass:"state-dot danger-dot"}),_c('div',[_vm._v("不合格")])]):_c('div',{staticClass:"state-wrapper"},[_c('div',{staticClass:"state-dot info-dot"}),_c('div',[_vm._v("未完成")])])]}}])}),_c('el-table-column',{attrs:{"label":"配网按键"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.successSteps.includes('3'))?_c('div',{staticClass:"state-wrapper"},[_c('div',{staticClass:"state-dot success-dot"}),_c('div',[_vm._v("合格")])]):(row.errorSteps.includes('3'))?_c('div',{staticClass:"state-wrapper"},[_c('div',{staticClass:"state-dot danger-dot"}),_c('div',[_vm._v("不合格")])]):_c('div',{staticClass:"state-wrapper"},[_c('div',{staticClass:"state-dot info-dot"}),_c('div',[_vm._v("未完成")])])]}}])}),_c('el-table-column',{attrs:{"label":"红外"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.successSteps.includes('4'))?_c('div',{staticClass:"state-wrapper"},[_c('div',{staticClass:"state-dot success-dot"}),_c('div',[_vm._v("合格")])]):(row.errorSteps.includes('4'))?_c('div',{staticClass:"state-wrapper"},[_c('div',{staticClass:"state-dot danger-dot"}),_c('div',[_vm._v("不合格")])]):_c('div',{staticClass:"state-wrapper"},[_c('div',{staticClass:"state-dot info-dot"}),_c('div',[_vm._v("未完成")])])]}}])}),_c('el-table-column',{attrs:{"label":"zigbee入网"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.successSteps.includes('5'))?_c('div',{staticClass:"state-wrapper"},[_c('div',{staticClass:"state-dot success-dot"}),_c('div',[_vm._v("合格")])]):(row.errorSteps.includes('5'))?_c('div',{staticClass:"state-wrapper"},[_c('div',{staticClass:"state-dot danger-dot"}),_c('div',[_vm._v("不合格")])]):_c('div',{staticClass:"state-wrapper"},[_c('div',{staticClass:"state-dot info-dot"}),_c('div',[_vm._v("未完成")])])]}}])}),_c('el-table-column',{attrs:{"label":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.result == 0)?_c('div',{staticClass:"state-wrapper"},[_c('div',{staticClass:"state-dot info-dot"}),_c('div',[_vm._v("未完成")])]):(row.result == 1)?_c('div',{staticClass:"state-wrapper"},[_c('div',{staticClass:"state-dot success-dot"}),_c('div',[_vm._v("合格")])]):(row.result == 2)?_c('div',{staticClass:"state-wrapper"},[_c('div',{staticClass:"state-dot danger-dot"}),_c('div',[_vm._v("不合格")])]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"测试时间","prop":"gmtCreate"}})],1),_c('PaginationBar',{attrs:{"pageNo":_vm.pagination.pageNum,"pageSize":_vm.pagination.pageSize,"total":_vm.pagination.total},on:{"update:pageNo":function($event){return _vm.$set(_vm.pagination, "pageNum", $event)},"update:page-no":function($event){return _vm.$set(_vm.pagination, "pageNum", $event)},"update:pageSize":function($event){return _vm.$set(_vm.pagination, "pageSize", $event)},"update:page-size":function($event){return _vm.$set(_vm.pagination, "pageSize", $event)},"change":function($event){return _vm.getList()}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }