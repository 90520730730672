<template>
  <div class="page">Home</div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {}
  },

  computed: {},

  watch: {},

  created() {},
  mounted() {},

  methods: {},

  destroyed() {}
}
</script>

<style scoped lang="scss">
.page {
}
</style>
