<template>
  <div class="view">
    <div class="left">
      <div class="header">
        <div class="title">{{ title }}</div>
        <div class="btn" v-if="serialNumber && showSearchBtn">
          <el-button type="primary" size="medium" :disabled="searchBtnDisabled" @click="handleSearch()">
            {{ searchBtnText }}
          </el-button>
        </div>
      </div>

      <div class="content">
        <slot name="content"></slot>
      </div>
    </div>

    <div class="middle">
      <slot name="middle"></slot>
    </div>

    <div class="right">
      <el-button v-if="detail.controlId" type="danger" size="medium" @click="handleDelete()">移除</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {
    title: {
      type: String,
      required: true
    },
    showSearchBtn: {
      type: Boolean,
      default: false
    },
    serialNumber: {
      type: String,
      default: ''
    },
    remoteId: {
      type: [Number, String],
      default: null
    }
  },

  data() {
    return {
      token: '',
      searchBtnText: '搜 索',
      searchBtnDisabled: false,
      countdownSecond: 60,
      countdownTimer: null,
      pollingTimer: null,
      detail: {}
    }
  },

  filters: {},
  computed: {},
  watch: {},

  created() {},

  methods: {
    // 搜索
    async handleSearch() {
      this.countdown()

      const res = await this.$api.packageApi.getTokenOfSearchSubDevice({
        serial: this.serialNumber,
        remoteId: this.remoteId
      })

      if (res.code === '00000') {
        this.token = res.data
        this.polling()
      }
    },

    // 倒计时
    countdown() {
      this.countdownSecond = 60

      this.searchBtnDisabled = true
      this.searchBtnText = '搜索中(' + this.countdownSecond + ')'

      this.countdownTimer = setInterval(() => {
        this.countdownSecond--
        this.searchBtnText = '搜索中(' + this.countdownSecond + ')'
        if (this.countdownSecond === 0) {
          clearInterval(this.countdownTimer)
          this.searchBtnDisabled = false
          this.searchBtnText = '搜 索'
        }
      }, 1000)
    },

    // 轮询子设备
    polling() {
      this.pollingTimer = setInterval(async () => {
        if (this.countdownSecond !== 0) {
          const res = await this.$api.packageApi.searchSubDeviceOfCreateCombo(this.token)
          if (res.code === '00000') {
            this.detail = res.data
            this.$emit('change', this.detail)
            this.$emit('update:showSearchBtn', false)
            this.countdownSecond = 0
            this.searchBtnDisabled = false
            this.searchBtnText = '搜 索'
            clearInterval(this.countdownTimer)
            clearInterval(this.pollingTimer)
          } else if (res.code === 'D0330') {
            this.$message.error(res.message)
          }
        } else {
          clearInterval(this.pollingTimer)
        }
      }, 5000)
    },

    // 移除子设备
    async handleDelete() {
      const res = await this.$api.packageApi.deleteSubDeviceOfCreateCombo(this.detail.deviceRemoteId)

      if (res.code === '00000') {
        this.detail = {}
        this.$emit('change', this.detail)
        this.$emit('update:showSearchBtn', true)
        this.$message.success('操作成功！')
      }
    },

    // 主设备改变时执行
    onMainChange() {
      this.detail = {}
      this.$emit('change', this.detail)
      this.$emit('update:showSearchBtn', true)
      this.countdownSecond = 0
      this.searchBtnText = '搜 索'
      this.searchBtnDisabled = false
      clearInterval(this.countdownTimer)
      clearInterval(this.pollingTimer)
    }
  }
}
</script>

<style lang="scss" scoped>
.view {
  display: flex;

  .left {
    flex-shrink: 0;
    width: 400px;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 36px;

      .title {
        margin-left: 6px;
        font-size: 20px;
      }

      .btn {
      }
    }

    .content {
      margin-top: 10px;
    }
  }

  .middle {
    flex-shrink: 0;
    width: 400px;
    margin-left: 20px;
  }

  .right {
    flex-shrink: 0;
    margin-left: 20px;
  }
}
</style>
