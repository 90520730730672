<template>
  <div class="side-menu">
    <div class="logo">
      <span>智能设备管理</span>
    </div>

    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :unique-opened="false"
        mode="vertical"
        background-color="transparent"
        text-color="#fff"
        active-text-color="#fff"
      >
        <sidebar-item v-for="route in routes" :key="route.path" :item="route" :base-path="route.path" />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import SidebarItem from './SidebarItem'

export default {
  components: { SidebarItem },
  computed: {
    routes() {
      return this.$router.options.routes
    },
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    }
  }
}
</script>

<style scoped lang="scss">
.side-menu {
  height: 100%;

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    font-size: 24px;
    color: #fff;
  }

  /deep/ .el-scrollbar {
    height: calc(100% - 60px - 20px);
    padding-bottom: 20px;

    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }

  /deep/ .el-menu {
    margin-right: -1px;

    .first-level-menu {
    }

    .el-submenu {
      .el-submenu__title {
        margin: 6px;
        border-radius: 5px;

        .el-submenu__icon-arrow {
          font-size: 18px;
          color: #fff;
        }
      }

      .el-submenu__title:hover {
        background-color: #5470c6 !important;
      }

      .el-menu-item {
        margin: 6px;
        margin-left: 16px;
        border-radius: 5px;
      }
    }

    .el-menu-item {
      margin: 6px;
      border-radius: 5px;
    }

    .el-menu-item:hover {
      background-color: #5470c6 !important;
    }

    .el-menu-item.is-active {
      background-color: #5470c6 !important;
    }
  }
}
</style>
