<template>
  <div class="page">
    <el-card>
      <div slot="header">
        <span>产品信息</span>
      </div>

      <!-- 表单区域 -->
      <el-form ref="form" :model="form" label-width="auto">
        <el-form-item label="产品图片">
          <el-image :src="form.basePic" class="product-img"></el-image>
        </el-form-item>

        <el-form-item label="产品名称">
          <el-input v-model="form.baseName" disabled></el-input>
        </el-form-item>

        <el-form-item label="产品型号">
          <el-col :span="10">
            <el-input v-model="form.type" disabled></el-input>
          </el-col>
          <el-col class="line" :span="4" style="text-align: right">产品类目&nbsp;&nbsp;&nbsp;</el-col>
          <el-col :span="10">
            <el-input v-model="form.baseName" disabled></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="产品颜色">
          <el-col :span="10">
            <el-input v-model="form.color" disabled></el-input>
          </el-col>
          <el-col class="line" :span="4" style="text-align: right">产品重量&nbsp;&nbsp;&nbsp;</el-col>
          <el-col :span="10">
            <el-input v-model="form.weight" disabled></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="产品规格">
          <el-input v-model="form.size" disabled></el-input>
        </el-form-item>

        <el-form-item label="连接方式" prop="linkWay">
          <!-- <el-select v-model="form.linkWay" placeholder="请选择连接方式" multiple>
            <el-option v-for="item in linkWays" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select> -->
          <el-input v-model="form.linkWayStr" disabled></el-input>
        </el-form-item>

        <el-form-item label="创建时间">
          <el-col :span="10">
            <el-input v-model="form.gmtCreate" disabled></el-input>
          </el-col>
          <el-col class="line" :span="4" style="text-align: right">更新时间&nbsp;&nbsp;&nbsp;</el-col>
          <el-col :span="10">
            <el-input v-model="form.gmtModified" disabled></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="说明书" class="editor">
          <Editor v-model="form.specification"></Editor>
        </el-form-item>
      </el-form>

      <div class="btn-box">
        <el-button @click="back()">返 回</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import { linkWays } from '@/utils/data'

export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      proNo: '',
      form: {
        baseName: '',
        basePic: null,
        linkWay: null,
        gmtCreate: null,
        gmtModified: null,
        specification: null,
        specName: null,
        specUrl: null
      },

      linkWays
    }
  },

  filters: {},

  computed: {},

  watch: {},

  created() {
    this.proNo = this.$route.query.proNo
    this.getDetail()
  },

  methods: {
    // 获取产品详情
    async getDetail() {
      const res = await this.$api.productFactory.getDetail(this.proNo)

      if (res.code === '00000') {
        if (res.data.linkWay) {
          res.data.linkWay = res.data.linkWay.split(',')
        }

        this.form = res.data
        this.formatLinkWay()
      }
    },

    // linkWay 转字符串
    formatLinkWay() {
      let str = ''
      this.form.linkWay.forEach((item, index) => {
        const obj = linkWays.find((item2) => item2.value === item)
        str += obj ? obj.label : '未知'
        if (index + 1 === this.form.linkWay.length) {
        } else {
          str += '、'
        }
      })
      this.form.linkWayStr = str
    },

    // 返回上一级
    back() {
      this.$router.back()
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  .el-card {
    .el-form {
      .el-form-item {
        width: 800px;
        .el-select {
          width: 100%;
        }
        .product-img {
          width: 100px;
          height: 100px;
        }
      }
      .editor {
        width: 600px;
        .upload {
          width: 500px;
          margin-top: 10px;
        }
      }
    }

    .btn-box {
      display: flex;
      justify-content: space-around;
      width: 800px;
      margin: 40px 0 20px;
    }
  }
}
</style>
