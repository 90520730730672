<template>
  <div class="page">
    <!-- 主体区域 -->
    <el-card>
      <!-- 筛选、操作区域 -->
      <OperationBar>
        <template slot="search-box">
          <el-form :model="queryForm" :inline="true">
            <el-form-item label="关键词">
              <el-input
                v-model="queryForm.search"
                placeholder="请输入关键词"
                clearable
                @keyup.enter.native="handleSearch()"
              ></el-input>
            </el-form-item>
            <el-form-item label="状态">
              <el-select v-model="queryForm.status" placeholder="请选择订单状态" clearable @change="handleSearch()">
                <el-option
                  v-for="item in orderStatus"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="下单时间">
              <el-date-picker
                v-model="queryDate"
                type="daterange"
                range-separator="至"
                value-format="yyyy-MM-dd"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="dateChange"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="medium" @click="handleSearch()">查询</el-button>
            </el-form-item>
          </el-form>
        </template>
      </OperationBar>

      <!-- 表格 -->
      <el-table ref="productListTable" :data="list" v-loading="loading" border>
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column label="订单编号" prop="orderNo"></el-table-column>
        <el-table-column label="产品名称" prop="proName"></el-table-column>
        <el-table-column label="产品型号" prop="type"></el-table-column>
        <el-table-column label="订单数量" prop="num"></el-table-column>
        <el-table-column label="已入库设备" prop="numed"></el-table-column>
        <el-table-column label="下单时间" prop="gmtCreate"></el-table-column>

        <el-table-column label="订单状态">
          <template slot-scope="{ row }">
            <span>{{ row.status | orderStatusFilter }}</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="150">
          <div slot-scope="{ row, $index }" class="action-cell">
            <ConfirmPopover
              v-if="row.status == 0"
              :index="$index"
              text="取消"
              @confirm="cancelOrder(row)"
            ></ConfirmPopover>
            <el-button v-if="[0, 5].includes(row.status)" type="primary" round size="mini" @click="putStorage(row)">
              入库
            </el-button>
            <el-button type="primary" round size="mini" @click="checkDetail(row)">详情</el-button>
          </div>
        </el-table-column>
      </el-table>

      <!-- 分页器 -->
      <PaginationBar
        :pageNo.sync="pagination.pageNum"
        :pageSize.sync="pagination.pageSize"
        :total="pagination.total"
        @change="getList()"
      ></PaginationBar>
    </el-card>

    <!-- 设备入库弹框 -->
    <el-dialog title="设备入库" :visible.sync="putStorageDialog" width="600px" @closed="clearContents()">
      <el-input v-model="serialNo" clearable @keyup.enter.native="confirmPutStorage()"></el-input>

      <div class="putStorageDialog-wrapper">
        <div class="header">
          <div class="title">入库记录</div>
          <div class="num">已入库数：{{ putStorageList.length }}</div>
        </div>

        <el-table ref="putStorageListTable" :data="putStorageList" border>
          <el-table-column label="包装序列号">
            <template slot-scope="{ row }">
              <span>{{ row }}</span>
            </template>
          </el-table-column>
          <el-table-column label="状态" width="120">已入库</el-table-column>
        </el-table>
      </div>

      <span slot="footer">
        <el-button @click="finishPutStorage()">结束入库</el-button>
      </span>
    </el-dialog>

    <!-- 结束入库弹窗 -->
    <el-dialog title="" :visible.sync="finishPutStorageDialog" width="600px">
      <el-result icon="warning" title="是否结束入库" subTitle="还未完成订单生产数量要求，是否结束入库？"></el-result>
      <span slot="footer">
        <el-button @click="finishPutStorageDialog = false">取 消</el-button>
        <el-button type="primary" @click="confirmFinish()">结 束</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { orderStatus } from '@/utils/data'

export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      query: {},
      queryForm: {
        search: '',
        startDate: '',
        endDate: '',
        status: null
      },
      orderStatus,
      queryDate: '',
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      loading: false,
      list: [],

      orderNo: '',
      orderNum: null,
      putStorageDialog: false,
      serialNo: '',
      putStorageList: [],
      finishPutStorageDialog: false
    }
  },

  filters: {},

  computed: {},

  watch: {},

  created() {
    this.init()
    this.getList()
  },

  methods: {
    init() {},

    // 获取列表
    async getList() {
      this.loading = true
      const res = await this.$api.order.getList({ ...this.query, ...this.pagination })
      this.loading = false
      if (res.code === '00000') {
        this.pagination.total = res.total
        this.list = res.data
      }
    },

    // 日期改变
    dateChange(e) {
      if (e) {
        this.queryForm.startDate = e[0]
        this.queryForm.endDate = e[1]
      } else {
        this.queryForm.startDate = ''
        this.queryForm.endDate = ''
      }

      this.handleSearch()
    },

    // 搜索事件
    handleSearch() {
      this.query = { ...this.queryForm }
      this.pagination.pageNum = 1
      this.getList()
    },

    // 取消订单
    async cancelOrder(row) {
      const res = await this.$api.order.cancelOrder(row.id)

      if (res.code === '00000') {
        this.$message.success('操作成功！')
        this.getList()
      }
    },

    // 关闭弹窗时清除表单
    clearContents() {
      this.serialNo = ''
    },

    // 设备入库
    putStorage(row) {
      this.orderNo = row.orderNo
      this.orderNum = row.num
      this.putStorageDialog = true
      this.getPutStorageList()
    },

    // 获取已入库列表
    async getPutStorageList() {
      const res = await this.$api.storage.getPutStorageList(this.orderNo)

      if (res.code === '00000') {
        this.putStorageList = res.data
      }
    },

    // 确认入库，调用接口
    async confirmPutStorage() {
      if (!this.serialNo) return

      const res = await this.$api.storage.putStorage({
        orderNo: this.orderNo,
        serialNo: this.serialNo
      })

      if (res.code === '00000') {
        this.$message.success('入库成功！')
        this.serialNo = ''
        this.getPutStorageList()
      }
    },

    // 结束入库
    finishPutStorage() {
      if (this.orderNum > this.putStorageList.length) {
        this.finishPutStorageDialog = true
      } else {
        this.putStorageDialog = false
      }
    },

    // 确认结束
    confirmFinish() {
      this.putStorageDialog = false
      this.finishPutStorageDialog = false
    },

    // 查看详情
    checkDetail(row) {
      localStorage.setItem('orderDetail', JSON.stringify(row))
      this.$router.push({ path: 'detail' })
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  .putStorageDialog-wrapper {
    margin-top: 20px;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .el-table {
      margin-top: 10px;
    }
  }
}
</style>
