<template>
  <div class="page">
    <!-- 主体区域 -->
    <el-card>
      <!-- 筛选、操作区域 -->
      <OperationBar>
        <template slot="search-box">
          <el-form :model="queryForm" :inline="true">
            <el-form-item label="名称">
              <el-input
                v-model="queryForm.nickname"
                placeholder="请输入管理员名称"
                clearable
                @keyup.enter.native="handleSearch()"
              ></el-input>
            </el-form-item>
            <el-form-item label="手机号">
              <el-input
                v-model="queryForm.mobile"
                placeholder="请输入管理员手机号"
                clearable
                @keyup.enter.native="handleSearch()"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="medium" @click="handleSearch()">查询</el-button>
            </el-form-item>
          </el-form>
        </template>

        <template slot="btn-box">
          <el-button type="primary" size="medium" round @click="jumpDetailPage()">新建管理员</el-button>
        </template>
      </OperationBar>

      <!-- 表格 -->
      <el-table ref="productListTable" :data="list" v-loading="loading" border>
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column label="管理员名称" prop="nickname"></el-table-column>
        <el-table-column label="管理员账号" prop="username"></el-table-column>
        <el-table-column label="账号角色" prop="roleNames"></el-table-column>

        <el-table-column label="操作" width="400">
          <div slot-scope="{ row, $index }" class="action-cell">
            <el-button type="primary" round size="mini" @click="jumpDetailPage(row.id)">查看详情</el-button>

            <ConfirmPopover :index="$index" text="重置" @confirm="handleReset(row)"></ConfirmPopover>

            <ConfirmPopover
              v-if="row.status == 1"
              :index="$index"
              text="禁用"
              @confirm="handleBan(row)"
            ></ConfirmPopover>

            <el-button v-else type="primary" round size="mini" @click="handleBan(row)">启用</el-button>

            <ConfirmPopover :index="$index" @confirm="handleDelete(row)"></ConfirmPopover>
          </div>
        </el-table-column>
      </el-table>

      <!-- 分页器 -->
      <PaginationBar
        :pageNo.sync="pagination.pageNum"
        :pageSize.sync="pagination.pageSize"
        :total="pagination.total"
        @change="getList()"
      ></PaginationBar>
    </el-card>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      query: { isAdmin: 1 },
      queryForm: {
        nickname: '',
        mobile: '',
        isAdmin: 1
      },
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },

      loading: false,

      list: []
    }
  },

  computed: {},

  watch: {},

  created() {
    this.getList()
  },

  methods: {
    // 获取列表
    async getList() {
      this.loading = true
      const res = await this.$api.account.getList({ ...this.query, ...this.pagination })
      this.loading = false

      if (res.code === '00000') {
        this.list = res.data
        this.pagination.total = res.total
      }
    },

    // 搜索事件
    handleSearch() {
      this.query = { ...this.queryForm }
      this.pagination.pageNum = 1
      this.getList()
    },

    // 跳转详情页面
    jumpDetailPage(id) {
      this.$router.push({ path: 'administratorDetail', query: { id } })
    },

    // 重置
    async handleReset(row) {
      const res = await this.$api.account.resetPassword(row.id)

      if (res.code === '00000') {
        this.$message.success('操作成功！')
        // this.getList()
      }
    },

    // 禁用
    async handleBan(row) {
      const res = await this.$api.account.banAccount(row.id)

      if (res.code === '00000') {
        this.$message.success('操作成功！')
        this.getList()
      }
    },

    // 删除
    async handleDelete(row) {
      const res = await this.$api.account.deleteAccount(row.id)

      if (res.code === '00000') {
        this.$message.success('操作成功！')
        this.getList()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  .el-card {
    width: 1200px;
  }
}
</style>
