<template>
  <div class="page">
    <!-- 主体区域 -->
    <el-card>
      <!-- 筛选、操作区域 -->
      <OperationBar>
        <template slot="search-box">
          <el-form :model="queryForm" :inline="true">
            <el-form-item label="序列号">
              <el-input
                v-model="queryForm.search"
                placeholder="请输入设备序列号"
                clearable
                @keyup.enter.native="handleSearch()"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="medium" @click="handleSearch()">查询</el-button>
            </el-form-item>
          </el-form>
        </template>

        <template slot="btn-box">
          <el-button type="primary" size="medium" round @click="handleCreate()">录入</el-button>
        </template>
      </OperationBar>

      <!-- 表格 -->
      <el-table ref="productListTable" :data="formatList" v-loading="loading" border>
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column :label="detail.product.proName" prop="serialNumber"></el-table-column>

        <el-table-column
          v-for="(item, index) in subProNameList"
          :key="index"
          :label="item"
          :prop="item"
        ></el-table-column>

        <el-table-column label="录入时间" prop="gmtCreate"></el-table-column>
      </el-table>

      <!-- 分页器 -->
      <PaginationBar
        :pageNo.sync="pagination.pageNum"
        :pageSize.sync="pagination.pageSize"
        :total="total"
        @change="getList()"
      ></PaginationBar>
    </el-card>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      id: null,
      query: {},
      queryForm: {
        search: ''
      },
      pagination: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      loading: false,
      detail: {
        product: {}
      },
      subProNameList: [],
      list: [],
      formatList: []
    }
  },

  filters: {},

  computed: {},

  watch: {},

  created() {
    this.id = this.$route.query.id
    this.getDetail()
    this.getList()
  },

  methods: {
    // 获取详情
    async getDetail() {
      const res = await this.$api.packageApi.getDetail(this.id)

      if (res.code === '00000') {
        this.detail = res.data
      }
    },

    // 获取列表
    async getList() {
      this.loading = true
      const res = await this.$api.packageApi.getComboList({ packageId: this.id, ...this.query, ...this.pagination })
      this.loading = false

      if (res.code === '00000') {
        this.subProNameList = res.data.subProNames
        this.list = res.data.list
        this.formatList = JSON.parse(JSON.stringify(this.list))
        this.total = res.total

        this.list.forEach((subPro, index1) => {
          subPro.subAddrList.forEach((value, index2) => {
            this.formatList[index1][this.subProNameList[index2]] = value
          })
        })
      }
    },

    // 搜索事件
    handleSearch() {
      this.query = { ...this.queryForm }
      this.pagination.pageNum = 1
      this.getList()
    },

    // 新建
    handleCreate() {
      this.$router.push({ path: 'createCombo', query: { id: this.id } })
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  .el-card {
    .el-table {
    }
  }
}
</style>
