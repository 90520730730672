<template>
  <div class="page">
    <el-card>
      <div class="title">登录</div>

      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item prop="username">
          <el-input
            v-model.trim="form.username"
            placeholder="请输入账号"
            clearable
            prefix-icon="el-icon-user-solid"
          ></el-input>
        </el-form-item>

        <el-form-item prop="password">
          <el-input
            type="password"
            v-model.trim="form.password"
            placeholder="请输入密码"
            clearable
            prefix-icon="el-icon-lock"
          ></el-input>
        </el-form-item>

        <el-form-item prop="validateCode">
          <div class="validateCode">
            <el-input
              v-model.trim="form.validateCode"
              placeholder="请输入验证码"
              clearable
              prefix-icon="el-icon-key"
              @keyup.enter.native="handleLogin()"
            ></el-input>
            <el-image :src="`data:image/jpeg;base64, ${validateCode.img}`" @click="getValidateCode()"></el-image>
          </div>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" :loading="loginLoading" style="width: 100%" @click="handleLogin()">登 录</el-button>
        </el-form-item>
      </el-form>

      <div class="action">
        <!-- <el-checkbox v-model="rememberPassword">记住密码</el-checkbox> -->
        <!-- <el-link type="primary" @click="jumpPage()">注册 ></el-link> -->
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      validateCode: {
        uuid: '',
        img: ''
      },

      form: {
        username: null,
        password: null,
        grant_type: 'captcha',
        validateCode: null
      },

      rules: {
        username: [{ required: true, message: '请输入账号', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        validateCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      },

      loginLoading: false,
      rememberPassword: false
    }
  },

  computed: {},

  watch: {},

  created() {
    this.getValidateCode()
  },

  methods: {
    // 获取验证码
    async getValidateCode() {
      const res = await this.$api.login.getValidateCode()

      if (res.code === '00000') {
        this.validateCode = res.data
      }
    },

    // 登录
    handleLogin() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return

        this.loginLoading = true
        const res = await this.$api.login.login({ ...this.form, uuid: this.validateCode.uuid })

        if (res.code === '00000') {
          localStorage.setItem('token', res.data.token_type + ' ' + res.data.access_token)
          localStorage.setItem('refresh_token', res.data.refresh_token)

          this.$message.success('登录成功！')
          this.$router.push('/')
        } else {
          this.getValidateCode()
        }

        this.loginLoading = false
      })
    },

    // 跳转注册页
    jumpPage() {
      this.$router.push('/register')
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #2d3a4b;

  .el-card {
    width: 400px;
    height: 500px;

    .title {
      margin: 40px 0 50px;
      font-size: 30px;
    }

    .el-form {
      .el-form-item {
        .validateCode {
          display: flex;
          justify-content: space-between;

          .el-image {
            margin-left: 10px;
          }
        }
      }
    }

    .action {
      // margin-top: 20px;
      text-align: right;
    }
  }
}
</style>
