<template>
  <div class="page">
    <!-- 主体区域 -->
    <el-card>
      <!-- 筛选、操作区域 -->
      <OperationBar>
        <template slot="search-box">
          <!-- <div style="font-size: 20px">待测试设备（共{{ pagination.total }}）</div> -->

          <el-form :model="queryForm" :inline="true">
            <el-form-item label="关键词">
              <el-input
                v-model="queryForm.search"
                placeholder="请输入关键词"
                clearable
                @keyup.enter.native="handleSearch()"
              ></el-input>
            </el-form-item>
            <el-form-item label="设备状态">
              <el-select v-model="queryForm.status" placeholder="请选择设备状态" clearable @change="handleSearch()">
                <el-option label="未完成" value="0"></el-option>
                <el-option label="合格" value="1"></el-option>
                <el-option label="返修" value="100"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="medium" @click="handleSearch()">查询</el-button>
            </el-form-item>
          </el-form>
        </template>

        <template slot="btn-box">
          <el-button type="primary" size="medium" round @click="refresh()">
            <i class="el-icon-refresh"></i> 刷新
          </el-button>
        </template>
      </OperationBar>

      <!-- 表格 -->
      <el-table ref="productListTable" :data="list" v-loading="loading" border>
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column label="设备编号" prop="serialNumber" width="150"></el-table-column>

        <el-table-column label="连接状态">
          <template slot-scope="{ row }">
            <div v-if="row.isOnline == 1" class="state-wrapper">
              <div class="state-dot success-dot"></div>
              <div>在线</div>
            </div>
            <div v-if="row.isOnline == 0" class="state-wrapper">
              <div class="state-dot danger-dot"></div>
              <div>离线</div>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="温度(℃)" prop="temperature"></el-table-column>
        <el-table-column label="湿度(%)" prop="humidity"></el-table-column>
        <!-- <el-table-column label="电量" prop="voltage"></el-table-column> -->

        <el-table-column label="温湿度">
          <!-- <div slot-scope="{ row }" class="action-cell">
            <el-button
              type="primary"
              round
              size="mini"
              :disabled="row.humitureResult == 1"
              @click="testDeviceTEMP(row, 1)"
            >
              合格
            </el-button>
            <el-button
              type="primary"
              round
              size="mini"
              :disabled="row.humitureResult == 2"
              @click="testDeviceTEMP(row, 2)"
            >
              不合格
            </el-button>
          </div> -->
          <template slot-scope="{ row }">
            <div v-if="row.successSteps.includes('1')" class="state-wrapper">
              <div class="state-dot success-dot"></div>
              <div>合格</div>
            </div>
            <div v-else-if="row.errorSteps.includes('1')" class="state-wrapper">
              <div class="state-dot danger-dot"></div>
              <div>不合格</div>
            </div>
            <div v-else class="state-wrapper">
              <div class="state-dot info-dot"></div>
              <div>未完成</div>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="触摸按键">
          <template slot-scope="{ row }">
            <div v-if="row.successSteps.includes('2')" class="state-wrapper">
              <div class="state-dot success-dot"></div>
              <div>合格</div>
            </div>
            <div v-else-if="row.errorSteps.includes('2')" class="state-wrapper">
              <div class="state-dot danger-dot"></div>
              <div>不合格</div>
            </div>
            <div v-else class="state-wrapper">
              <div class="state-dot info-dot"></div>
              <div>未完成</div>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="配网按键">
          <template slot-scope="{ row }">
            <div v-if="row.successSteps.includes('3')" class="state-wrapper">
              <div class="state-dot success-dot"></div>
              <div>合格</div>
            </div>
            <div v-else-if="row.errorSteps.includes('3')" class="state-wrapper">
              <div class="state-dot danger-dot"></div>
              <div>不合格</div>
            </div>
            <div v-else class="state-wrapper">
              <div class="state-dot info-dot"></div>
              <div>未完成</div>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="红外">
          <template slot-scope="{ row }">
            <div v-if="row.successSteps.includes('4')" class="state-wrapper">
              <div class="state-dot success-dot"></div>
              <div>合格</div>
            </div>
            <div v-else-if="row.errorSteps.includes('4')" class="state-wrapper">
              <div class="state-dot danger-dot"></div>
              <div>不合格</div>
            </div>
            <div v-else class="state-wrapper">
              <div class="state-dot info-dot"></div>
              <div>未完成</div>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="zigbee入网">
          <template slot-scope="{ row }">
            <div v-if="row.successSteps.includes('5')" class="state-wrapper">
              <div class="state-dot success-dot"></div>
              <div>合格</div>
            </div>
            <div v-else-if="row.errorSteps.includes('5')" class="state-wrapper">
              <div class="state-dot danger-dot"></div>
              <div>不合格</div>
            </div>
            <div v-else class="state-wrapper">
              <div class="state-dot info-dot"></div>
              <div>未完成</div>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="状态">
          <template slot-scope="{ row }">
            <div v-if="row.result == 0" class="state-wrapper">
              <div class="state-dot info-dot"></div>
              <div>未完成</div>
            </div>
            <div v-else-if="row.result == 1" class="state-wrapper">
              <div class="state-dot success-dot"></div>
              <div>合格</div>
            </div>
            <div v-else-if="row.result == 2" class="state-wrapper">
              <div class="state-dot danger-dot"></div>
              <div>不合格</div>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="120">
          <div slot-scope="{ row }" class="action-cell">
            <!-- <el-button
              v-if="row.result == 0 && row.humitureResult == 1"
              type="primary"
              round
              size="mini"
              @click="testDevice(row)"
            >
              测试
            </el-button>
            <el-button v-if="[1, 2].includes(row.result)" type="primary" round size="mini" @click="testDevice(row)">
              重新测试
            </el-button> -->

            <el-button type="primary" size="mini" round @click="sendControlSignal('light', row)">
              {{ lightState ? '开 灯' : '关 灯' }}
            </el-button>

            <el-button v-if="row.isRepair == 1" type="primary" round size="mini" @click="viewHistory(row)">
              查看历史记录
            </el-button>
          </div>
        </el-table-column>
      </el-table>

      <!-- 分页器 -->
      <PaginationBar
        :pageNo.sync="pagination.pageNum"
        :pageSize.sync="pagination.pageSize"
        :total="pagination.total"
        @change="getList()"
      ></PaginationBar>
    </el-card>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      query: {
        // status: 20
      },
      queryForm: {
        // status: 20,
        search: ''
      },
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      loading: false,
      list: [],

      lightState: true
    }
  },

  filters: {},

  computed: {},
  watch: {},

  created() {
    this.init()
    this.getList()
  },

  methods: {
    init() {},

    // 获取列表
    async getList() {
      this.loading = true
      const res = await this.$api.deviceFactory.getTestList({ ...this.query, ...this.pagination })
      this.loading = false
      if (res.code === '00000') {
        this.list = this.$utils.stringToArray(res.data, 'successSteps', ';')
        this.list = this.$utils.stringToArray(res.data, 'errorSteps', ';')
        this.pagination.total = res.total
      }
    },

    // 搜索事件
    handleSearch() {
      this.query = { ...this.queryForm }
      this.pagination.pageNum = 1
      this.getList()
    },

    // 刷新
    refresh() {
      this.pagination.pageNum = 1
      this.getList()
    },

    // 温湿度测试结果
    async testDeviceTEMP(row, value) {
      const res = await this.$api.deviceFactory.testDeviceTEMP({
        id: row.id,
        humitureResult: value
      })

      if (res.code === '00000') {
        row.humitureResult = value
        this.$message.success('操作成功！')
      }
    },

    // 发送信号
    async sendControlSignal(name, row) {
      const res = await this.$api.deviceFactory.sendControlSignal({
        serialNumber: row.serialNumber,
        command: name,
        isLight: this.lightState ? 1 : 0
      })

      if (res.code === '00000') {
        if (name === 'light') {
          this.lightState = !this.lightState
        }
      }
    },

    // 测试设备
    testDevice(row) {
      localStorage.setItem('testDeviceDetail', JSON.stringify(row))
      this.$router.push({ path: 'testDetail' })
    },

    // 查看历史记录
    viewHistory(row) {
      this.$router.push({ path: 'testHistory', query: { id: row.id } })
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  .state-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .state-dot {
      width: 10px;
      height: 10px;
      margin-right: 5px;
      border-radius: 5px;
    }

    .success-dot {
      background-color: #63e441;
    }

    .danger-dot {
      background-color: #f56c6c;
    }

    .info-dot {
      background-color: #909399;
    }
  }
}
</style>
