<template>
  <div class="lightState">
    <div class="title">灯光检测：</div>
    <div class="action">
      <el-radio-group v-model="value" @input="onInput">
        <el-radio :label="1">合格</el-radio>
        <el-radio :label="0">不合格</el-radio>
      </el-radio-group>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      value: null
    }
  },

  filters: {},
  computed: {},
  watch: {},

  created() {},

  methods: {
    // 选中值变化时触发
    onInput(value) {
      this.$emit('change', value)
    }
  }
}
</script>

<style lang="scss" scoped>
.lightState {
  display: flex;
  // align-items: center;
  margin-top: 16px;

  .title {
    font-size: 18px;
  }

  .action {
    margin-left: 20px;

    /deep/ .el-radio__label {
      font-size: 18px;
    }
  }
}
</style>
