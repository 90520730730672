<template>
  <div class="page">
    <!-- 筛选、操作区域 -->
    <OperationBar>
      <template slot="search-box">
        <el-form :model="queryForm" :inline="true">
          <el-form-item label="关键词">
            <el-input
              v-model="queryForm.keyword"
              placeholder="请输入关键词"
              clearable
              @keyup.enter.native="handleSearch()"
            ></el-input>
          </el-form-item>
          <el-form-item label="所属开发者">
            <el-input
              v-model="queryForm.developer"
              placeholder="请输入开发者名称"
              clearable
              @keyup.enter.native="handleSearch()"
            ></el-input>
          </el-form-item>
          <el-form-item label="通讯方式">
            <el-select v-model="queryForm.mode" clearable @change="handleSearch()">
              <el-option v-for="item in linkWays" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="medium" @click="handleSearch()">查询</el-button>
          </el-form-item>
        </el-form>
      </template>

      <template slot="btn-box">
        <!-- <el-button type="primary" size="medium" round @click="jumpDetailPage()">新建产品</el-button> -->
      </template>
    </OperationBar>

    <!-- 主体区域 -->
    <el-card>
      <!-- 表格 -->
      <el-table ref="productListTable" :data="list" v-loading="loading" border>
        <el-table-column label="序号" type="index" width="50"></el-table-column>

        <el-table-column label="产品信息" min-width="200">
          <div slot-scope="{ row }" class="product-info">
            <div class="left">
              <el-image :src="row.image"></el-image>
            </div>
            <div class="right">
              <div class="title">{{ row.name }}</div>
              <div class="id">{{ row.productID }}</div>
            </div>
          </div>
        </el-table-column>
        <el-table-column label="通讯方式">
          <template slot-scope="{ row }">
            <span v-for="(item, index) in row.mode" :key="item">
              {{ item | linkWayFilter }}{{ index + 1 == row.mode.length ? '' : '、' }}
            </span>
          </template>
        </el-table-column>

        <el-table-column label="所属开发者" prop="developer"></el-table-column>
        <el-table-column label="总数" prop="num"></el-table-column>
        <el-table-column label="激活量" prop="num2"></el-table-column>
        <el-table-column label="添加数量" prop="num3"></el-table-column>
        <el-table-column label="创建时间" prop="createTime"></el-table-column>

        <el-table-column label="操作" width="200">
          <div slot-scope="{ row }" class="action-cell">
            <el-button type="primary" round size="mini" @click="handlePass(row)">通过</el-button>
            <el-button type="danger" round size="mini" @click="handleReject(row)">驳回</el-button>
          </div>
        </el-table-column>
      </el-table>

      <!-- 分页器 -->
      <PaginationBar
        :pageNo.sync="pagination.pageNum"
        :pageSize.sync="pagination.pageSize"
        :total="pagination.total"
        @change="getList()"
      ></PaginationBar>
    </el-card>
  </div>
</template>

<script>
import { linkWays } from '@/utils/data'

export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      query: {},
      queryForm: {
        keyword: '',
        developer: '',
        mode: null
      },
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },

      linkWays,
      loading: false,

      list: [
        {
          id: 1,
          name: '111',
          productID: '123432414234543',
          image:
            'http://jiaotong.oss-cn-hangzhou.aliyuncs.com/JT_PICS/20220112/347ccc27-befd-4d93-8a28-391d50cfa365.jpeg',
          mode: [1],
          developer: 'developer',
          num: 9,
          num2: 999,
          num3: 999,
          createTime: '2022-03-30'
        },
        {
          id: 2,
          name: '222',
          productID: '123432414234543',
          image:
            'http://jiaotong.oss-cn-hangzhou.aliyuncs.com/JT_PICS/20220112/347ccc27-befd-4d93-8a28-391d50cfa365.jpeg',
          mode: [1, 2],
          developer: 'developer2',
          num: 9,
          num2: 999,
          num3: 999,
          createTime: '2022-03-30'
        }
      ]
    }
  },

  filters: {},

  computed: {},

  watch: {},

  created() {
    this.getList()
  },

  methods: {
    // 获取产品列表
    async getList() {
      this.loading = true
      const res = await this.$api.product.getLibraryList(this.query)
      this.loading = false

      if (res.code === '00000') {
      }
    },

    // 搜索事件
    handleSearch() {
      this.query = { ...this.queryForm }
      this.pagination.pageNum = 1
      this.getList()
    },

    // 跳转详情页面
    jumpDetailPage(id) {
      this.$router.push({ path: 'detail', query: { id } })
    },

    // 通过
    handlePass(row) {},

    // 驳回
    handleReject(row) {}
  }
}
</script>

<style scoped lang="scss">
.page {
  .el-card {
    .el-table {
      .product-info {
        display: flex;
        align-items: center;

        .left {
          .el-image {
            width: 75px;
            height: 75px;
          }
        }

        .right {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-around;
          height: 80px;
          margin-left: 20px;
          // text-align: left;

          .title {
            color: #4e8cff;
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>
