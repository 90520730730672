<template>
  <div class="page">
    <!-- 主体区域 -->
    <el-card>
      <!-- 筛选、操作区域 -->
      <OperationBar>
        <template slot="search-box">
          <el-form :model="queryForm" :inline="true">
            <el-form-item label="关键词">
              <el-input
                v-model="queryForm.search"
                placeholder="请输入关键词"
                clearable
                @keyup.enter.native="handleSearch()"
              ></el-input>
            </el-form-item>
            <el-form-item label="状态">
              <el-select v-model="queryForm.status" placeholder="请选择设备状态" clearable @change="handleSearch()">
                <el-option
                  v-for="item in deviceStatus"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="产品">
              <el-select v-model="queryForm.proId" placeholder="请选择产品名称" clearable @change="handleSearch()">
                <el-option
                  v-for="item in productList"
                  :key="item.id"
                  :label="item.proName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="medium" @click="handleSearch()">查询</el-button>
            </el-form-item>
          </el-form>
        </template>

        <!-- <template slot="btn-box"></template> -->
      </OperationBar>

      <!-- 表格 -->
      <el-table ref="productListTable" :data="list" v-loading="loading" border>
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column label="产品名称" prop="proName"></el-table-column>
        <el-table-column label="设备编号" prop="serialNumber"></el-table-column>
        <el-table-column label="绑定手机" prop="mobile"></el-table-column>
        <el-table-column label="供货商" prop="agentName"></el-table-column>
        <el-table-column label="生产工厂" prop="channelName"></el-table-column>
        <el-table-column label="版本号" prop="version"></el-table-column>
        <el-table-column label="设备状态" prop="status">
          <template slot-scope="{ row }">
            <span v-for="item in row.status" :key="item">
              <span v-if="item == '离线'" style="color: red">{{ item }} </span>
              <span v-else>{{ item }} </span>
            </span>
          </template>
        </el-table-column>

        <el-table-column label="通讯方式">
          <template slot-scope="{ row }">
            <span v-for="(item, index) in row.linkWay" :key="item">
              {{ item | linkWayFilter }}{{ index + 1 == row.linkWay.length ? '' : '、' }}
            </span>
          </template>
        </el-table-column>

        <el-table-column label="生产批次" prop="gmtCreate" width="100"></el-table-column>

        <el-table-column label="操作" width="120">
          <div slot-scope="{ row, $index }" class="action-cell">
            <el-button type="primary" round size="mini" @click="forcedUpdating(row)">强制更新</el-button>
            <ConfirmPopover :index="$index" @confirm="handleDelete(row)"></ConfirmPopover>
          </div>
        </el-table-column>
      </el-table>

      <!-- 分页器 -->
      <PaginationBar
        :pageNo.sync="pagination.pageNum"
        :pageSize.sync="pagination.pageSize"
        :total="pagination.total"
        @change="getList()"
      ></PaginationBar>
    </el-card>
  </div>
</template>

<script>
import { linkWays, deviceStatus } from '@/utils/data'

export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      query: {},
      queryForm: {
        search: '',
        status: null,
        proId: ''
      },
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },

      loading: false,
      linkWays,
      deviceStatus,

      list: [],
      productList: []
    }
  },

  filters: {},

  computed: {},

  watch: {},

  created() {
    this.init()
    this.getList()
    this.getProductList()
  },

  methods: {
    init() {
      const search = this.$route.query.search

      if (search) {
        this.query.search = search
        this.queryForm.search = search
      }
    },

    // 获取列表
    async getList() {
      this.loading = true
      const res = await this.$api.device.getList({ ...this.query, ...this.pagination })
      this.loading = false
      if (res.code === '00000') {
        this.pagination.total = res.total
        this.list = this.$utils.stringToArray(res.data, 'linkWay')
        this.list.forEach((item, index) => {
          item.status = item.status.trim().split(' ')
        })
      }
    },

    // 获取产品列表
    async getProductList() {
      const res = await this.$api.product.getProductList({ pageNum: 1, pageSize: 9999 })

      if (res.code === '00000') {
        this.productList = this.$utils.stringToArray(res.data, 'linkWay')
      }
    },

    // 搜索事件
    handleSearch() {
      this.query = { ...this.queryForm }
      this.pagination.pageNum = 1
      this.getList()
    },

    // 强制更新
    async forcedUpdating(row) {
      const res = await this.$api.device.forcedUpdating({
        serialNumber: row.serialNumber
      })

      if (res.code === '00000') {
        this.$message.success('操作成功！')
      }
    },

    // 删除
    async handleDelete(row) {
      const res = await this.$api.device.deleteDevice(row.id)

      if (res.code === '00000') {
        this.$message.success('删除成功！')
        this.getList()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.page {
}
</style>
