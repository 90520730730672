<template>
  <div class="page">
    <!-- 主体区域 -->
    <el-card>
      <!-- 筛选、操作区域 -->
      <OperationBar>
        <template slot="search-box">
          <el-form :model="queryForm" :inline="true">
            <el-form-item label="名称">
              <el-input
                v-model="queryForm.nickname"
                placeholder="请输入渠道商名称"
                clearable
                @keyup.enter.native="handleSearch()"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="medium" @click="handleSearch()">查询</el-button>
            </el-form-item>
          </el-form>
        </template>

        <template slot="btn-box">
          <el-button type="primary" size="medium" round @click="createAccount()">新建渠道商</el-button>
        </template>
      </OperationBar>

      <!-- 表格 -->
      <el-table ref="listTable" :data="list" v-loading="loading" border>
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column label="账号" prop="username"></el-table-column>
        <el-table-column label="渠道商名称" prop="nickname"></el-table-column>
        <el-table-column label="wifi账号" prop="name"></el-table-column>
        <el-table-column label="wifi密码" prop="password"></el-table-column>
        <el-table-column label="联系人" prop="contact"></el-table-column>
        <el-table-column label="联系方式" prop="contactWay"></el-table-column>
        <el-table-column label="地址" prop="address"></el-table-column>

        <el-table-column label="操作" width="400">
          <div slot-scope="{ row, $index }" class="action-cell">
            <el-button type="primary" round size="mini" @click="editAccount(row)">编辑</el-button>

            <ConfirmPopover :index="$index" text="重置" @confirm="handleReset(row)"></ConfirmPopover>

            <ConfirmPopover
              v-if="row.status == 1"
              :index="$index"
              text="禁用"
              @confirm="handleBan(row)"
            ></ConfirmPopover>

            <el-button v-else type="primary" round size="mini" @click="handleBan(row)">启用</el-button>

            <ConfirmPopover :index="$index" @confirm="handleDelete(row)"></ConfirmPopover>
          </div>
        </el-table-column>
      </el-table>

      <!-- 分页器 -->
      <PaginationBar
        :pageNo.sync="pagination.pageNum"
        :pageSize.sync="pagination.pageSize"
        :total="pagination.total"
        @change="getList()"
      ></PaginationBar>
    </el-card>

    <!-- 创建账号弹框 -->
    <el-dialog title="编辑账号" :visible.sync="accountDialog" width="600px" @closed="clearContents()">
      <el-form ref="accountForm" :model="accountForm" :rules="accountRules" label-width="auto">
        <el-form-item label="账号" prop="username">
          <el-input v-model="accountForm.username" placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item label="渠道商名称" prop="nickname">
          <el-input v-model="accountForm.nickname" placeholder="请输入渠道商名称"></el-input>
        </el-form-item>
        <el-form-item label="wifi账号" prop="name">
          <el-input v-model="accountForm.name" placeholder="请输入wifi账号"></el-input>
        </el-form-item>
        <el-form-item label="wifi密码" prop="password">
          <el-input v-model="accountForm.password" placeholder="请输入wifi密码"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="contact">
          <el-input v-model="accountForm.contact" placeholder="请输入联系人"></el-input>
        </el-form-item>
        <el-form-item label="联系方式" prop="contactWay">
          <el-input v-model="accountForm.contactWay" placeholder="请输入联系方式"></el-input>
        </el-form-item>
        <el-form-item label="地址" prop="address">
          <el-input v-model="accountForm.address" placeholder="请输入地址"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="accountDialog = false">取 消</el-button>
        <el-button type="primary" @click="handleAccountSave()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      query: {},
      queryForm: {
        nickname: ''
      },
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },

      loading: false,

      list: [],

      accountDialog: false,
      accountForm: {},
      defaultAccountForm: {
        username: '',
        nickname: '',
        name: '',
        password: '',
        contact: '',
        contactWay: '',
        address: ''
      },
      accountRules: {
        username: [{ required: true, message: '请输入账号', trigger: 'blur' }],
        nickname: [{ required: true, message: '请输入渠道商名称', trigger: 'blur' }],
        name: [{ required: true, message: '请输入wifi账号', trigger: 'blur' }],
        password: [{ required: true, message: '请输入wifi密码', trigger: 'blur' }],
        contact: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        contactWay: [{ required: true, message: '请输入联系方式', trigger: 'blur' }],
        address: [{ required: true, message: '请输入地址', trigger: 'blur' }]
      }
    }
  },

  computed: {},

  watch: {},

  created() {
    this.getList()
  },

  methods: {
    // 获取列表
    async getList() {
      this.loading = true
      const res = await this.$api.channel.getList({ ...this.query, ...this.pagination })
      this.loading = false

      if (res.code === '00000') {
        this.list = res.data
        this.pagination.total = res.total
      }
    },

    // 搜索事件
    handleSearch() {
      this.query = { ...this.queryForm }
      this.pagination.pageNum = 1
      this.getList()
    },

    // 新建账号
    createAccount() {
      this.accountDialog = true
    },

    // 编辑账号
    editAccount(row) {
      this.accountForm = { ...row }
      this.accountDialog = true
    },

    // 确认新建账号，调用接口
    handleAccountSave() {
      this.$refs.accountForm.validate(async (valid) => {
        if (!valid) return

        const res = await this.$api.channel.editChannel(this.accountForm)
        if (res.code === '00000') {
          this.$message.success('操作成功！')
          this.accountDialog = false
          this.pagination.pageNum = 1
          this.getList()
        }
      })
    },

    // 重置
    async handleReset(row) {
      const res = await this.$api.account.resetPassword(row.userId)

      if (res.code === '00000') {
        this.$message.success('操作成功！')
        this.getList()
      }
    },

    // 禁用
    async handleBan(row) {
      const res = await this.$api.account.banAccount(row.userId)

      if (res.code === '00000') {
        this.$message.success('操作成功！')
        this.getList()
      }
    },

    // 删除
    async handleDelete(row) {
      const res = await this.$api.account.deleteAccount(row.userId)

      if (res.code === '00000') {
        this.$message.success('操作成功！')
        this.getList()
      }
    },

    // 关闭弹窗时清除表单
    clearContents() {
      this.accountForm = { ...this.defaultAccountForm }
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  .el-card {
  }
}
</style>
