<template>
  <div class="page">
    <!-- 主体区域 -->
    <el-card>
      <!-- 筛选、操作区域 -->
      <OperationBar>
        <template slot="search-box">
          <el-form :model="queryForm" :inline="true">
            <el-form-item label="关键词">
              <el-input
                v-model="queryForm.search"
                placeholder="请输入关键词"
                clearable
                @keyup.enter.native="handleSearch()"
              ></el-input>
            </el-form-item>
            <el-form-item label="一级分类">
              <el-select
                v-model="queryForm.firstCategoryId"
                placeholder="请选择一级分类"
                clearable
                @change="onChangeFirstCategory"
              >
                <el-option
                  v-for="item in firstCategoryList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="二级分类">
              <el-select v-model="queryForm.categoryId" placeholder="请选择二级分类" clearable @change="handleSearch()">
                <el-option
                  v-for="item in secondCategoryList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所属开发者" v-perm="'product:list:developerSelect'">
              <el-select v-model="queryForm.agentId" placeholder="请选择开发者" clearable @change="handleSearch()">
                <el-option
                  v-for="item in developerSelectList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="通讯方式">
              <el-select v-model="queryForm.linkWay" placeholder="请选择通讯方式" clearable @change="handleSearch()">
                <el-option
                  v-for="item in linkWays"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="上下架状态">
              <el-select v-model="queryForm.status" placeholder="请选择上下架状态" clearable @change="handleSearch()">
                <el-option label="上架" :value="1"></el-option>
                <el-option label="下架" :value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="medium" @click="handleSearch()">查询</el-button>
            </el-form-item>
          </el-form>
        </template>

        <template slot="btn-box">
          <el-button v-perm="'product:list:create'" type="primary" size="medium" round @click="createProduct()">
            创建产品
          </el-button>
        </template>
      </OperationBar>

      <!-- 表格 -->
      <el-table ref="productListTable" :data="list" v-loading="loading" border>
        <el-table-column label="序号" type="index" width="50"></el-table-column>

        <el-table-column label="产品信息" width="300">
          <div slot-scope="{ row }" class="product-info">
            <div class="left">
              <el-image :src="row.basePic"></el-image>
            </div>
            <div class="right">
              <div class="title">{{ row.proName }}</div>
              <el-tooltip class="proNo" effect="light" placement="top-start" :enterable="false" :content="row.proNo">
                <span>
                  {{ row.proNo | proNoFilter }}
                  <i class="el-icon-document-copy" v-copy="row.proNo"></i>
                </span>
              </el-tooltip>
            </div>
          </div>
        </el-table-column>

        <el-table-column label="通讯方式">
          <template slot-scope="{ row }">
            <span v-for="(item, index) in row.linkWay" :key="item">
              {{ item | linkWayFilter }}{{ index + 1 == row.linkWay.length ? '' : '、' }}
            </span>
          </template>
        </el-table-column>

        <el-table-column label="所属开发者" prop="agentName"></el-table-column>
        <el-table-column label="一级类目" prop="firstCategoryName"></el-table-column>
        <el-table-column label="二级类目" prop="categoryName"></el-table-column>
        <el-table-column label="总数" prop="total"></el-table-column>
        <el-table-column label="激活量" prop="usedNum"></el-table-column>
        <el-table-column label="创建时间" prop="gmtCreate" width="100"></el-table-column>

        <el-table-column label="操作" width="300">
          <div slot-scope="{ row, $index }" class="action-cell">
            <el-button type="primary" round size="mini" @click="handleDetail(row)">查看详情</el-button>
            <el-button type="primary" round size="mini" @click="handleDevice(row)"> 查看设备 </el-button>
            <el-button
              v-perm="'product:list:updateFirmware'"
              type="primary"
              round
              size="mini"
              @click="updateFirmware(row)"
              >固件更新</el-button
            >
            <!-- <el-button type="primary" round size="mini" @click="downloadSecurityCode(row)">下载防伪码</el-button> -->
            <el-button type="primary" round size="mini" @click="downloadQRCode(row)">下载二维码</el-button>
            <!-- <el-dropdown>
              <el-button type="primary" round size="mini">
                下载
                <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click="downloadSecurityCode">下载防伪码</el-dropdown-item>
                <el-dropdown-item @click="downloadQRCode">下载二维码</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown> -->

            <div v-perm="'product:list:shelf'" class="shelf">
              <el-button v-if="row.status == 0" type="primary" round size="mini" @click="setProductShelfStatus(row)">
                上架
              </el-button>
              <el-button v-if="row.status == 1" type="danger" round size="mini" @click="setProductShelfStatus(row)">
                下架
              </el-button>
            </div>

            <ConfirmPopover :index="$index" @confirm="handleDelete(row)"></ConfirmPopover>
          </div>
        </el-table-column>
      </el-table>

      <!-- 分页器 -->
      <PaginationBar
        :pageNo.sync="pagination.pageNum"
        :pageSize.sync="pagination.pageSize"
        :total="pagination.total"
        @change="getList()"
      ></PaginationBar>
    </el-card>
  </div>
</template>

<script>
import { linkWays } from '@/utils/data'

export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      query: {},
      queryForm: {
        search: '',
        agentId: '',
        linkWay: null,
        firstCategoryId: null,
        categoryId: null
      },
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },

      linkWays,
      loading: false,

      list: [],
      developerSelectList: [],
      firstCategoryList: [],
      secondCategoryList: []
    }
  },

  filters: {
    proNoFilter(val) {
      const header = val.substr(0, 5)
      const footer = val.substr(val.length - 5, 5)
      return header + '······' + footer
    }
  },

  computed: {},

  watch: {},

  created() {
    this.getList()
    this.getDeveloperSelectList()
    this.getFirstCategoryList()
  },

  methods: {
    // 获取产品列表
    async getList() {
      this.loading = true
      const res = await this.$api.product.getProductList({ ...this.query, ...this.pagination })
      this.loading = false

      if (res.code === '00000') {
        this.pagination.total = res.total
        this.list = this.$utils.stringToArray(res.data, 'linkWay')
      }
    },

    // 搜索事件
    handleSearch() {
      this.query = { ...this.queryForm }
      this.pagination.pageNum = 1
      this.getList()
    },

    // 获取开发者列表
    async getDeveloperSelectList() {
      const res = await this.$api.account.getDeveloperSelectList({ pageNum: 1, pageSize: 9999 })

      if (res.code === '00000') {
        this.developerSelectList = res.data
      }
    },

    // 获取一级分类列表
    async getFirstCategoryList() {
      const res = await this.$api.subProduct.getCategoryList({ levels: 1, pageNum: 1, pageSize: 999 })

      if (res.code === '00000') {
        this.firstCategoryList = res.data
      }
    },

    // 获取二级分类列表
    async getSecondCategoryList(id) {
      const res = await this.$api.subProduct.getCategoryList({ levels: 2, categoryId: id, pageNum: 1, pageSize: 999 })

      if (res.code === '00000') {
        this.secondCategoryList = res.data
      }
    },

    // 一级分类变化时触发
    onChangeFirstCategory(value) {
      this.queryForm.categoryId = null
      this.secondCategoryList = []
      this.handleSearch()
      this.getSecondCategoryList(value)
    },

    // 添加产品
    createProduct() {
      this.$router.push({ path: 'create' })
    },

    // 查看详情
    handleDetail(row) {
      this.$router.push({ path: 'detail', query: { id: row.id } })
    },

    // 下载防伪码
    async downloadSecurityCode(row) {
      window.open(process.env.VUE_APP_BASE_API + '/serve-user/api/v1/users/excel/' + row.proNo)
    },

    // 下载二维码
    async downloadQRCode(row) {
      const res = await this.$api.download.getQRCode({
        type: 2,
        id: row.id
      })

      if (res.code === '00000') {
        const link = document.createElement('a')
        link.href = res.data
        link.download = row.proName + '.png'
        link.click()
      }
    },

    // 查看设备
    handleDevice(row) {
      this.$router.push({
        path: '/device/list',
        query: { search: row.proNo }
      })
    },

    // 更新固件
    updateFirmware(row) {
      this.$router.push({
        path: '/firmware/update',
        query: { id: row.id }
      })
    },

    // 上下架
    async setProductShelfStatus(row) {
      const res = await this.$api.product.setProductShelfStatus(row.id)

      if (res.code === '00000') {
        this.$message.success('操作成功！')
        this.getList()
      }
    },

    // 删除
    async handleDelete(row) {
      const res = await this.$api.product.deleteProduct(row.id)

      if (res.code === '00000') {
        this.$message.success('删除成功！')
        this.getList()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  .el-card {
    .el-table {
      .product-info {
        display: flex;
        align-items: center;

        .left {
          .el-image {
            width: 75px;
            height: 75px;
          }
        }

        .right {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-around;
          height: 80px;
          margin-left: 20px;
          // text-align: left;

          .title {
            color: #4e8cff;
            font-size: 16px;
          }

          .proNo {
            i {
              color: #4e8cff;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
