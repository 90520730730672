<template>
  <div class="page">
    <el-card>
      <!-- 筛选、操作区域 -->
      <OperationBar>
        <template slot="search-box">
          <el-form :model="queryForm" :inline="true">
            <el-form-item label="关键词">
              <el-input
                v-model="queryForm.search"
                placeholder="请输入关键词"
                clearable
                @keyup.enter.native="handleSearch()"
              ></el-input>
            </el-form-item>
            <el-form-item label="设备状态">
              <el-select v-model="queryForm.status" placeholder="请选择设备状态" @change="handleSearch()">
                <el-option label="全部" value="12"></el-option>
                <el-option label="通过" value="1"></el-option>
                <el-option label="不通过" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-date-picker
                v-model="queryDate"
                type="daterange"
                range-separator="至"
                value-format="yyyy-MM-dd"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="dateChange"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="medium" @click="handleSearch()">查询</el-button>
            </el-form-item>
          </el-form>
        </template>
      </OperationBar>

      <!-- 表格 -->
      <el-table ref="productListTable" :data="list" v-loading="loading" border>
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column label="设备名称" prop="proName"></el-table-column>
        <el-table-column label="产品型号" prop="type"></el-table-column>
        <el-table-column label="设备编号" prop="serialNumber"></el-table-column>
        <el-table-column label="测试状态">
          <template slot-scope="{ row }">
            <span v-if="row.result == 1">通过</span>
            <span v-if="row.result == 2">不通过</span>
          </template>
        </el-table-column>
        <el-table-column label="未通过模块" prop="msg"></el-table-column>
        <el-table-column label="测试时间" prop="gmtModified"></el-table-column>
      </el-table>

      <!-- 分页器 -->
      <PaginationBar
        :pageNo.sync="pagination.pageNum"
        :pageSize.sync="pagination.pageSize"
        :total="pagination.total"
        @change="getList()"
      ></PaginationBar>
    </el-card>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      query: {
        status: '12'
      },
      queryForm: {
        search: '',
        startDate: '',
        endDate: '',
        status: '12'
      },
      queryDate: '',
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      loading: false,
      list: []
    }
  },

  filters: {},
  computed: {},
  watch: {},

  created() {
    this.init()
    this.getList()
  },

  methods: {
    init() {},

    // 获取列表
    async getList() {
      this.loading = true
      const res = await this.$api.deviceFactory.getTestList({ ...this.query, ...this.pagination })
      this.loading = false
      if (res.code === '00000') {
        this.pagination.total = res.total
        this.list = res.data
      }
    },

    // 日期改变
    dateChange(e) {
      if (e) {
        this.queryForm.startDate = e[0]
        this.queryForm.endDate = e[1]
      } else {
        this.queryForm.startDate = ''
        this.queryForm.endDate = ''
      }

      this.handleSearch()
    },

    // 搜索事件
    handleSearch() {
      this.query = { ...this.queryForm }
      this.pagination.pageNum = 1
      this.getList()
    }
  }
}
</script>

<style scoped lang="scss">
.page {
}
</style>
