<template>
  <div class="page">
    <!-- 开发者信息卡片 -->
    <el-card class="info-card">
      <div slot="header">
        <span>开发者信息</span>
      </div>

      <el-descriptions :column="2">
        <el-descriptions-item label="开发者名称">{{ detail.nickname }}</el-descriptions-item>
        <el-descriptions-item label="联系方式">{{ detail.mobile }}</el-descriptions-item>
      </el-descriptions>
    </el-card>

    <!-- 拥有产品卡片 -->
    <el-card class="have-card">
      <div slot="header">
        <span>拥有产品</span>
      </div>

      <!-- 表格 -->
      <el-table ref="productTable" :data="list" v-loading="loading" border>
        <el-table-column label="序号" type="index" width="50"></el-table-column>

        <el-table-column label="产品信息" min-width="200">
          <div slot-scope="{ row }" class="product-info">
            <div class="left">
              <el-image :src="row.basePic"></el-image>
            </div>
            <div class="right">
              <div class="title">{{ row.proName }}</div>
              <div class="id">{{ row.baseId }}</div>
            </div>
          </div>
        </el-table-column>

        <el-table-column label="通讯方式">
          <template slot-scope="{ row }">
            <span v-for="(item, index) in row.linkWay" :key="item">
              {{ item | linkWayFilter }}{{ index + 1 == row.linkWay.length ? '' : '、' }}
            </span>
          </template>
        </el-table-column>

        <el-table-column label="总数" prop="total"></el-table-column>

        <el-table-column label="激活量" prop="usedNum"></el-table-column>

        <el-table-column label="创建时间" prop="gmtCreate"></el-table-column>

        <el-table-column label="操作" width="200">
          <div slot-scope="{ row, $index }" class="action-cell">
            <el-button type="primary" round size="mini" @click="handleDetail(row)">查看详情</el-button>
            <el-button type="primary" round size="mini" @click="handleDevice(row)">查看设备</el-button>
            <ConfirmPopover :index="$index" @confirm="handleDelete(row)"></ConfirmPopover>
          </div>
        </el-table-column>
      </el-table>

      <!-- 分页器 -->
      <PaginationBar
        :pageNo.sync="pagination.pageNum"
        :pageSize.sync="pagination.pageSize"
        :total="pagination.total"
        @change="getList()"
      ></PaginationBar>
    </el-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      query: {},
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },

      loading: false,

      list: []
    }
  },

  filters: {},

  computed: { ...mapState('detail', ['detail']) },

  watch: {},

  created() {
    this.init()
    this.getList()
  },

  methods: {
    init() {
      this.query.agentId = this.detail.id
    },

    // 获取列表
    async getList() {
      this.loading = true
      const res = await this.$api.product.getProductList({ ...this.query, ...this.pagination })
      this.loading = false

      if (res.code === '00000') {
        this.pagination.total = res.total
        this.list = this.$utils.stringToArray(res.data, 'linkWay')
      }
    },

    // 查看详情
    handleDetail(row) {
      this.$router.push({ path: '/product/detail', query: { id: row.id } })
    },

    // 查看设备
    handleDevice(row) {
      this.$router.push({
        path: '/device/list',
        query: { search: row.proNo }
      })
    },

    // 删除
    async handleDelete(row) {
      const res = await this.$api.product.deleteProduct(row.id)

      if (res.code === '00000') {
        this.$message.success('删除成功！')
        this.getList()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  .info-card {
    .el-descriptions {
      width: 600px;
    }
  }

  .have-card {
    margin-top: 10px;

    .el-table {
      .product-info {
        display: flex;
        align-items: center;

        .left {
          .el-image {
            width: 75px;
            height: 75px;
          }
        }

        .right {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-around;
          height: 80px;
          margin-left: 20px;
          // text-align: left;

          .title {
            color: #4e8cff;
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>
