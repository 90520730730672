<template>
  <div class="page">
    <el-card>
      <!-- 筛选、操作区域 -->
      <OperationBar>
        <template slot="search-box">
          <el-form :model="queryForm" :inline="true">
            <el-form-item label="关键词">
              <el-input
                v-model="queryForm.search"
                placeholder="请输入关键词"
                clearable
                @keyup.enter.native="handleSearch()"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-date-picker
                v-model="queryDate"
                type="daterange"
                range-separator="至"
                value-format="yyyy-MM-dd"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="dateChange"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="medium" @click="handleSearch()">查询</el-button>
            </el-form-item>
          </el-form>
        </template>
      </OperationBar>

      <!-- 表格 -->
      <el-table :data="list" v-loading="loading" border>
        <el-table-column label="序号" type="index" width="50" header-align="center"></el-table-column>
        <el-table-column label="用户账号" prop="mobile"></el-table-column>
        <el-table-column label="昵称" prop="nickName"></el-table-column>
        <el-table-column label="反馈内容" prop="content"></el-table-column>

        <el-table-column label="图片" width="300">
          <template slot-scope="{ row }">
            <div class="pic-box">
              <el-image
                v-for="(item, index) in row.topThreePics"
                :key="index"
                :src="item"
                :preview-src-list="row.list"
              ></el-image>
            </div>
          </template>
        </el-table-column>

        <!-- <el-table-column label="操作" width="300">
          <div slot-scope="{ row, $index }" class="action-cell">
            <el-button type="primary" round size="mini" @click="handleDetail(row)">查看详情</el-button>
          </div>
        </el-table-column> -->
      </el-table>

      <!-- 分页器 -->
      <PaginationBar
        :pageNo.sync="pagination.pageNum"
        :pageSize.sync="pagination.pageSize"
        :total.sync="pagination.total"
        @change="getList()"
      ></PaginationBar>
    </el-card>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      query: {},
      queryForm: {
        search: '',
        startDate: '',
        endDate: ''
      },
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      queryDate: '',
      list: [],
      loading: false
    }
  },

  computed: {},

  watch: {},

  created() {
    this.getList()
  },

  methods: {
    // 获取反馈列表
    async getList() {
      this.loading = true
      const res = await this.$api.user.getFeedbackList({ ...this.query, ...this.pagination })
      this.loading = false

      if (res.code === '00000') {
        this.pagination.total = res.total
        this.list = res.data

        this.list.forEach((item, index) => {
          if (item.list) {
            item.topThreePics = item.list.slice(0, 3)
          }
        })
      }
    },

    // 搜索事件
    handleSearch() {
      this.query = { ...this.queryForm }
      this.pagination.pageNum = 1
      this.getList()
    },

    // 日期改变
    dateChange(e) {
      if (e) {
        this.queryForm.startDate = e[0]
        this.queryForm.endDate = e[1]
      } else {
        this.queryForm.startDate = ''
        this.queryForm.endDate = ''
      }

      this.handleSearch()
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  .el-card {
    .el-table {
      .pic-box {
        display: flex;
        justify-content: center;

        .el-image {
          width: 75px;
          height: 75px;
          margin-right: 12px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
</style>
