<template>
  <div class="view">
    <ProductFrame :title="title + ' : '">
      <div slot="content">
        <el-select v-model="serialNumber" placeholder="请选择主设备" @change="onChange" style="width: 100%">
          <el-option
            v-for="item in list"
            :key="item.id"
            :label="item.serialNumber"
            :value="item.serialNumber"
          ></el-option>
        </el-select>
      </div>
      <div v-if="serialNumber" slot="middle" class="right-wrapper">
        <img v-show="lightSwitch" src="@/assets/package/switch_on.png" @click="setLight(0)" />
        <img v-show="!lightSwitch" src="@/assets/package/switch_off.png" @click="setLight(1)" />
      </div>
    </ProductFrame>
  </div>
</template>

<script>
import ProductFrame from './components/ProductFrame'

export default {
  name: '',
  components: { ProductFrame },

  props: {
    title: {
      type: String,
      default: '主设备'
    }
  },

  data() {
    return {
      list: null,
      serialNumber: '',
      lightSwitch: false
    }
  },

  filters: {},
  computed: {},
  watch: {},

  created() {
    this.getList()
  },

  methods: {
    // 获取列表
    async getList() {
      const res = await this.$api.packageApi.getMainDeviceListOfCreateCombo({ packageId: this.$route.query.id })

      if (res.code === '00000') {
        this.list = res.data
      }
    },

    // 设备切换时触发
    onChange(value) {
      this.$emit('change', value)
    },

    // 开关灯
    async setLight(type) {
      this.lightSwitch = !this.lightSwitch

      const res = await this.$api.deviceFactory.sendControlSignal({
        serialNumber: this.serialNumber,
        command: 'light',
        isLight: type
      })

      if (res.code === '00000') {
      } else {
        this.lightSwitch = !this.lightSwitch
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.view {
  .right-wrapper {
    margin-top: 30px;

    img {
      width: 50px;
      height: 50px;
      cursor: pointer;
    }
  }
}
</style>
