<template>
  <div class="page">
    <!-- 主体区域 -->
    <el-card>
      <div slot="header">
        <span>版本管理</span>
      </div>

      <!-- 操作系统 -->
      <div class="os">
        <el-button :type="type == 1 ? 'primary' : ''" round @click="selectOS(1)">安卓</el-button>
        <el-button :type="type == 0 ? 'primary' : ''" round @click="selectOS(0)">IOS</el-button>
        <el-button :type="type == 11 ? 'primary' : ''" round @click="selectOS(11)">安卓 PAD</el-button>
        <el-button :type="type == 10 ? 'primary' : ''" round @click="selectOS(10)">IOS PAD</el-button>
        <el-button :type="type == 12 ? 'primary' : ''" round @click="selectOS(12)">4寸屏</el-button>
        <el-button :type="type == 13 ? 'primary' : ''" round @click="selectOS(13)">10寸屏</el-button>
      </div>

      <!-- 表单区域 -->
      <el-form ref="form" :model="form" :rules="rules" label-width="auto" v-loading="loading">
        <el-form-item label="版本号" prop="version">
          <el-input v-model="form.version" placeholder="请输入版本号"></el-input>
        </el-form-item>
        <el-form-item label="更新内容" prop="content">
          <el-input
            v-model="form.content"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 10 }"
            placeholder="请输入更新内容"
          ></el-input>
        </el-form-item>
        <el-form-item label="强制更新">
          <el-switch v-model="form.isForce" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
        <el-form-item label="下载地址" prop="url">
          <el-input v-model="form.url" placeholder="请输入下载地址"></el-input>
        </el-form-item>
      </el-form>

      <div class="btn-box">
        <!-- <el-button @click="handleCancel()">取 消</el-button> -->
        <el-button type="primary" @click="handleSave()">保 存</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      type: 1,
      form: {
        version: '',
        content: '',
        isForce: 0,
        url: ''
      },

      rules: {
        version: [{ required: true, message: '请输入版本号', trigger: 'blur' }],
        content: [{ required: true, message: '请输入更新内容', trigger: 'blur' }],
        url: [{ required: true, message: '请输入下载地址', trigger: 'blur' }]
      },

      loading: false
    }
  },

  filters: {},
  computed: {},
  watch: {},

  created() {
    this.getVersion()
  },

  methods: {
    // 获取版本信息
    async getVersion() {
      this.loading = true
      const res = await this.$api.app.getVersionInfo(this.type)
      this.loading = false

      if (res.code === '00000') {
        this.form = {
          ...res.data,
          type: this.type
        }
      }
    },

    // 选择操作系统
    selectOS(type) {
      if (this.type === type) return

      this.type = type
      this.getVersion()
    },

    // 保存
    handleSave() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return

        const res = await this.$api.app.updateVersion(this.form)

        if (res.code === '00000') {
          this.$message.success('操作成功！')
          // this.$router.back()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  .el-card {
    .el-form {
      margin-top: 20px;
    }
  }
}
</style>
