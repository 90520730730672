<template>
  <div class="page">
    <el-card>
      <div slot="header">
        <span>产品信息</span>
      </div>

      <!-- 表单区域 -->
      <el-form ref="form" :model="form" :rules="rules" label-width="auto">
        <el-form-item label="产品图片" prop="pic">
          <UploadImg :imgUrl.sync="form.pic"></UploadImg>
        </el-form-item>

        <el-form-item label="产品名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入产品名称"></el-input>
        </el-form-item>

        <el-form-item label="连接方式" prop="linkWay">
          <el-select v-model="form.linkWay" placeholder="请选择连接方式" multiple>
            <el-option v-for="item in linkWays" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="创建时间">
          <el-col :span="10">
            <el-input v-model="form.gmtCreate" disabled></el-input>
          </el-col>
          <el-col class="line" :span="4" style="text-align: right">更新时间&nbsp;&nbsp;&nbsp;</el-col>
          <el-col :span="10">
            <el-input v-model="form.gmtModified" disabled></el-input>
          </el-col>
        </el-form-item>
      </el-form>

      <div class="btn-box">
        <el-button @click="handleCancel()">取 消</el-button>
        <el-button type="primary" @click="handleSave()">保 存</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { linkWays } from '@/utils/data'

export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      form: {
        name: '',
        pic: null,
        linkWay: null,
        content: null,
        gmtCreate: null,
        gmtModified: null
      },

      linkWays,
      rules: {
        name: [{ required: true, message: '请输入产品名称', trigger: 'blur' }],
        pic: [{ required: true, message: '请上传产品图片', trigger: 'blur' }],
        linkWay: [{ required: true, message: '请选择连接方式', trigger: 'blur' }]
      }
    }
  },

  computed: { ...mapState('detail', ['detail']) },

  watch: {},

  created() {
    this.init()
  },

  methods: {
    init() {
      if (this.detail.id) {
        this.form = { ...this.detail }
      }
    },

    // 取消编辑，返回上一级
    handleCancel() {
      this.$router.back()
    },

    // 保存
    handleSave() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return

        const form = { ...this.form }
        form.linkWay = form.linkWay.join(',')

        const res = await this.$api.product.editLibraryProduct(form)

        if (res.code === '00000') {
          this.$message.success('编辑成功！')
          this.$router.back()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  .el-card {
    .el-form {
      .el-form-item {
        width: 600px;
        .el-select {
          width: 100%;
        }
        .product-img {
          width: 100px;
          height: 100px;
        }
      }
      .editor {
        width: 1200px;
        .upload {
          width: 500px;
          margin-top: 10px;
        }
      }
    }

    .btn-box {
      display: flex;
      justify-content: space-around;
      width: 600px;
      margin: 40px 0 20px;
    }
  }
}
</style>
