var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('el-card',{staticClass:"info-card"},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("开发者信息")])]),_c('el-descriptions',{attrs:{"column":2}},[_c('el-descriptions-item',{attrs:{"label":"开发者名称"}},[_vm._v(_vm._s(_vm.detail.nickname))]),_c('el-descriptions-item',{attrs:{"label":"联系方式"}},[_vm._v(_vm._s(_vm.detail.mobile))])],1)],1),_c('el-card',{staticClass:"have-card"},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("拥有产品")])]),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"productTable",attrs:{"data":_vm.list,"border":""}},[_c('el-table-column',{attrs:{"label":"序号","type":"index","width":"50"}}),_c('el-table-column',{attrs:{"label":"产品信息","min-width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"product-info"},[_c('div',{staticClass:"left"},[_c('el-image',{attrs:{"src":row.basePic}})],1),_c('div',{staticClass:"right"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(row.proName))]),_c('div',{staticClass:"id"},[_vm._v(_vm._s(row.baseId))])])])}}])}),_c('el-table-column',{attrs:{"label":"通讯方式"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _vm._l((row.linkWay),function(item,index){return _c('span',{key:item},[_vm._v(" "+_vm._s(_vm._f("linkWayFilter")(item))+_vm._s(index + 1 == row.linkWay.length ? '' : '、')+" ")])})}}])}),_c('el-table-column',{attrs:{"label":"总数","prop":"total"}}),_c('el-table-column',{attrs:{"label":"激活量","prop":"usedNum"}}),_c('el-table-column',{attrs:{"label":"创建时间","prop":"gmtCreate"}}),_c('el-table-column',{attrs:{"label":"操作","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return _c('div',{staticClass:"action-cell"},[_c('el-button',{attrs:{"type":"primary","round":"","size":"mini"},on:{"click":function($event){return _vm.handleDetail(row)}}},[_vm._v("查看详情")]),_c('el-button',{attrs:{"type":"primary","round":"","size":"mini"},on:{"click":function($event){return _vm.handleDevice(row)}}},[_vm._v("查看设备")]),_c('ConfirmPopover',{attrs:{"index":$index},on:{"confirm":function($event){return _vm.handleDelete(row)}}})],1)}}])})],1),_c('PaginationBar',{attrs:{"pageNo":_vm.pagination.pageNum,"pageSize":_vm.pagination.pageSize,"total":_vm.pagination.total},on:{"update:pageNo":function($event){return _vm.$set(_vm.pagination, "pageNum", $event)},"update:page-no":function($event){return _vm.$set(_vm.pagination, "pageNum", $event)},"update:pageSize":function($event){return _vm.$set(_vm.pagination, "pageSize", $event)},"update:page-size":function($event){return _vm.$set(_vm.pagination, "pageSize", $event)},"change":function($event){return _vm.getList()}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }