<template>
  <div class="page">
    <!-- 主体区域 -->
    <el-card>
      <!-- 筛选、操作区域 -->
      <OperationBar>
        <template slot="search-box">
          <el-form :model="queryForm" :inline="true">
            <el-form-item label="关键词">
              <el-input
                v-model="queryForm.search"
                placeholder="请输入关键词"
                clearable
                @keyup.enter.native="handleSearch()"
              ></el-input>
            </el-form-item>
            <el-form-item label="所属分类">
              <el-select v-model="queryForm.categoryId" placeholder="请选择所属分类" clearable @change="handleSearch()">
                <el-option v-for="item in categoryList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="medium" @click="handleSearch()">查询</el-button>
            </el-form-item>
          </el-form>
        </template>

        <template slot="btn-box">
          <el-button type="primary" size="medium" round @click="handleCreate()">新建自动化</el-button>
        </template>
      </OperationBar>

      <!-- 表格 -->
      <el-table ref="productListTable" :data="list" v-loading="loading" border>
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column label="自动化名称" prop="name"></el-table-column>
        <el-table-column label="所属分类" prop="categoryName"></el-table-column>
        <el-table-column label="图标">
          <template slot-scope="{ row }">
            <el-image :src="row.icon"></el-image>
          </template>
        </el-table-column>

        <el-table-column label="排序" width="120">
          <template slot-scope="{ row, $index }">
            <el-input-number
              :ref="`sortInput${$index}`"
              class="sort-input"
              v-model="row.sort"
              size="medium"
              :controls="false"
              :min="1"
              :step="1"
              step-strictly
              @focus="(e) => onSortInputFocus(e, row.sort)"
              @keyup.enter.native="onSortInputEnter(row, $index)"
            ></el-input-number>
          </template>
        </el-table-column>

        <el-table-column label="上架状态" width="120">
          <template slot-scope="{ row }">
            <el-switch
              v-model="row.status"
              :active-value="1"
              :inactive-value="0"
              @change="changeIsShow(row)"
            ></el-switch>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="240">
          <div slot-scope="{ row, $index }" class="action-cell">
            <el-button type="primary" round size="mini" @click="handleEdit(row)">编辑</el-button>
            <ConfirmPopover :index="$index" @confirm="handleDelete(row)"></ConfirmPopover>
          </div>
        </el-table-column>
      </el-table>

      <!-- 分页器 -->
      <PaginationBar
        :pageNo.sync="pagination.pageNum"
        :pageSize.sync="pagination.pageSize"
        :total="pagination.total"
        @change="getList()"
      ></PaginationBar>
    </el-card>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      query: {
        isAuto: 1
      },
      queryForm: {
        isAuto: 1,
        search: '',
        categoryId: ''
      },
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      loading: false,
      list: [],
      categoryList: [],
      lastSortInputValue: null
    }
  },

  filters: {},

  computed: {},

  watch: {},

  created() {
    this.getList()
    this.getCategoryList()
  },

  methods: {
    // 获取列表
    async getList() {
      this.loading = true
      const res = await this.$api.automation.getSceneList({ ...this.query, ...this.pagination })
      this.loading = false

      if (res.code === '00000') {
        this.list = res.data
        this.pagination.total = res.total
      }
    },

    // 获取分类列表
    async getCategoryList() {
      const res = await this.$api.automation.getCategoryList({
        pageNum: 1,
        pageSize: 999
      })

      if (res.code === '00000') {
        this.categoryList = res.data
      }
    },

    // 搜索事件
    handleSearch() {
      this.query = { ...this.queryForm }
      this.pagination.pageNum = 1
      this.getList()
    },

    // 创建
    handleCreate() {
      this.$router.push({ path: 'sceneDetail', query: { type: 1 } })
    },

    // 排序 input 获得焦点时触发
    onSortInputFocus(e, value) {
      this.lastSortInputValue = value
    },

    // 排序 input 回车时触发
    async onSortInputEnter(row, index) {
      this.$refs[`sortInput${index}`].$refs.input.blur()
      if (row.sort === this.lastSortInputValue) return

      const res = await this.$api.automation.sortScene({ id: row.id, sort: row.sort })

      if (res.code === '00000') {
        this.$message.success('操作成功！')
        this.getList()
      }
    },

    // 显示隐藏状态变化时
    async changeIsShow(row) {
      const res = await this.$api.automation.setSceneShelfStatus(row.id)

      if (res.code === '00000') {
        this.$message.success('操作成功！')
        this.getList()
      }
    },

    // 编辑
    handleEdit(row) {
      this.$router.push({ path: 'sceneDetail', query: { type: 1, id: row.id } })
    },

    // 删除
    async handleDelete(row) {
      const res = await this.$api.automation.deleteScene(row.id)

      if (res.code === '00000') {
        this.$message.success('删除成功！')
        this.getList()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  .el-card {
    .el-table {
      .el-image {
        width: 75px;
        height: 75px;
      }

      .sort-input {
        width: 90px;
      }
    }
  }
}
</style>
