<template>
  <div class="page">
    <!-- 主体区域 -->
    <el-card>
      <div slot="header">
        <span>启动页配置</span>
      </div>

      <!-- 表单区域 -->
      <el-form ref="form" :model="form" :rules="rules" label-width="auto" v-loading="loading">
        <div class="cover-form-item">
          <el-form-item label="广告页尺寸一">
            <UploadImg :imgUrl.sync="form.url"></UploadImg>
          </el-form-item>
          <el-form-item label="广告页尺寸二" style="margin-left: 20px">
            <UploadImg :imgUrl.sync="form.urlPro"></UploadImg>
          </el-form-item>
        </div>
        <el-form-item label="跳转链接">
          <el-input v-model="form.content" placeholder="请输入跳转链接"></el-input>
        </el-form-item>
      </el-form>

      <div class="btn-box">
        <!-- <el-button @click="handleCancel()">取 消</el-button> -->
        <el-button type="primary" @click="handleSave()">保 存</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      form: {
        url: '',
        urlPro: '',
        content: ''
      },

      rules: {
        // url: [{ required: true, message: '请上传启动图', trigger: 'blur' }]
      },

      loading: false
    }
  },

  filters: {},
  computed: {},
  watch: {},

  created() {
    this.getLunchPageDetail()
  },

  methods: {
    // 获取启动页详情
    async getLunchPageDetail() {
      this.loading = true
      const res = await this.$api.app.getLunchPage()
      this.loading = false

      if (res.code === '00000') {
        this.form = { ...res.data }
      }
    },

    // 保存
    handleSave() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return

        const res = await this.$api.app.updateLunchPage(this.form)

        if (res.code === '00000') {
          this.$message.success('操作成功！')
          // this.$router.back()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  .el-card {
    .el-form {
      .cover-form-item {
        display: flex;
      }
    }
  }
}
</style>
