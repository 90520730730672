<template>
  <div class="page">
    <el-card>
      <div slot="header">
        <span>个人信息</span>
      </div>

      <!-- 表单区域 -->
      <el-form ref="form" :model="form" :rules="rules" label-width="auto">
        <el-form-item label="头像" prop="avatar">
          <UploadImg :imgUrl.sync="form.avatar"></UploadImg>
        </el-form-item>

        <el-form-item label="公司名称" prop="nickname">
          <el-input v-model="form.nickname" placeholder="请输入公司名称"></el-input>
        </el-form-item>

        <el-form-item label="关联账号" v-if="form.isAdmin !== 1">
          <el-input v-model="form.username" disabled>
            <el-button v-perm="'account:personalCenter:rebind'" slot="append" type="primary" @click="handleRebind()">
              重新绑定
            </el-button>
          </el-input>
        </el-form-item>

        <el-form-item label="创建时间">
          <el-col :span="10">
            <el-input v-model="form.gmtCreate" disabled></el-input>
          </el-col>
          <el-col class="line" :span="4" style="text-align: right">更新时间&nbsp;&nbsp;&nbsp;</el-col>
          <el-col :span="10">
            <el-input v-model="form.gmtModified" disabled></el-input>
          </el-col>
        </el-form-item>
      </el-form>

      <div class="btn-box">
        <el-button @click="handleCancel()">取 消</el-button>
        <el-button type="primary" @click="changePassword()">修改密码</el-button>
        <el-button type="primary" @click="handleSave()">保 存</el-button>
      </div>
    </el-card>

    <!-- 重新绑定弹框 -->
    <el-dialog :visible.sync="bindDialog" custom-class="bindDialog" title="绑定" width="50%" @closed="clearContents()">
      <el-steps :active="stepIndex" align-center>
        <el-step title="验证账号"></el-step>
        <el-step title="变更账号"></el-step>
        <el-step title="完成"></el-step>
      </el-steps>

      <el-card class="step-box">
        <div v-if="stepIndex == 0" class="step step1">
          <div class="text">
            <div class="line1">为验证是账户 {{ this.form.username }} 本人操作，请完成以下验证：</div>
            <div class="line2">验证账号：{{ this.form.username }}</div>
          </div>
          <el-form :model="form2" label-width="auto">
            <el-form-item label="验证码">
              <el-input v-model="form2.code" placeholder="请输入验证码">
                <SmsCodeBtn slot="append" :mobile="this.form.mobile"></SmsCodeBtn>
              </el-input>
            </el-form-item>
          </el-form>
          <el-button type="primary" @click="verifyCurrentMobile()">下一步</el-button>
        </div>

        <div v-else-if="stepIndex == 1" class="step step2">
          <div class="text">身份认证已完成，请输入未在平台注册的手机号，并完成验证</div>
          <el-form :model="form3" label-width="auto">
            <el-form-item label="新账号">
              <el-input v-model="form3.mobile" placeholder="请输入新手机号"></el-input>
            </el-form-item>
            <el-form-item label="验证码">
              <el-input v-model="form3.code" placeholder="请输入验证码">
                <SmsCodeBtn slot="append" :mobile="this.form3.mobile"></SmsCodeBtn>
              </el-input>
            </el-form-item>
          </el-form>
          <el-button type="primary" @click="verifyNewMobile()">下一步</el-button>
        </div>
      </el-card>

      <span slot="footer" class="dialog-footer">
        <el-button @click="bindDialog = false">取 消</el-button>
        <el-button type="primary" @click="bindConfirm()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改密码弹框 -->
    <el-dialog title="修改密码" :visible.sync="changePasswordDialog" width="600px" @closed="clearContents()">
      <el-form ref="changePasswordForm" :model="changePasswordForm" :rules="changePasswordRules" label-width="auto">
        <el-form-item label="旧密码" prop="oldPassword">
          <el-input v-model="changePasswordForm.oldPassword" placeholder="请输入旧密码"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input v-model="changePasswordForm.newPassword" placeholder="请输入新密码"></el-input>
          <div class="tip">6-20字符，包括字母和数字</div>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="changePasswordDialog = false">取 消</el-button>
        <el-button type="primary" @click="handleChangePasswordSave()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      form: {
        avatar: '',
        nickname: '',
        username: '',
        mobile: '',
        roleId: null,
        roleIds: null,
        gmtCreate: null,
        gmtModified: null
      },
      rules: {
        avatar: [{ required: true, message: '请上传头像', trigger: 'blur' }],
        nickname: [{ required: true, message: '请输入公司名称', trigger: 'blur' }]
      },

      pagination: {
        page: 1,
        limit: 9999
      },

      bindDialog: false,
      bindForm: {
        number: null
      },
      bindRules: {
        number: [{ required: true, message: '请填写需要添加的设备数量', trigger: 'blur' }]
      },
      stepIndex: 0,
      form2: {
        code: ''
      },
      form3: {
        mobile: '',
        code: ''
      },

      changePasswordDialog: false,
      changePasswordForm: {},
      defaultChangePasswordForm: {
        oldPassword: '',
        newPassword: ''
      },
      changePasswordRules: {
        oldPassword: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
        newPassword: [{ required: true, message: '请输入新密码', trigger: 'blur' }]
      }
    }
  },

  computed: { ...mapState('user', ['userInfo']) },

  watch: {},

  created() {
    this.init()
    this.getDetail()
  },

  methods: {
    init() {
      this.changePasswordForm = { ...this.defaultChangePasswordForm }
    },

    // 获取账号详情
    async getDetail() {
      const res = await this.$api.account.getDetail(this.userInfo.id)

      if (res.code === '00000') {
        this.form = res.data
      }
    },

    // 重新绑定按钮点击事件
    handleRebind() {
      this.stepIndex = 0
      this.bindDialog = true
    },

    // 关闭弹窗时清除表单
    clearContents() {
      this.changePasswordForm = { ...this.defaultChangePasswordForm }
      this.form2.code = ''
      this.form3.mobile = ''
      this.form3.code = ''
      this.$utils.resetForm(this, 'bindForm')
    },

    // 验证当前手机号
    async verifyCurrentMobile() {
      const res = await this.$api.login.verifyCurrentMobile({ mobile: this.form.mobile, code: this.form2.code })

      if (res.code === '00000') {
        this.form2.code = ''
        this.stepIndex = 1
      }
    },

    // 验证新手机号
    async verifyNewMobile() {
      const res = await this.$api.login.verifyNewMobile({ mobile: this.form3.mobile, code: this.form3.code })

      if (res.code === '00000') {
        this.form3.mobile = ''
        this.form3.code = ''
        this.stepIndex = 2
        this.$message.success('已完成账号变更！')
        this.bindDialog = false
        this.getDetail()
      }
    },

    // 取消编辑，返回上一级
    handleCancel() {
      this.$router.back()
    },

    // 修改密码
    changePassword() {
      this.changePasswordDialog = true
    },

    // 确认修改密码，调用接口
    handleChangePasswordSave() {
      this.$refs.changePasswordForm.validate(async (valid) => {
        if (!valid) return

        const res = await this.$api.account.changePassword(this.changePasswordForm)
        if (res.code === '00000') {
          this.$message.success('修改密码成功！')
          this.changePasswordDialog = false
        }
      })
    },

    // 保存
    async handleSave() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return

        const res = await this.$api.account.updateAccount({ id: this.userInfo.id, ...this.form })

        if (res.code === '00000') {
          this.$message.success('修改成功！')
          this.$router.back()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  .el-card {
    .el-form {
      .el-form-item {
        width: 600px;
        .el-select {
          width: 100%;
        }
      }
    }

    .btn-box {
      display: flex;
      justify-content: space-around;
      width: 600px;
      margin: 40px 0 20px;
    }
  }

  .bindDialog {
    .step-box {
      margin-top: 20px;
      font-size: 16px;

      .step {
        .el-form {
          margin-top: 24px;

          .el-form-item {
            width: 100%;
          }
        }
      }

      .step1 {
        .text {
          .line2 {
            margin-top: 10px;
          }
        }
      }
    }
  }
}
</style>
