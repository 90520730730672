<template>
  <div class="page">
    <!-- 主体区域 -->
    <el-card>
      <!-- 筛选、操作区域 -->
      <OperationBar>
        <template slot="search-box">
          <el-form :model="queryForm" :inline="true">
            <el-form-item label="关键词">
              <el-input
                v-model="queryForm.search"
                placeholder="请输入关键词"
                clearable
                @keyup.enter.native="handleSearch()"
              ></el-input>
            </el-form-item>
            <el-form-item label="栏目">
              <el-select v-model="queryForm.classifyId" placeholder="请选择所属栏目" clearable @change="handleSearch()">
                <el-option v-for="item in columnList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="medium" @click="handleSearch()">查询</el-button>
            </el-form-item>
          </el-form>
        </template>

        <template slot="btn-box">
          <el-button type="primary" size="medium" round @click="handleCreate()">新建</el-button>
        </template>
      </OperationBar>

      <!-- 表格 -->
      <el-table ref="productListTable" :data="list" v-loading="loading" border>
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column label="标题" prop="title"></el-table-column>

        <el-table-column label="封面">
          <template slot-scope="{ row }">
            <el-image :src="row.pic"></el-image>
          </template>
        </el-table-column>

        <el-table-column label="栏目" prop="classifyName"></el-table-column>
        <el-table-column label="创建时间" prop="gmtCreate"></el-table-column>

        <el-table-column label="是否上架" width="120">
          <template slot-scope="{ row }">
            <el-switch
              v-model="row.isShow"
              :active-value="1"
              :inactive-value="0"
              @change="changeOnOff(row)"
            ></el-switch>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="200">
          <div slot-scope="{ row, $index }" class="action-cell">
            <el-button type="primary" round size="mini" @click="handleEdit(row)">编辑</el-button>
            <ConfirmPopover :index="$index" @confirm="handleDelete(row)"></ConfirmPopover>
          </div>
        </el-table-column>
      </el-table>

      <!-- 分页器 -->
      <PaginationBar
        :pageNo.sync="pagination.pageNum"
        :pageSize.sync="pagination.pageSize"
        :total="total"
        @change="getList()"
      ></PaginationBar>
    </el-card>

    <!-- 编辑弹框 -->
    <el-dialog title="编辑文章" :visible.sync="editDialog" width="600px" @closed="clearContents()">
      <el-form ref="editForm" :model="editForm" :rules="editRules" label-width="auto">
        <el-form-item label="类型" prop="type">
          <el-select v-model="editForm.type" placeholder="请选择类型" clearable @change="$forceUpdate()">
            <el-option label="图文" value="图文"></el-option>
            <el-option label="视频" value="视频"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标题" prop="title">
          <el-input v-model="editForm.title" placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-form-item label="封面" prop="pic">
          <UploadImg :imgUrl.sync="editForm.pic"></UploadImg>
        </el-form-item>
        <el-form-item v-if="editForm.type == '图文'" label="内容" prop="content">
          <Editor v-model="editForm.content"></Editor>
        </el-form-item>
        <el-form-item v-if="editForm.type == '视频'" label="视频" prop="video">
          <UploadVideo :url.sync="editForm.video" @onSuccess="onUploadVideoSuccess"></UploadVideo>
        </el-form-item>
        <el-form-item label="简介">
          <el-input
            v-model="editForm.intro"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 10 }"
            placeholder="请输入简介"
          ></el-input>
        </el-form-item>
        <el-form-item label="栏目" prop="classifyId">
          <el-select v-model="editForm.classifyId" placeholder="请选择栏目" clearable>
            <el-option v-for="item in columnList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序">
          <el-input-number v-model="editForm.sort" :min="1" :step="1" step-strictly></el-input-number>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="editDialog = false">取 消</el-button>
        <el-button type="primary" @click="handleEditSave()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      query: {},
      queryForm: {
        search: '',
        classifyId: ''
      },
      pagination: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      loading: false,
      list: [],
      columnList: [],

      editDialog: false,
      editForm: {},
      editRules: {
        type: [{ required: true, message: '请选择类型', trigger: 'blur' }],
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        pic: [{ required: true, message: '请上传封面', trigger: 'blur' }],
        content: [{ required: true, message: '请编辑内容', trigger: 'blur' }],
        video: [{ required: true, message: '请上传视频', trigger: 'blur' }],
        classifyId: [{ required: true, message: '请选择栏目', trigger: 'blur' }]
      }
    }
  },

  filters: {},

  computed: {},

  watch: {},

  created() {
    this.getList()
    this.getColumnList()
  },

  methods: {
    // 获取列表
    async getList() {
      this.loading = true
      const res = await this.$api.discover.getArticleList({ ...this.query, ...this.pagination })
      this.loading = false

      if (res.code === '00000') {
        this.list = res.data
        this.total = res.total
      }
    },

    // 获取栏目列表
    async getColumnList() {
      const res = await this.$api.discover.getColumnList({ ...this.query, ...this.pagination })

      if (res.code === '00000') {
        this.columnList = res.data
      }
    },

    // 搜索事件
    handleSearch() {
      this.query = { ...this.queryForm }
      this.pagination.pageNum = 1
      this.getList()
    },

    // 新建
    handleCreate() {
      this.editForm = {}
      this.editDialog = true
    },

    // 开关状态变化时
    async changeOnOff(row) {
      const res = await this.$api.discover.setArticleOnOff(row.id)

      if (res.code === '00000') {
        this.$message.success('操作成功！')
        this.getList()
      }
    },

    // 编辑
    handleEdit(row) {
      this.editForm = JSON.parse(JSON.stringify(row))

      if (this.editForm.content) {
        this.editForm.type = '图文'
      } else if (this.editForm.video) {
        this.editForm.type = '视频'
      }

      this.editDialog = true
    },

    // 关闭弹窗时清除表单
    clearContents() {
      this.editForm = {}
    },

    // 删除
    async handleDelete(row) {
      const res = await this.$api.discover.deleteArticle(row.id)

      if (res.code === '00000') {
        this.$message.success('删除成功！')
        this.getList()
      }
    },

    // 视频上传成功
    onUploadVideoSuccess(res, file) {
      this.editForm.duration = file.duration
    },

    // 保存编辑
    handleEditSave() {
      this.$refs.editForm.validate(async (valid) => {
        if (!valid) return

        if (this.editForm.type === '图文') {
          this.editForm.video = ''
          this.editForm.duration = ''
        } else if (this.editForm.type === '视频') {
          this.editForm.content = ''
        }

        const res = await this.$api.discover.editArticle(this.editForm)
        if (res.code === '00000') {
          this.$message.success('操作成功！')
          this.editDialog = false
          this.getList()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  .el-card {
    .el-table {
    }
  }
}
</style>
